import React from 'react';
import {bodyClick} from "../app/app";

const ErrorPage = () => {
    return (
        <div onClick={bodyClick}>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="error">
                    <h1 className="error-text">Oops, This page does not exist</h1>
                </div>
            </section>
        </div>
    );
};

export default ErrorPage;