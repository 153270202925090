import React from 'react';
import {bodyClick} from "../app/app";

const Terminologic = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page">
            </section>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Термінологія Таеквон-До</span>
                                            <span className="ru">Терминология Таэквон-До</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src={require("./img/icons/icons8-северная-корея-48.png")} alt=""/>
                                        <img src={require("./img/icons/icons8-южная-корея-48.png")} alt=""/>
                                    </div>
                                    <div className="service-desc ua">
                                    </div>
                                    <div className="service-desc ru">
                                        <h5>
                                            Терминология таэквон-до (ИТФ)</h5><br/>

                                        сонбе ним - старший ученик<br/>

                                        бусабом ним - помощник учителя (1-3 дан)<br/>

                                        сабом ним - инструктор (4-6 дан)<br/>

                                        сахёним - мастер (7-8 дан)<br/>

                                        сасонг ним - великий мастер (9 дан)<br/>

                                        до дзанг - тренировочный зал, площадка для состязаний<br/>

                                        до бок - форма для занятий<br/>

                                        гып - ученическая степень<br/>

                                        дан - мастерская степень<br/>

                                        сурён - тренировка<br/>

                                        паль - руки<br/>

                                        баль - ноги<br/>

                                        чаги - удар ногой<br/>

                                        дзириги - пробивающий удар<br/>

                                        тэриги - рубящий удар<br/>

                                        тульги - колющий удар<br/>

                                        гутги - режущий удар<br/>

                                        мильги - толчок<br/>

                                        макги - блок<br/>

                                        соги - стойка<br/>

                                        дзапги - захват<br/>

                                        орундзок, вэндзок - право, лево (поворот - уро тора)<br/>

                                        ал, юн, дви - вперед, в сторону, назад<br/>

                                        ан, бакат - внутренний, наружный<br/>

                                        ануро, бакуро - внутрь, наружу<br/>

                                        нэрё, оллё - вниз, вверх<br/>

                                        апджё, иросо - сесть, встать<br/>

                                        сасон - наискось, по диагонали<br/>

                                        баро - прямой (одноимённый)<br/>

                                        бандэ - обратный (разноимённый)<br/>

                                        онмом - фронтальное положение корпуса<br/>

                                        банмом - боковое положение корпуса<br/>

                                        юпмом - боковое положение корпуса<br/>

                                        надзундэ, каундэ, нопундэ - нижняя, средняя, верхняя части тела<br/>

                                        дора - поворот<br/>

                                        доллё - по кругу<br/>

                                        дольмё - с разворотом<br/>

                                        кеча - скрестно<br/>

                                        санг - двойной<br/>

                                        судзик - вертикально<br/>

                                        супьёнг - горизонтально<br/>

                                        хечё - раздвигающий<br/>

                                        тимё - прыжок<br/>

                                        микульмё - скольжение<br/>

                                        дарибулиги - растягивающие упражнения (шпагаты)<br/>

                                        юльтон - "волна"(принцип движений в таэквондо)<br/>

                                        хим - мощь<br/>

                                        массоги - спарринг<br/>

                                        туль - технические комплексы<br/>

                                        кёкпа - разбивание<br/>

                                        вирек - измерение силы удара (синоним "кёкпа")<br/>

                                        т ки - спецтехника<br/>

                                        хосинсул - самооборона дален - укрепление тела (набивка)<br/>

                                        кихап - "использование энергии"- крик, сопровождающий движение<br/>

                                        кёнг ги - состязания<br/>

                                        дзип дзунг - концентрация<br/>

                                        чонгсин - духовная составляющая личности<br/>

                                        мораль чонгсин тонгиль - "единство духа"(медитативное yпражнение в таэквондо)<br/>

                                        ти - пояс<br/>

                                        синцэ дален - физическая подготовка<br/>

                                        симпан ним - судья<br/>

                                        дзедза - ученик<br/>

                                        ил гоп пил са - одним ударом наповал<br/>

                                        сибум - показательные выступления<br/>

                                        симса - аттестация<br/>

                                        кёнг ги кё дзонг - правила соревнований<br/>
                                        <br/>

                                        <br/>
                                        <h5>Команды:</h5><br/>

                                        моет - построиться<br/>

                                        чарёт - внимание (принять стойку "внимания")<br/>

                                        кюнэ - поклон сие - вольно<br/>

                                        чунби - приготовиться<br/>

                                        си дзак - выполнять<br/>

                                        гуман - закончить<br/>

                                        баро - прямо (вернуться в исходное положение)<br/>

                                        дащи - повторить<br/>

                                        кэсок - продолжать<br/>

                                        гёдэ - поменять<br/>

                                        бандэро - обратно, в другую сторону<br/>

                                        уро, чаро, диро дора - повернуться направо, налево, кругом<br/>

                                        апро(диро) ка - вперёд, назад марш<br/>

                                        анджё - сесть, иро сот-встать<br/>

                                        добок чонгдан - поправить форму<br/>

                                        чончони - выполнять медленно<br/>

                                        химике - выполнять мощно, быстро<br/>


                                        Счёт от 1 до 10<br/>


                                        хана - раз<br/>

                                        туль - два<br/>

                                        сэт - три<br/>

                                        нэт - четыре<br/>

                                        тасот - пять<br/>

                                        ёсот - шесть<br/>

                                        ильгоп - семь<br/>

                                        ёдол - восемь<br/>

                                        ахоп - девять<br/>

                                        ёл - десять<br/>


                                        <br/>
                                        <h5>Ударные части тела:</h5><br/>

                                        дзу мок-, кулак<br/>

                                        ап-, юп-, дунг-, мит- дзумок - передняя, боковая, тыльная, нижняя часть кулака<br/>

                                        сонкаль - ребро ладони со стороны мизинца<br/>

                                        сонкаль дунг - ребро ладони со стороны большого пальца<br/>

                                        сонкаль батанг - основание ребра ладони<br/>

                                        сонкут - кончики пальцев<br/>

                                        сонбадак - ладонь<br/>

                                        сондунг - тыльная часть ладони<br/>

                                        бандальсон - рука арка (дуга) пальмок- предплечье<br/>

                                        ан-, баккат-, дунг-, мит- палъмок- внутренняя, внешняя, тыльная, нижняя часть
                                        предплечья
                                        палькуп- локоть апкупчи- подушечка стопы балькаль- наружный край стопы (ребро стопы)
                                        балькаль дунг- внутренний край стопы бальдунг- подъём стопы бальбадак- подошва
                                        двиттчук-
                                        задняя часть пятки двитткупчи-нижняя часть пятки

                                        муруп - колено<br/>

                                        хан сонгарак - один палец (указательный)<br/>

                                        ду сонгарак - два пальца (указательный и средний)<br/>

                                        сонгарак бадк - подушечки пальцев<br/>

                                        дзипгэ сон - пальцы-щипцы<br/>

                                        индзи дзумок - удар фалангой указательного пальца<br/>

                                        дзунгдзи дзумок - удар фалангой среднего пальца<br/>

                                        сон мок - изгиб кисти (ан-, бакат-, дунг-, мит-)<br/>

                                        омдзи - большой палец<br/>

                                        омдзи батанг - основания большого пальца<br/>

                                        дзиап - давящие пальцы<br/>

                                        пен дзумок - основание ладони<br/>

                                        <br/>
                                        <h5>Стойки: (соги)</h5><br/>

                                        нарани соги - параллельная стойка<br/>

                                        чарёт соги - стойка внимания<br/>

                                        аннун соги - сидячая стойка (всадника)<br/>

                                        гуннун соги - стойка ходьбы<br/>

                                        ниундза соги - стойка L-образная<br/>

                                        годзонг соги - глубокая стойка<br/>

                                        губурё соги - стойка на согнутой ноге (варианты: "А", "В")<br/>

                                        вэбаль соги - стойка на прямой ноге<br/>

                                        двитт баль соги - стойка на задней ноге<br/>

                                        моа соги - стойка ноги вместе (варианты: "А, В, С, D")<br/>

                                        кеча соги - стойка ноги скрестно<br/>

                                        начё соги - длинная стойка<br/>

                                        судзик соги - вертикальная стойка<br/>

                                        огурё соги - сгруппированная стойка (вариант диагональной стойки-сасон соги) -колени
                                        согнуты во внутрь<br/>

                                        сасон соги - диагональная стойка<br/>

                                        кённэ дзасэ - позиция поклона<br/>

                                        палдза соги - открытая стойка (варианты: ан палдза соги и бакат палдза соги)<br/>

                                        моа дзунби соги - стойка готовности воина (варианты: "А" и "Б")<br/>

                                        чунби соги - стойка готовность<br/>

                                        хануль сап - руки неба (это не стойка, а позиция рук)<br/>

                                        <br/>
                                        <h5>Удары руками:</h5><br/>

                                        баро дзириги - одноименный удар<br/>

                                        бандэ дзириги - обратный (разноимённый) удар рукой<br/>

                                        двиттдзибо дзириги - перевёрнутый удар (тыльной частью кулака вниз)<br/>

                                        сево дзириги - вертикальный удар рукой<br/>

                                        гёкджа дзириги - Г- образный удар рукой<br/>

                                        доллё дзириги - круговой удар<br/>

                                        бандаль дзириги - дугообразный удар<br/>

                                        сан сонкут тульги - удар кончиками пальцев, ладонь вертикально<br/>

                                        опун сонкут тульги - удар кончиками пальцев, ладонь вниз<br/>

                                        хоми сонкут тульги - угловой удар кончиками пальцев<br/>

                                        двиттдзибо сонкут тульги - удар кончиками пальцев, ладонь вверх<br/>

                                        оллё дзириги - удар кулаком вверх<br/>

                                        ап палькуп тэриги - удар локтем вперёд<br/>

                                        ви палькуп тэриги - удар локтем снизу вверх<br/>

                                        нопундэ палькуп тэриги - удар локтем сбоку вверх<br/>

                                        ви двит палькуп тэриги - удар одним локтем снизувверх другим назад<br/>

                                        палькуп тульги - колющий удар локтем (варианты: нэрё, вэ юп, санг юн, вэ<br/>

                                        супьёнг, санг супьёнт, двитт, санг юп двит.)<br/>

                                        нэрё - удар сверху вниз (варианты: дзириги, тульги, тэриги)<br/>

                                        ду дзумок дзириги - парный удар кулаком<br/>

                                        и джунг конг гёк - двойной удар(атака)<br/>

                                        сам джунг конг гёк - тройной удар(атака)<br/>

                                        ёнсок конг гёк - последовательный удар(атака)<br/>

                                        хон ап конг гёк - комбинированный удар(атака)<br/>

                                        бандаль тэриги - дугообразный рубящий удар (варианты: бандаль сон/ рука арка, дзипгэ
                                        сон/рука клещи)<br/>

                                        гудги - режущий удар, разрез (варианты: ануро, бакуро)<br/>

                                        хечё тэриги - расклинивающий рубящий удар (варианты: сонкаль, сон дунг, дунг
                                        дзумок)<br/>

                                        омдзи дзомук - удар большим пальцем<br/>

                                        пен дзумок дзириги - удар основанием ладони<br/>

                                        <br/>
                                        <h5>Удары ногами:</h5><br/>

                                        ап-, юп-, двитт- ча оллиги - мах ногой вперёд, вбок, назад (используются для
                                        разминки,
                                        развитии гибкости или для блокирования)<br/>

                                        муруп чаги - удар коленом (варианты: па, оллё, доллё, битуро)<br/>

                                        апча бусиги - хлёсткий удар ногой вперёд<br/>

                                        двиттча бусиги - хлёсткий удар ногой назад<br/>

                                        юпча дзириги - удар ногой в сторону (боковой пронзающий удар)<br/>

                                        двитгча дзириги - удар ногой назад<br/>

                                        двиттча милиги - толкающий удар назад<br/>

                                        доллё чаги - круговой удар<br/>

                                        бандэ доллё чаги - обратный круговой удар<br/>

                                        коро чаги - удар захлёстом голени<br/>

                                        нэре чаги (ануро-, бакуро-) - удар сверху вниз (вовнутрь, наружу)<br/>

                                        сево чаги - вертикальный удар<br/>

                                        битуро чаги - удар со скручиванием<br/>

                                        ильбо чаги - удар с подшагом<br/>

                                        ибо чаги - удар с зашагом<br/>

                                        тимё чаги - удар в прыжке<br/>

                                        и дзунг чаг/и - два удара ногой<br/>

                                        сам дзунг чаги - три удара ногой<br/>

                                        хонап чаги - комбинированный удар ногой<br/>

                                        ёнсок чаги - последовательные удары ногами<br/>

                                        дольмё чаги (дора чаги) - удар с разворотом<br/>

                                        ча момчиги - контролирующий удар ногой (варианты: ап ча момчиги, юп ча момчиги)<br/>

                                        бандал чаги - дугообразный удар<br/>

                                        бада чаги - контратакующий удар ногой<br/>

                                        тимё номо чаги - удар ногой в прыжке вдаль<br/>

                                        тимё нопи чаги - удар ногой в прыжке вверх<br/>

                                        тимё са банг чаги - удары ногами в прыжке в четыре направления<br/>

                                        тимё сам банг чаги - удары ногами в прыжке в три направления<br/>

                                        тимё сам дзунг чаги - три удара ногой в прыжке (варианты: суджик/вертикально,
                                        супьёнг/горизонтально)<br/>

                                        тимё и дзунг чаги - два удара ногой в прыжке (варианты: суджик/ вертикально,
                                        супьёнг/горизонтально)<br/>

                                        тимё сангбанг чаги - двойной удар ногами в прыжке в два направления (варианты: тимё
                                        апдви чаги/переднезадний удар в прыжке, тимё юп битуро чаги/удар в прыжке одна нога
                                        бьёт
                                        в док другая выполняет удар со скручиванием<br/>

                                        тимё санг бал чаги - двойной удар в прыжке (варианты: ап, юп, долио, нопи)<br/>

                                        тимё санг бал ап ча мочиги - двойной контролирующий удар в прыжке<br/>

                                        тимё кави чаги - ножницеобразный удар в прыжке<br/>

                                        тимё расонсик чаги - спиралеобразный удар в прыжке<br/>

                                        бал дулги - поднятие ноги (с целю защиты от удара в ногу)<br/>

                                        бал голги - подсечка<br/>

                                        будзапго чаги - удар ногой с одновременным захватом руками другого противника<br/>

                                        голчё чаги - накладывающий блок ногой<br/>

                                        дзома чаги- кувырковый удар (варианты: ап кумчи, двит чук)<br/>

                                        доро чаги - отмахивающий удар (используется для защиты паха, голени и лодмышки или
                                        для
                                        атаки подмышки противника)<br/>

                                        гокенг и чаги - пикообразный удар<br/>

                                        нуло чаги - давящий удар (варианты: ануро, бакуро)<br/>

                                        банса чаги - отталкивающий удар (выполняется после отталкивания от стены другого
                                        противника и т.д.)<br/>

                                        ча бапги - топчущий удар<br/>

                                        джиго чаги - прямой удар ( варианты: ап, двит)<br/>

                                        сиро чаги - подметающий удар (варианты: балкаль дунг, двиттчук)<br/>

                                        <br/>
                                        <h5>Техника защиты:</h5><br/>

                                        хечё макги - расклинивающий блок<br/>

                                        надзундэ макги - защита нижней части тела<br/>

                                        каундэ макги - защита средней части тела<br/>

                                        нопундэ макги - защита верхней части тела<br/>

                                        ап макги - прямой блок (блок от нападения в центр корпуса спереди, положения корпуса
                                        фронтальное "онмом")<br/>

                                        юп макги - боковой блок (полуфронтальное или боковое положение корпуса, блокирующая
                                        поверхность на линии плеч)<br/>

                                        юпап макги - боковой прямой блок (фронтальное положение корпуса, блокирующая
                                        поверхность
                                        на линии плеч)<br/>

                                        чукё макги - верхний блок<br/>

                                        санг макги - двойной блок<br/>

                                        долимё макги - круговой блок<br/>

                                        гольчё макги - накладывающий блок<br/>

                                        оллё макги - блок снизу-вверх<br/>

                                        нулло макги - давящий блок<br/>

                                        миро макги - толкающий блок (варианты: сонбадак, ду паль мок, сон каль, сон каль
                                        дунг)<br/>

                                        момчё" макги - контролирующий блок (варианты: кеча джомук/скрестными кулаками ,кёча
                                        сонкаль/скрестными ребрами ладони, вэ сан пальмок/одним прямым предплечьем, санг сан
                                        пальмок/двумя прямыми предплечьями, вэ сан сонкаль/одним прямым ребром ладони, санг
                                        сан
                                        сонкаль/двумя прямыми ребрами ладони)<br/>

                                        ду бандальсон макги - парный дугообразый блок<br/>

                                        дыро макги - поднимающий блок<br/>

                                        гутдза макги - блок в форме цифры "9"<br/>

                                        ду пальмок макги - блок предплечьем с усилением второй рукой<br/>

                                        дигутдза макги - U-образный блок<br/>

                                        монгдунг-и макги - U-образный блок (синоним "дигудза макги")<br/>

                                        дигудза дзапги - U-образный захват<br/>

                                        монгдунг-и дзапги - U-образный захват (синоним "дигудза дзапги")<br/>

                                        нэрё макги - блок сверху вниз (варианты: сон бадак/ладонью, санг сон бадак/двумя
                                        ладонями, окалин сон бадак/чередующие ладони, пальмок/предплечьями, сонкаль/ребром
                                        ладони, санг сонкаль/двумя ребрами ладони, сан палкуп/прямым локтем, кеча
                                        джомук/скрестными кулаками, кеча)<br/>

                                        сон каль - скрестными ребрами ладони)<br/>

                                        мом начуги - опускание тела (нырок)<br/>

                                        будзаба макги - блок захватом<br/>

                                        деби макги - охраняющий блок (варианты: пальмок/предплечьями, сонкаль/ребрами
                                        ладони,
                                        сон каль дунг/обратным и ребрами ладони)<br/>

                                        сан макги - W-образный блок (варианты: ан пальмок, бакат пальмок, сон каль, сонкаль
                                        дунг) сан-горы)<br/>

                                        суньёнг макги - горизонтальный удар (варианты: сон бадак, сонкаль)<br/>

                                        нарани макги - параллельный блок (варианты: ан пальмок, бакат пальмок, сон каль, сон
                                        каль дунг, сон дунг, дунг пальмок)<br/>

                                        <br/>
                                        <h5>Технические комплексы: подготовительные:</h5><br/>

                                        1. Садзу дзириги - удары в четыре направления<br/>

                                        2. Садзу макги - блок в четыре направления<br/>


                                        <br/>
                                        <h5>Основные (тулъ):</h5><br/>

                                        1. Чон-Джи 13. Ий-Ам<br/>

                                        2. Дан-Гун 14. Чунг-Дзанг<br/>

                                        3. До-Сан 15. Джуче<br/>

                                        4. Вон-Хё 16.Сам-Ил<br/>

                                        5.Юл-Гок 17.Ю~Син<br/>

                                        6. Джунг-Гун 18. Че-Юнг<br/>

                                        7.Тэ-Ге 19. Ён-Ге<br/>

                                        8. Хва-Ранг 20. Ул-Дзи<br/>

                                        9. Чунг-Му 21.Мун-Му<br/>

                                        10. Кванг-Ге 22. Со-Сан<br/>

                                        11. Г1о-Ун 23.Сэ-Дзонг<br/>

                                        12. Ге-Бэк 24. Тон г-Ил<br/>

                                        <br/>
                                        <h5>Принципы Таэквон-до:</h5><br/>

                                        е ый - учтивость, вежливость, этикет<br/>

                                        ем чи - честность, прямота, чистота, полнота<br/>

                                        ин нэ - упорство, терпение, стойкость, настойчивость<br/>

                                        гык ги - самоконтроль<br/>

                                        бэкдзол булгул - неукротимый (непоколебимый) дух<br/>

                                        <br/>
                                        <h5>Моральная культура:</h5><br/>

                                        ин - гуманизм<br/>

                                        ый - справедливость, добродетель<br/>

                                        е - правильность<br/>

                                        дзи - мудрость<br/>

                                        син - доверие<br/>

                                        <br/>
                                        <h5>Теория силы:</h5><br/>

                                        бандонг рёок - сила противодействия<br/>

                                        дзип дзунг - концентрация<br/>

                                        кюн хёнг - равновесия, баланс<br/>

                                        донгчок андзонг - динамическая стабильность<br/>

                                        хоуп дзодзол - контроль дыхания<br/>

                                        дзилянг - масса<br/>

                                        сокдо – скорость<br/>

                                        банынг - реакция<br/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Terminologic;