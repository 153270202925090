import React from 'react';
import {bodyClick} from "../app/app";
import {Link} from "react-scroll";

const Attestation = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page">
            </section>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Атестація Таеквон-До</span>
                                            <span className="ru">Аттестация Таэквон-До</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src={require("./img/icons/icons8-спарринг-48.png")} alt=""/>
                                    </div>
                                    <div className="service-desc ua">
                                    </div>
                                    <div className="service-desc ru">
                                        <h5>Аттестационные требования ТАЭКВОН-ДО</h5>

                                        <p className="attestation-list">
                                            <b><Link to="9 KUP" smooth={true} offset={0} duration={1000}><b>Белый пояс с желтой нашивкой, </b>9 Гып</Link></b><br/>

                                            <b><Link to="8 KUP" smooth={true} offset={0} duration={1000}><b>Желтый пояс, </b>8 Гып</Link></b><br/>

                                            <b><Link to="7 KUP" smooth={true} offset={0} duration={1000}><b>Желтый пояс с зеленой нашивкой, </b>7 Гып</Link></b><br/>

                                            <b><Link to="6 KUP" smooth={true} offset={0} duration={1000}><b>Зеленый пояс, </b>6 Гып</Link></b><br/>

                                            <b><Link to="5 KUP" smooth={true} offset={0} duration={1000}><b>Зеленый пояс с синей нашивкой, </b>5 Гып</Link></b><br/>

                                            <b><Link to="4 KUP" smooth={true} offset={0} duration={1000}><b>Синий пояс, </b>4 Гып</Link></b><br/>

                                            <b><Link to="3 KUP" smooth={true} offset={0} duration={1000}><b>Синий пояс с красной нашивкой, </b>3 Гып</Link></b><br/>

                                            <b><Link to="2 KUP" smooth={true} offset={0} duration={1000}><b>Красный пояс, </b>2 Гып</Link></b><br/>

                                            <b><Link to="1 KUP" smooth={true} offset={0} duration={1000}><b>Красный пояс с черной нашивкой, </b>1 Гып</Link></b><br/>

                                            <b><Link to="1 DAN" smooth={true} offset={0} duration={1000}><b>Черный пояс, </b>1 Дан</Link></b><br/>

                                        </p>
                                        <br/>

                                        <div className="entry">
                                            <p>&nbsp;</p>
                                            <h3 style={{textAlign: 'center'}}>ФЕДЕРАЦИЯ ТАЕКВОН-ДО ИТФ УКРАИНЫ</h3>
                                            <p>&nbsp;</p>
                                            <p style={{textAlign: 'center'}}>
                                                <em><strong>&nbsp;АТТЕСТАЦИОННЫЕ&nbsp; НОРМАТИВЫ</strong></em></p>
                                            <p style={{textAlign: 'center'}}><b>&nbsp; НАЦИОНАЛЬНОЙ ФЕДЕРАЦИИ</b></p>
                                            <p style={{textAlign: 'center'}}><b>&nbsp; ТАЭКВОН-ДО ИТФ </b></p>
                                            <p style={{textAlign: 'center'}}><b>УКРАИНЫ</b></p>
                                            <p><b>&nbsp;</b></p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p align="center">&nbsp; <b></b></p>
                                            <p align="center"><a className="belt-with-margin" name="9 KUP"></a><b>9&nbsp; </b><b>ГЫП</b><b> (<a
                                                style={{background: '#000000', color: 'white'}}>бело</a>-<a
                                                style={{color: 'gold'}}>желтый</a> пояс)</b><b></b></p>
                                            <p><b>&nbsp;</b></p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Туль:</span> <i>«САДЖУ
                                                ЧИРУГИ»</i></b></p>
                                            <p><b><i>&nbsp; «САДЖУ МАККИ»</i></b></p>
                                            <p><b><i>&nbsp; «ЧОН-ДЖИ»</i></b></p>
                                            <p>Тули «САДЖУ ЧИРУГИ» и «САДЖУ МАККИ» выполняются на левую и правую
                                                ногу</p>
                                            <p>&nbsp;</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Удары ногами:</span></b>
                                                <i>Ап чаги, Долльо чаги, Ануро нэрьо чаги, Бакуро нэрьо чаги, Горо
                                                    чаги</i></p>
                                            <p>Данные удары ногами выполняются попеременно левой-правой ногой из
                                                стойки <i>Гоннун соги.</i> Количество — на усмотрение инструктора.</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Спарринг:</span></b>&nbsp; Самбо
                                                матсоги (спарринг на 3 шага без партнера)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>ОФП:</span></b> 10-отжиманий,30-приседаний,10-«складочка»
                                                (для спортсменов в возрасте до 12 лет)</p>
                                            <p>15-отжиманий,35-приседаний,15-«складочка» (для спортсменов в возрасте от
                                                13 лет и старше)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Теория: &nbsp;</span></b>
                                            </p>
                                            <p><b><i>Билет №1</i></b> Имя основателя Таэквон-до. Что означает слово
                                                Таэквон-до? Духовные принципы Таэквон-до.</p>
                                            <p><b><i>Ответ: </i></b>Основатель таэквон-до – корейский генерал Чой Хонг
                                                Хи.</p>
                                            <p>Корейское «таэ» озночает «выполнение удара ногой в прыжке или полете»,
                                                «квон» — «кулак» (главным образом в контексте нанесения удара или
                                                разбивания чего-либо рукой), «до» — «искусство», «путь» — путь истины,
                                                проторенный в прошлом, покрытый святостью и мудростью. Соединив все
                                                вместе, получаем, что слово «таэквон-до» обозначает систему духовной
                                                тренировки и технику самообороны без оружия наряду со здоровьем, а также
                                                квалифицированным исполнением ударов, блоков и прыжков, выполняющихся
                                                голыми руками и ногами для поражения одного или нескольких
                                                соперников.</p>
                                            <p>Принципы таэквон-до: 1) Учтивость (Ye Ui); 2) Честность(Yom Chi); 3)
                                                Настойчивость (In Nae); 4) Самообладание (Guk Gi); 5) Непоколебимость
                                                духа (Baekjul Boolgool).</p>
                                            <p><b><i>Билет №2</i></b> Дата&nbsp; рождения Таэквон-до. «ЧОН-ЖИ»
                                                (расшифровать название и указать количество движений)<b></b></p>
                                            <p><b><i>Ответ:</i></b> свое название Таэквон-до получило 11 апреля 1955
                                                года.<b><span style={{textDecoration: 'underline'}}> &nbsp;</span></b>
                                            </p>
                                            <p>«Чон Джи» переводится как «рай земной». На востоке оно
                                                интерпретируется &nbsp;как создание мира и зарождение истории
                                                человечества. Это первый туль, с которым знакомится новичок. Он состоит
                                                из двух частей, первая из которых символизирует Землю, а вторая –
                                                Небеса.<b> </b>Количество движений: 19.</p>
                                            <p><b></b></p>
                                            <p><b><i>Билет №3</i></b> Дата основания Международной Федерации Таэквон-до.
                                                Что обозначает белый пояс?</p>
                                            <p><b><i>Ответ: </i></b>Международной Федерации Таэквон-до (ITF) была
                                                создана 22 марта 1966 года ассоциациями таэквон-до Вьетнама, ФРГ, США,
                                                Малайзии, Египта, Турции, Италии, Сингапура и Кореи.</p>
                                            <p>Белый – символизирует незнание, т.к. начинающие заниматься таэквон-до
                                                вначале не имеют никаких специальных знаний и умений.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет №4</i></b> Имя нынешнего Президента Международной Федерации
                                                Таэквон-до. Какие цвета поясов есть в Таэквон-до</p>
                                            <p><b><i>Ответ: </i></b>нынешний президент Международной Федерации
                                                Таэквон-до - мастер Ри Ёнг Сон, Почетный президент Международной
                                                Федерации Таэквон-до - корейский профессор Чан Унг.
                                                В Таэквон-до существуют такие цвета поясов: белый, желтый, зеленый,
                                                синий, красный и черный.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет №5</i></b><i>&nbsp;&nbsp; &nbsp;</i>Счет по-корейски до 10.
                                                Для чего необходим «Туль»</p>
                                            <p><b><i>Ответ: </i></b>1- хана, 2 – туль, 3 – сэт, 4 – нэт, 5 – тасот, 6 –
                                                йосот, 7 – ильгоп, 8 – йодоль, 9 – ахоп, 10 — йоль.</p>
                                            <p>Использование в тренировке туль позволяет путем повторения многих связок
                                                движений совершенствовать спарринг, повышать гибкость, овладеть техникой
                                                движений, укреплять мышцы, учиться контролю дыхания, оттачивать
                                                плавность и ритмичность движений.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет №6</i></b><i>&nbsp;&nbsp; </i>&nbsp;Почему пояс, в
                                                Таэквон-до, завязывается в один обхват? Как называется площадка для
                                                проведения соревнований<i> </i>по<i>&nbsp; </i>Таэквон-до?</p>
                                            <p><b><i>Ответ:</i> </b>главное назначение пояса – подчеркнуть значение
                                                философских основ таэквон-до, а также обозначить квалификацию
                                                спортсмена. 1 июля 1985 года было принято решение о том, что пояс должен
                                                опоясывать тело занимающегося один раз а не дважды, как это
                                                практиковалось ранее.<b> </b>Один оборот пояса вокруг тела
                                                символизирует: 1- «О до иль кван» — стремлении, во что бы то ни стало
                                                достичь одну, ранее поставленную цель;&nbsp; 2 – «Иль пхэн дан сим» —
                                                служение одному делу с непоколебимой преданностью;&nbsp; 3 – «Иль кёк
                                                пхиль сунг» — достижение победы путем проведения одного удара.</p>
                                            <p>Площадка для проведения соревнований<i> </i>по<i>&nbsp; </i>Таэквон-до
                                                называется «Доянг».</p>
                                            <p>&nbsp;</p>
                                            <p><i><b>Билет № 7</b> </i>Что символизирует надпись на спине добока?
                                                Рассказать все о стойке «Гоннун соги»</p>
                                            <p><b><i>Ответ:</i> </b>надпись делается в виде вечнозеленого дерева –
                                                сосны.</p>
                                            <p>«Гоннун соги» является наиболее эффективной стойкой для выполнения
                                                атакующих и защитных действий, направленных вперед или назад.</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад так, чтобы расстояние между
                                                    пальцами в
                                                </li>
                                            </ol>
                                            <p>продольном направлении составляло 1,5 ширины плеч, а расстояние между
                                                центрами стоп (в поперечном направлении) равнялось ширине плеч.</p>
                                            <ol>
                                                <li>Колено передней ноги сгибается таким образом, что воображаемая
                                                    вертикальная линия одновременно проходит через коленный сустав и
                                                    пятку. Задняя нога должна быть прямой.
                                                </li>
                                                <li>Вес тела распределен равномерно на обе ноги.</li>
                                                <li>Стопа передней ноги обращена вперед, стопа задней ноги повернута
                                                    наружу на 25°. Поворот задней ноги боле чем на 25° затрудняет
                                                    защитные действия при атаке сзади.
                                                </li>
                                                <li>Мышцы стоп должны быть напряжены (должно возникать чувство, что
                                                    стопы притягиваются друг к другу).
                                                </li>
                                                <li>При выполнении атакующих и защитных действий из этой стойки
                                                    таэквондист может быть обращен к сопернику лицом или в пол-оборота.
                                                </li>
                                            </ol>
                                            <p>&nbsp;</p>
                                            <p><i><b>Билет № 8</b>&nbsp; &nbsp;</i>Сколько существует тулей в
                                                Таэквон-до? Дата основания Федерации Таэквон-до И.Т.Ф. Украины<i> </i>
                                            </p>
                                            <p><b><i>Ответ:</i> </b>в Таэквон-до существует 24 туля, что символизирует
                                                24 часа в сутках, т.е. жизнь человека. Федерация Таэквон-до И.Т.Ф.
                                                Украины создана в 1992 году.<br/>
                                                <i>&nbsp;&nbsp; </i></p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p align="center"><a className="belt-with-margin" name="8 KUP"></a><b>8 ГЫП (<a
                                                style={{color: 'gold'}}>желтый</a> пояс)</b><b></b></p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Туль:</span>
                                                <i>«ЧОН-ДЖИ»</i></b></p>
                                            <p><b><i>«ДАН-ГУН»</i></b></p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Удары ногами:</span></b>
                                                <i>Ап чаги, Долльо чаги, Ануро нэрьо чаги, Бакуро нэрьо чаги, Горо чаги,
                                                    Йоп чаги.</i></p>
                                            <p>Данные удары ногами выполняются попеременно левой-правой ногой из
                                                стойки <i>Гоннун соги.</i> Количество — на усмотрение инструктора.</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Спарринг:</span></b>&nbsp; Самбо
                                                матсоги (спарринг на 3 шага без партнера)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>ОФП:</span></b> 10-отжиманий,30-приседаний,10-«складочка»(для
                                                спортсменов в возрасте до 12 лет)</p>
                                            <p>15-отжиманий,35-приседаний,15-«складочка»(для спортсменов в возрасте от
                                                13 лет и старше)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Теория:</span></b><i> </i>
                                            </p>
                                            <p><i><b>Билет № 1</b></i> Имя основателя Таэквон-до. Что означает слово
                                                Таэквон-до? Духовные принципы Таэквон-до.</p>
                                            <p><b><i>Ответ: </i></b>Основатель Таэквон-до – корейский генерал Чой Хонг
                                                Хи.</p>
                                            <p>Корейское «таэ» озночает «выполнение удара ногой в прыжке или полете»,
                                                «квон» — «кулак» (главным образом в контексте нанесения удара или
                                                разбивания чего-либо рукой), «до» — «искусство», «путь» — путь истины,
                                                проторенный в прошлом, покрытый святостью и мудростью. Соединив все
                                                вместе, получаем, что слово «Таэквон-до» обозначает систему духовной
                                                тренировки и технику самообороны без оружия наряду со здоровьем, а также
                                                квалифицированным исполнением ударов, блоков и прыжков, выполняющихся
                                                голыми руками и ногами для поражения одного или нескольких
                                                соперников.</p>
                                            <p>Принципы таэквон-до: 1) Учтивость (Ye Ui); 2) Честность(Yom Chi); 3)
                                                Настойчивость (In Nae); 4) Самообладание (Guk Gi); 5) Непоколебимость
                                                духа (Baekjul Boolgool).</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 2</i></b> Дата&nbsp; рождения Таэквон-до. Расшифровать
                                                духовный принцип «Учтивость». Туль «ЧОН-ДЖИ» (расшифровать название и
                                                указать количество движений)<b><span
                                                    style={{textDecoration: 'underline'}}>&nbsp;&nbsp; </span></b></p>
                                            <p><b><i>Ответ: </i></b>свое название Таэквон-до получило 11 апреля 1955
                                                года.<b></b></p>
                                            <p>Формируя свой характер, занимающиеся Таэквон-до должны практиковать
                                                следующие проявления учтивости, включая их как обязательные элементы в
                                                тренировочный процесс:</p>
                                            <ol>
                                                <li>Развивать дух взаимоуважения.</li>
                                                <li>Стыдиться своих вредных привычек.</li>
                                                <li>Быть вежливым.</li>
                                                <li>Поддерживать атмосферу справедливости и гуманизма.</li>
                                                <li>Соблюдать иерархию между занимающимися и инструктором, начинающими
                                                    и&nbsp; квалифицированными спортсменами, старшими и младшими.
                                                </li>
                                                <li>Вести себя в соответствии с принятым этикетом.</li>
                                                <li>Уважать положение других.</li>
                                                <li>Вести себя с другими честно и открыто.<b></b></li>
                                            </ol>
                                            <p>«Чон Джи» переводится как «рай земной». На востоке оно
                                                интерпретируется&nbsp; как создание мира и зарождение истории
                                                человечества. Это первый туль, с которым знакомится новичок. Он состоит
                                                из двух частей, первая из которых символизирует Землю, а вторая –
                                                Небеса.<b> </b>Количество движений: 19.</p>
                                            <p><b><i>Билет № 3</i></b> Дата основания Международной Федерации
                                                Таэквон-до. Что обозначает желтый цвет пояса? Расшифровать духовный
                                                принцип «Честность».</p>
                                            <p><b><i>Ответ: </i></b>Международной Федерации Таэквон-до (ITF) была
                                                создана 22 марта 1966 года ассоциациями таэквон-до Вьетнама, ФРГ, США,
                                                Малайзии, Египта, Турции, Италии, Сингапура и Кореи.</p>
                                            <p>Желтый – символизирует Землю, в которую кладут семя и из которой
                                                произрастают корни Таэквон-до.</p>
                                            <p>Каждый должен уметь отличать правильное от ложного, в случае собственной
                                                неправоты, испытывать угрызения совести. Ниже приводятся несколько
                                                примеров, иллюстрирующих недостаток собранности:</p>
                                            <ol>
                                                <li>Преподавание инструктором неверной техники из-за пробелов в
                                                    образовании или безответственности.
                                                </li>
                                                <li>Использование спортсменом подпиленных досок при демонстрации силы
                                                    ударов.
                                                </li>
                                                <li>Попытки инструктора закамуфлировать недостатки своей техники
                                                    созданием хороших условий для тренировок.
                                                </li>
                                                <li>Выспрашивание у инструктора более высокого пояса, попытки его
                                                    купить.
                                                </li>
                                                <li>Попытки получить пояс для осуществления грязных намерений или для
                                                    демонстрации своего превосходства.
                                                </li>
                                                <li>Обучение инструктором своему искусству только во имя удовлетворения
                                                    собственных материальных запросов.
                                                </li>
                                                <li>Расхождение слова и дела.</li>
                                                <li>Игнорирование спортсменом мнения младших.</li>
                                            </ol>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 4</i></b> Имя нынешнего президента Международной Федерации
                                                Таэквон-до. Какие цвета поясов существуют в &nbsp;Таэквон-до.
                                                Расшифровать духовный принцип «Настойчивость».</p>
                                            <p><b><i>Ответ: </i></b>нынешний президент Международной Федерации
                                                Таэквон-до - мастер Ри Ёнг Сон, Почетный президент Международной
                                                Федерации Таэквон-до - корейский профессор Чан Унг.
                                            </p>
                                            <p>В Таэквон-до существуют такие цвета поясов: белый, желтый, зеленый,
                                                синий, красный и черный.</p>
                                            <p>Древние говорили: «Настойчивость приводит к очищению и совершенству».
                                                «Тот, кто будет стократно настойчив, обеспечит мир своему дому». Счастье
                                                и удача чаще всего достаются&nbsp;&nbsp; настойчивым. Для того, чтобы
                                                чего-либо достичь, необходимо поставить перед собой цель и настойчиво ее
                                                добиваться.</p>
                                            <p>Один из секретов становления мастерства в Таэквон-до – настойчивое
                                                преодоление всех трудностей.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 5</i></b><i> </i>Счет по-корейски до 10. Для чего необходим
                                                «Туль». «ДАН-ГУН» (расшифровать название и указать количество движений)
                                            </p>
                                            <p><b><i>Ответ: </i></b>1- хана, 2 – туль, 3 – сэт, 4 – нэт, 5 – тасот, 6 –
                                                йосот, 7 – ильгоп, 8 – йодоль, 9 – ахоп, 10 — йоль.</p>
                                            <p>Использование в тренировке туль позволяет путем повторения многих связок
                                                движений совершенствовать спарринг, повышать гибкость, овладеть техникой
                                                движений, укреплять мышцы, учиться контролю дыхания, оттачивать
                                                плавность и ритмичность движений.</p>
                                            <p>Туль «Дан Гун» назван в честь Дан Гуна – родоначальника корейской нации и
                                                основателя Дангунской Кореи, существовавшей в 2333 г. до н.э.. Туль
                                                насчитывает 21 движение.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 6</i></b><i>&nbsp;&nbsp; </i>&nbsp;Почему пояс, в
                                                Таэквон-до, завязывается в один обхват? Как называется площадка для
                                                проведения соревнований<i> </i>по<i>&nbsp; </i>Таэквон-до? Расшифровать
                                                духовный принцип «Самоконтроль».</p>
                                            <p><b><i>Ответ: </i></b>главное назначение пояса – подчеркнуть значение
                                                философских основ таэквон-до, а также обозначить квалификацию
                                                спортсмена. 1 июля 1985 года было принято решение о том, что пояс должен
                                                опоясывать тело занимающегося один раз а не дважды, как это
                                                практиковалось ранее.<b> </b>Один оборот пояса вокруг тела
                                                символизирует: 1- «О до иль кван» — стремлении, во что бы то ни стало
                                                достичь одну, ранее поставленную цель;&nbsp; 2 – «Иль пхэн дан сим» —
                                                служение одному делу с непоколебимой преданностью;&nbsp; 3 – «Иль кёк
                                                пхиль сунг» — достижение победы путем проведения одного удара.</p>
                                            <p>Площадка для проведения соревнований<i> </i>по<i>&nbsp; </i>Таэквон-до
                                                называется «Доянг».</p>
                                            <p>Самообладание чрезвычайно важно как в тренировочном зале, так и в
                                                повседневной жизни. Потеря самоконтроля во время спарринга может
                                                привести к очень серьёзным последствиям для обоих соперников.
                                                Неуживчивость внутри коллектива является проявлением плохого
                                                самоконтроля. Великий китайский мыслитель Лао Цзы говорил: <b><i>«Сильный
                                                    – тот, кто умеет побеждать себя, а не другого».</i></b></p>
                                            <p>&nbsp;</p>
                                            <p><i><b>Билет № 7</b> </i>Что символизирует надпись на спине добка?
                                                Рассказать все о стойке «Гоннун соги». Ф.И.О президента Федерации
                                                Таэквон-до &nbsp;И.Т.Ф. Украины.</p>
                                            <p><b><i>&nbsp;Ответ: </i></b>надпись делается в виде вечнозеленого дерева –
                                                сосны.</p>
                                            <p>«Гоннун соги» является наиболее эффективной стойкой для выполнения
                                                атакующих и защитных действий, направленных вперед или назад.</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад так, чтобы расстояние между
                                                    пальцами в
                                                </li>
                                            </ol>
                                            <p>продольном направлении составляло 1,5 ширины плеч, а расстояние между
                                                центрами стоп (в поперечном направлении) равнялось ширине плеч.</p>
                                            <ol>
                                                <li>Колено передней ноги сгибается таким образом, что воображаемая
                                                    вертикальная линия одновременно проходит через коленный сустав и
                                                    пятку. Задняя нога должна быть прямой.
                                                </li>
                                                <li>Вес тела распределен равномерно на обе ноги.</li>
                                                <li>Стопа передней ноги обращена вперед, стопа задней ноги повернута
                                                    наружу на 25°. Поворот задней ноги боле чем на 25° затрудняет
                                                    защитные действия при атаке сзади.
                                                </li>
                                                <li>Мышцы стоп должны быть напряжены (должно возникать чувство, что
                                                    стопы притягиваются друг к другу).
                                                </li>
                                                <li>При выполнении атакующих и защитных действий из этой стойки
                                                    таэквондист может быть обращен к сопернику лицом или в пол-оборота.
                                                </li>
                                            </ol>
                                            <p>Президент Федерации Таэквон-до&nbsp; И.Т.Ф. Украины – Потернак Виктор
                                                Николаевич (мастер VIII
                                                дан).</p>
                                            <p>&nbsp;</p>
                                            <p><i><b>Билет № 8</b>&nbsp; </i>Сколько существует тулей в Таэквон-до? Дата
                                                основания Федерации Таэквон-до И.Т.Ф. Украины. Расшифровать духовный
                                                принцип «Непоколебимость духа».</p>
                                            <p><b><i>Ответ:</i> </b>в Таэквон-до существует 24 туля, что символизирует
                                                24 часа в сутках, т.е. жизнь человека.</p>
                                            <p>Федерация Таэквон-до И.Т.Ф. Украины создана в 1992 году.</p>
                                            <p><b><i>«Здесь покоятся триста, выполнивших свой долг».</i></b> Эта простая
                                                эпитафия относится к одному из величайших подвигов в истории
                                                человечества. Леонид и 300 спартанцев, став насмерть на пути
                                                полчищ&nbsp; Ксеркса, показали миру, что такое непоколебимый дух.</p>
                                            <p>Занимающиеся Таэквон-до должны быть честными и искренними. Столкнувшись с
                                                несправедливостью, они должны действовать активно, ничего не боясь,
                                                проявлять непоколебимый дух, независимо от того, кто им противостоит и
                                                как много противников.</p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p align="center"><a className="belt-with-margin" name="7 KUP"></a><b>7 </b><b>ГЫП </b><b>(<a
                                                style={{color: 'gold'}}>желто</a>-<a
                                                style={{color: 'green'}}>зеленый</a> пояс)</b><b></b></p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Туль<i>:</i></span><i> «ДАН-ГУН»</i></b>
                                            </p>
                                            <p><b><i>&nbsp; «ДО-САН»</i></b></p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Удары ногами:</span></b>
                                                <i>Ап чаги, Долльо чаги, Ануро нэрьо чаги, Бакуро нэрьо чаги, Горо чаги,
                                                    Йоп чаги</i></p>
                                            <p>Данные удары ногами выполняются попеременно левой-правой ногой из
                                                положения <i>Нюнча со</i> <i>пальмок дэби макки</i>. Количество — на
                                                усмотрение инструктора.</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Спарринг:</span></b>&nbsp; Самбо
                                                матсоги (спарринг на 3 шага без партнера)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>ОФП:</span></b> 15-отжиманий,35-приседаний,15-«складочка»
                                                (для спортсменов в возрасте до 12 лет)</p>
                                            <p>20-отжиманий,40-приседаний, 20-«складочка» (для спортсменов в возрасте от
                                                13 лет и старше)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Теория:</span><i> Билет №
                                                1</i></b> Что означает слово Таэквон-до? Духовные принципы Таэквон-до.
                                                Назвать на корейском языке уровни нанесения ударов в Таэквон-до.</p>
                                            <p><b><i>Ответ: </i></b>Корейское «таэ» озночает «выполнение удара ногой в
                                                прыжке или полете», «квон» — «кулак» (главным образом в контексте
                                                нанесения удара или разбивания чего-либо рукой), «до» — «искусство»,
                                                «путь» — путь истины, проторенный в прошлом, покрытый святостью и
                                                мудростью. Соединив все вместе, получаем, что слово «таэквон-до»
                                                обозначает систему духовной тренировки и технику самообороны без оружия
                                                наряду со здоровьем, а также квалифицированным исполнением ударов,
                                                блоков и прыжков, выполняющихся голыми руками и ногами для поражения
                                                одного или нескольких соперников. Принципы таэквон-до: 1) Учтивость (Ye
                                                Ui); 2) Честность(Yom Chi); 3) Настойчивость (In Nae); 4) Самообладание
                                                (Guk Gi); 5) Непоколебимость духа (Baekjul Boolgool).</p>
                                            <p>«Нопундэ» — верхний уровень (выше уровня плеч); «Каундэ» — средний
                                                уровень (между уровнем пояса и уровнем плеч); «Наджундэ» — нижний
                                                уровень (ниже уровня пояса).</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 2</i></b> Дата&nbsp; рождения Таэквон-до. Расшифровать
                                                духовный принцип «Учтивость». Туль «ЧОН-ДЖИ» (расшифровать название и
                                                указать количество движений)</p>
                                            <p><b><i>&nbsp;Ответ: </i></b>свое название Таэквон-до получило 11 апреля
                                                1955 года.<b></b></p>
                                            <p>Формируя свой характер, занимающиеся Таэквон-до должны практиковать
                                                следующие проявления учтивости, включая их как обязательные элементы в
                                                тренировочный процесс:</p>
                                            <ol>
                                                <li>Развивать дух взаимоуважения.</li>
                                                <li>Стыдиться своих вредных привычек.</li>
                                                <li>Быть вежливым.</li>
                                                <li>Поддерживать атмосферу справедливости и гуманизма.</li>
                                                <li>Соблюдать иерархию между занимающимися и инструктором, начинающими
                                                    и&nbsp; квалифицированными спортсменами, старшими и младшими.
                                                </li>
                                                <li>Вести себя в соответствии с принятым этикетом.</li>
                                                <li>Уважать положение других.</li>
                                                <li>Вести себя с другими честно и открыто.<b></b></li>
                                            </ol>
                                            <p>«Чон Джи» переводится как «рай земной». На востоке оно
                                                интерпретируется&nbsp; как создание мира и зарождение истории
                                                человечества. Это первый туль, с которым знакомится новичок. Он состоит
                                                из двух частей, первая из которых символизирует Землю, а вторая –
                                                Небеса.<b> </b>Количество движений: 19.</p>
                                            <p><b><i>Билет № 3</i></b>Дата основания Интернациональной федерации
                                                Таэквон-до. Что обозначает желтый цвет пояса? Расшифровать духовный
                                                принцип «Честность».</p>
                                            <p>Международной Федерации Таэквон-до (ITF) была создана 22 марта 1966 года
                                                ассоциациями таэквон-до Вьетнама, ФРГ, США, Малайзии, Египта, Турции,
                                                Италии, Сингапура и Кореи.</p>
                                            <p>Желтый – символизирует Землю, в которую кладут семя и из которой
                                                произрастают корни Таэквон-до.</p>
                                            <p>Каждый должен уметь отличать правильное от ложного, в случае собственной
                                                неправоты, испытывать угрызения совести. Ниже приводятся несколько
                                                примеров, иллюстрирующих недостаток собранности:</p>
                                            <ol start="1">
                                                <li>Преподавание инструктором неверной техники из-за пробелов в
                                                    образовании или безответственности.
                                                </li>
                                                <li>Использование спортсменом подпиленных досок при демонстрации силы
                                                    ударов.
                                                </li>
                                                <li>Попытки инструктора закамуфлировать недостатки своей техники
                                                    созданием хороших условий для тренировок.
                                                </li>
                                                <li>Выспрашивание у инструктора более высокого пояса, попытки его
                                                    купить.
                                                </li>
                                                <li>Попытки получить пояс для осуществления грязных намерений или для
                                                    демонстрации своего превосходства.
                                                </li>
                                                <li>Обучение инструктором своему искусству только во имя удовлетворения
                                                    собственных материальных запросов.
                                                </li>
                                                <li>Расхождение слова и дела.</li>
                                                <li>Игнорирование спортсменом мнения младших.</li>
                                            </ol>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 4</i></b> Имя нынешнего президента Таэквон-до. Расшифровать
                                                духовный принцип «Настойчивость». «ДО-САН» (расшифровать название и
                                                указать количество движений)</p>
                                            <p><b><i>Ответ: </i></b>нынешний президент Международной Федерации
                                                Таэквон-до - мастер Ри Ёнг Сон, Почетный президент Международной
                                                Федерации Таэквон-до - корейский профессор Чан Унг.
                                            </p>
                                            <p>Древние говорили: «Настойчивость приводит к очищению и совершенству».
                                                «Тот, кто будет стократно настойчив, обеспечит мир своему дому». Счастье
                                                и удача чаще всего достаются&nbsp;&nbsp; настойчивым. Для того, чтобы
                                                чего-либо достичь, необходимо поставить перед собой цель и настойчиво ее
                                                добиваться. Один из секретов становления мастерства в Таэквон-до –
                                                настойчивое преодоление всех трудностей.</p>
                                            <p>Название туля «До Сан» является псевдонимом известного патриота Ан Чхан
                                                Хо (1876-1938). Включение в туль 24 движений символизирует годы жизни,
                                                посвященные этим человеком просветительской деятельности и участию в
                                                движении за освобождение Кореи.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 5</i></b><i> </i>Для чего необходим «Туль». «ДАН-ГУН»
                                                (расшифровать название и указать количество движений). &nbsp;Какие цвета
                                                поясов существуют в Таэквон-до.</p>
                                            <p><b><i>Ответ: </i></b>Использование в тренировке туль позволяет путем
                                                повторения многих связок движений совершенствовать спарринг, повышать
                                                гибкость, овладеть техникой движений, укреплять мышцы, учиться контролю
                                                дыхания, оттачивать плавность и ритмичность движений.</p>
                                            <p>Туль «Дан Гун» назван в честь Дан Гуна – родоначальника корейской нации и
                                                основателя Дангунской Кореи, существовавшей в 2333 г. до н.э.. Туль
                                                насчитывает 21 движение.</p>
                                            <p>В Таэквон-до существуют такие цвета поясов: белый, желтый, зеленый,
                                                синий, красный и черный.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 6</i></b><i>&nbsp;&nbsp; </i>&nbsp;Почему пояс в Таэквон-до
                                                завязывается в один обхват? Как называется площадка для проведения
                                                соревнований<i> </i>по<i>&nbsp; </i>Таэквон-до? Расшифровать духовный
                                                принцип «Самоконтроль».</p>
                                            <p><b><i>Ответ: </i></b>главное назначение пояса – подчеркнуть значение
                                                философских основ Таэквон-до, а также обозначить квалификацию
                                                спортсмена. 1 июля 1985 года было принято решение о том, что пояс должен
                                                опоясывать тело занимающегося один раз а не дважды, как это
                                                практиковалось ранее.<b> </b>Один оборот пояса вокруг тела
                                                символизирует: 1- «О до иль кван» — стремлении, во что бы то ни стало
                                                достичь одну, ранее поставленную цель;&nbsp; 2 – «Иль пхэн дан сим» —
                                                служение одному делу с непоколебимой преданностью;&nbsp; 3 – «Иль кёк
                                                пхиль сунг» — достижение победы путем проведения одного удара.</p>
                                            <p>Площадка для проведения соревнований<i> </i>по<i>&nbsp; </i>Таэквон-до
                                                называется «Доянг».</p>
                                            <p>Самообладание чрезвычайно важно как в тренировочном зале, так и в
                                                повседневной жизни. Потеря самоконтроля во время спарринга может
                                                привести к очень серьёзным последствиям для обоих соперников.
                                                Неуживчивость внутри коллектива является проявлением плохого
                                                самоконтроля. Великий китайский мыслитель Лао Цзы говорил: <b><i>«Сильный
                                                    – тот, кто умеет побеждать себя, а не другого».</i></b></p>
                                            <p>&nbsp;</p>
                                            <p><i><b>Билет № 7</b>&nbsp; </i>Что символизирует надпись на спине добка?
                                                Рассказать все о стойке «Гоннун соги». Ф.И.О президента Федерации
                                                Таэквон-до&nbsp; И.Т.Ф. Украины.</p>
                                            <p><b><i>Ответ: </i></b>надпись делается в виде вечнозеленого дерева –
                                                сосны.</p>
                                            <p>«Гоннун соги» является наиболее эффективной стойкой для выполнения
                                                атакующих и защитных действий, направленных вперед или назад.</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад так, чтобы расстояние между
                                                    пальцами в
                                                </li>
                                            </ol>
                                            <p>продольном направлении составляло 1,5 ширины плеч, а расстояние между
                                                центрами стоп (в поперечном направлении) равнялось ширине плеч.</p>
                                            <ol>
                                                <li>Колено передней ноги сгибается таким образом, что воображаемая
                                                    вертикальная линия одновременно проходит через коленный сустав и
                                                    пятку. Задняя нога должна быть прямой.
                                                </li>
                                                <li>Вес тела распределен равномерно на обе ноги.</li>
                                                <li>Стопа передней ноги обращена вперед, стопа задней ноги повернута
                                                    наружу на 25°. Поворот задней ноги боле чем на 25° затрудняет
                                                    защитные действия при атаке сзади.
                                                </li>
                                                <li>Мышцы стоп должны быть напряжены (должно возникать чувство, что
                                                    стопы притягиваются друг к другу).
                                                </li>
                                                <li>При выполнении атакующих и защитных действий из этой стойки
                                                    таэквондист может быть обращен к сопернику лицом или в пол-оборота.
                                                </li>
                                            </ol>
                                            <p>Президент Федерации Таэквон-до&nbsp; И.Т.Ф. Украины – Потернак Виктор
                                                Николаевич (мастер VIII дан).</p>
                                            <p>&nbsp;</p>
                                            <p><i><b>Билет № 8</b>&nbsp;&nbsp; </i>Сколько существует тулей в
                                                Таэквон-до? Рассказать все о стойке «Нюнча соги». &nbsp;Расшифровать
                                                духовный принцип «Непоколебимость духа».</p>
                                            <p><b><i>Ответ:</i> </b>в Таэквон-до существует 24 туля, что символизирует
                                                24 часа в сутках, т.е. жизнь человека.</p>
                                            <p>Стойка «Нюнча соги» широко применяется при выполнении как защитных, так и
                                                атакующих действий. Для нанесения удара передней ногой требуется лишь
                                                незначительное перераспределить вес тела. Преимуществами данной стойки
                                                считаются возможность выполнения проскальзывания и расположения
                                                таэквондиста в пол-оборота к сопернику .</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад так, чтобы расстояние между
                                                    пальцами передней ноги и наружным краем задней ноги в продольном
                                                    направлении составляло 1,5 ширины плеч. Стопы при этом располагаются
                                                    почти под прямым углом. Рекомендуется повернуть обе стопы внутрь
                                                    примерно на 15°. Для повышения устойчивости расстояние между пятками
                                                    во фронтальной плоскости должно составлять примерно 2,5 см.
                                                </li>
                                                <li>Колено задней ноги сгибается таким образом, чтобы воображаемая
                                                    вертикальная линия одновременно проходила через коленный сустав и
                                                    пальцы стопы. Колено задней ноги сгибается пропорционально передней.
                                                </li>
                                                <li>Таз должен располагаться над внутренним краем коленного сустава
                                                    задней ноги.
                                                </li>
                                                <li>Примерно 70% веса тела должно приходится на заднюю и 30% на переднюю
                                                    ногу.
                                                </li>
                                            </ol>
                                            <p><b><i>&nbsp;«Здесь покоятся триста, выполнивших свой долг».</i></b> Эта
                                                простая эпитафия относится к одному из величайших подвигов в истории
                                                человечества. Леонид и 300 спартанцев, став насмерть на пути
                                                полчищ&nbsp; Ксеркса, показали миру, что такое непоколебимый дух.</p>
                                            <p>Занимающиеся Таэквон-до должны быть честными и искренними. Столкнувшись с
                                                несправедливостью, они должны действовать активно, ничего не боясь,
                                                проявлять непоколебимый дух, независимо от того, кто им противостоит и
                                                как много противников.</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p align="center"><a className="belt-with-margin" name="6 KUP"></a><b>6 ГЫП (<a
                                                style={{color: 'green'}}>зеленый</a> пояс)</b></p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Туль:</span>
                                                <i>«ДО-САН»</i></b></p>
                                            <p><b><i>&nbsp; «ВОН-ХЁ»</i></b>;&nbsp; <b>&nbsp;</b></p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Удары ногами<i>:</i></span></b><i> Ап
                                                чаги, Долльо чаги, Ануро нэрьо чаги, Бакуро нэрьо чаги, Горо чаги, Йоп
                                                чаги, Тора йоп чаги, Твимьо ап чаги</i> (наносится с задней ноги), <i>Твимьо
                                                йоп чаги</i> (наносится с передней ноги).</p>
                                            <p>Данные удары ногами выполняются попеременно левой-правой ногой из
                                                положения <i>Нюнча со пальмок дэби макки</i>. Количество — на усмотрение
                                                инструктора.</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Спарринг:</span></b>&nbsp; Самбо
                                                матсоги (спарринг на 3 шага с партнером)</p>
                                            <p>Свободный спарринг (30 сек.)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>ОФП:</span></b> 20-отжиманий,40-приседаний,
                                                20-«складочка» (для спортсменов в возрасте до 12 лет)</p>
                                            <p>25-отжиманий,45-приседаний, 25-«складочка» (для спортсменов в возрасте от
                                                13 лет и старше)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Теория:</span></b>&nbsp;&nbsp; <b><i>Билет
                                                № 1</i></b> Духовные принципы Таэквон-до (краткое определение каждого
                                                принципа).</p>
                                            <p><b><i>Ответ: </i></b>Принципы таэквон-до:</p>
                                            <p>1) Учтивость (Ye Ui). Формируя свой характер, занимающиеся Таэквон-до
                                                должны практиковать следующие проявления учтивости, включая их как
                                                обязательные элементы в тренировочный процесс: развивать дух
                                                взаимоуважения; стыдиться своих вредных привычек; быть вежливым;
                                                поддерживать атмосферу справедливости и гуманизма; соблюдать иерархию
                                                между занимающимися и инструктором, начинающими
                                                и&nbsp; квалифицированными спортсменами, старшими и младшими; вести себя
                                                в соответствии с принятым этикетом; уважать положение других; вести себя
                                                с другими честно и открыто.<b></b></p>
                                            <p>2) Честность(Yom Chi). Каждый должен уметь отличать правильное от
                                                ложного, в случае собственной неправоты, испытывать угрызения
                                                совести.</p>
                                            <p>3) Настойчивость (In Nae). &nbsp;Древние говорили: «Настойчивость
                                                приводит к очищению и совершенству». «Тот, кто будет стократно
                                                настойчив, обеспечит мир своему дому». Счастье и удача чаще всего
                                                достаются&nbsp;&nbsp; настойчивым. Для того, чтобы чего-либо достичь,
                                                необходимо поставить перед собой цель и настойчиво ее добиваться. Один
                                                из секретов становления мастерства в</p>
                                            <p>Таэквон-до – настойчивое преодоление всех трудностей.</p>
                                            <p>4) Самообладание (Guk Gi). Самообладание чрезвычайно важно как в
                                                тренировочном зале, так и в повседневной жизни. Потеря самоконтроля во
                                                время спарринга может привести к очень серьёзным последствиям для обоих
                                                соперников. Неуживчивость внутри коллектива является проявлением плохого
                                                самоконтроля. Великий китайский мыслитель Лао Цзы говорил: <b><i>«Сильный
                                                    – тот, кто умеет побеждать себя, а не другого».</i></b></p>
                                            <p>5) Непоколебимость духа (Baekjul Boolgool). Занимающиеся Таэквон-до
                                                должны быть честными и искренними. Столкнувшись с несправедливостью, они
                                                должны действовать активно, ничего не боясь, проявлять непоколебимый
                                                дух, независимо от того, кто им противостоит и как много
                                                противников.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 2 </i></b>&nbsp;Дата основания Таэквон-до&nbsp; «ДО-САН»
                                                (расшифровать название и указать количество движений). Для чего
                                                необходим спарринг?</p>
                                            <p><b><i>Ответ: </i></b>свое название Таэквон-до получило 11 апреля 1955
                                                года.</p>
                                            <p>Название туля «До Сан» является псевдонимом известного патриота Ан Чхан
                                                Хо (1876-1938). Включение в туль 24 движений символизирует годы жизни,
                                                посвященные этим человеком просветительской деятельности и участию в
                                                движении за освобождение Кореи.</p>
                                            <p>Спарринг в Таєквон-до в основном делится на два вида: свободный, который
                                                похож на бокс, и спарринг с заранее оговоренным сценарием. Свободный
                                                спарринг позволяет развить бойцовский дух и храбрость, научиться
                                                «читать» маневры и тактику соперника, получить объективную оценку своего
                                                мастерства. А спарринг с заранее оговоренным сценарием дает возможность
                                                применять умения и навыки, приобретенные при изучении тулей и базовой
                                                техники по отношению к движущемуся сопернику. Спарринг имеет неразрывную
                                                связь с тулями и базовой техникой. С другой стороны, он полезен и для
                                                закалки атакующих и защитных сегментов тела.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp; Билет № 3&nbsp; </i></b>Имя и дата рождения основателя
                                                Таэквон-до.&nbsp; Назвать 4 основных блока и их краткое описание.</p>
                                            <p><b><i>Ответ: </i></b>основатель Таэквон-до, генерал Чой Хонг Хи родился 9
                                                ноября 1918 года в&nbsp; местечке Хвадэ (территория нынешней КНДР).</p>
                                            <p>«Наджундэ макги» – нижний блок;</p>
                                            <p>«Чукё макги» — верхний блок;</p>
                                            <p>«Анпальмок каунде макги»&nbsp; — средний блок (изнутри наружу);</p>
                                            <p>«Ануро бакатпальмок макги» — средний блок (снаружи внутрь).</p>
                                            <p><span style={{textDecoration: 'underline'}}>&nbsp;</span></p>
                                            <p><b><i>Билет № 4</i></b> Чарёт соги,<b><i> </i></b>Нарани соги, Гоннун
                                                соги, Нюнча соги, Аннун соги- краткое описание стоек.</p>
                                            <p><b><i>Ответ: </i></b>«Чарёт соги» (моа соги) – стопы располагаются рядом.
                                                При этой стойке таэквондист обращен к сопернику или лицом или боком.</p>
                                            <p>«Нарани соги» — стопы располагаются параллельно на ширине плеч.
                                                Таэквондист обращен к сопернику или лицом или боком.</p>
                                            <p>«Гоннун соги» является наиболее эффективной стойкой для выполнения
                                                атакующих и защитных действий, направленных вперед или назад.</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад так, чтобы расстояние между
                                                    пальцами в
                                                </li>
                                            </ol>
                                            <p>продольном направлении составляло 1,5 ширины плеч, а расстояние между
                                                центрами стоп (в поперечном направлении) равнялось ширине плеч.</p>
                                            <ol>
                                                <li>Колено передней ноги сгибается таким образом, что воображаемая
                                                    вертикальная линия одновременно проходит через коленный сустав и
                                                    пятку. Задняя нога должна быть прямой.
                                                </li>
                                                <li>Вес тела распределен равномерно на обе ноги.</li>
                                                <li>Стопа передней ноги обращена вперед, стопа задней ноги повернута
                                                    наружу на 25°. Поворот задней ноги боле чем на 25° затрудняет
                                                    защитные действия при атаке сзади.
                                                </li>
                                                <li>Мышцы стоп должны быть напряжены (должно возникать чувство, что
                                                    стопы притягиваются друг к другу).
                                                </li>
                                                <li>При выполнении атакующих и защитных действий из этой стойки
                                                    таэквондист может быть обращен к сопернику лицом или в пол-оборота.
                                                </li>
                                            </ol>
                                            <p>«Нюнча соги» широко применяется при выполнении как защитных, так и
                                                атакующих действий. Для нанесения удара передней ногой требуется лишь
                                                незначительное перераспределить вес тела. Преимуществами данной стойки
                                                считаются возможность выполнения проскальзывания и расположения
                                                таэквондиста в пол-оборота к сопернику .</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад так, чтобы расстояние между
                                                    пальцами передней ноги и наружным краем задней ноги в продольном
                                                    направлении составляло 1,5 ширины плеч. Стопы при этом располагаются
                                                    почти под прямым углом. Рекомендуется повернуть обе стопы внутрь
                                                    примерно на 15°. Для повышения устойчивости расстояние между пятками
                                                    во фронтальной плоскости должно составлять примерно 2,5 см.
                                                </li>
                                                <li>Колено задней ноги сгибается таким образом, чтобы воображаемая
                                                    вертикальная линия одновременно проходила через коленный сустав и
                                                    пальцы стопы. Колено задней ноги сгибается пропорционально передней.
                                                </li>
                                                <li>Таз должен располагаться над внутренним краем коленного сустава
                                                    задней ноги.
                                                </li>
                                                <li>Примерно 70% веса тела должно приходится на заднюю и 30% на переднюю
                                                    ногу.
                                                </li>
                                            </ol>
                                            <p>«Аннун соги» очень удобная стойка для выполнения движений в сторону.
                                                Широко используется при совершенствовании ударов руками, тренировке мышц
                                                ног.</p>
                                            <ol>
                                                <li>Стопы располагаются параллельно на расстоянии 1,5 ширины плеч.</li>
                                                <li>Вес тела распределен равномерно на обе ноги.</li>
                                                <li>Коленные суставы находятся над подушечками стоп.</li>
                                                <li>Наружные края стоп упираются в опору (как бы отталкиваясь каждой
                                                    ногой внутрь).
                                                </li>
                                                <li>Грудь и живот поданы вперед. Мышцы брюшного пресса напряжены.</li>
                                                <li>При выполнении атакующих и защитных действий из этой стойки
                                                    таэквондист может быть обращен к сопернику лицом или боком.
                                                </li>
                                            </ol>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 5</i></b>Что означает слово Таэквон-до? Сколько тулей в
                                                Таэквон-до и почему именно такое количество? «ДО-САН» (расшифровать
                                                название и указать количество движений)</p>
                                            <p><b><i>Ответ: &nbsp;</i></b>Корейское «таэ» озночает «выполнение удара
                                                ногой в прыжке или полете», «квон» — «кулак» (главным образом в
                                                контексте нанесения удара или разбивания чего-либо рукой), «до» —
                                                «искусство», «путь» — путь истины, проторенный в прошлом, покрытый
                                                святостью и мудростью. Соединив все вместе, получаем, что слово
                                                «таэквон-до» обозначает систему духовной тренировки и технику
                                                самообороны без оружия наряду со здоровьем, а также квалифицированным
                                                исполнением ударов, блоков и прыжков, выполняющихся голыми руками и
                                                ногами для поражения одного или нескольких соперников.</p>
                                            <p>В Таэквон-до существует 24 туля, что символизирует 24 часа в сутках, т.е.
                                                жизнь человека. Человеческая жизнь (грубо говоря 100 лет) в масштабе
                                                вечности может рассматриваться как один день. Таким образом, все люди –
                                                простые странники, проходящие перед лицом вечности за один день. Никто
                                                не может прожить дольше отрезка времени, отпущенного ему для жизни. Но,
                                                несмотря на это, многие люди так сильно стремятся к материальным благам,
                                                как будто они собираются прожить тысячелетия. Немногим удаётся оставить
                                                грядущим поколениям образцы высокого духа и таким образом обрести
                                                бессмертие. Дух вечен, материальные блага – нет. Самым главным в жизни
                                                есть то что передаётся грядущим поколениям.</p>
                                            <p>Название туля «До Сан» является псевдонимом известного патриота Ан Чхан
                                                Хо (1876-1938). Включение в туль 24 движений символизирует годы жизни,
                                                посвященные этим человеком просветительской деятельности и участию в
                                                движении за освобождение Кореи.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 6</i></b>Что обозначает зеленый цвет пояса? «ВОН-ХЁ»
                                                (расшифровать название и указать количество движений).</p>
                                            <p><b><i>Ответ: </i></b>зеленый символизирует начальные этапы процесса роста
                                                растений, которому уподобляется процесс развития Таэквон-до.</p>
                                            <p>Туль «Вон Хё» назван в честь монаха, познакомившего в 686 г до н.э.
                                                корейскую династию Силла с буддизмом. Количество движений – 28.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 7</i></b>Взаимоотношения «ученик-инструктор» (предписания,
                                                которые им необходимо выполнять)</p>
                                            <p><b><i>Ответ:</i></b>&nbsp; <b>&nbsp;</b>Ответственность &nbsp;за обучение<b> &nbsp;</b>искусству
                                                Таэквон-до лежит на инструкторах, основная задача которых состоит в том,
                                                чтобы сделать учеников физически и духовно здоровыми и тем самым внести
                                                свой вклад в создание мирного общества. Конечно же, каждой школе
                                                необходим преданный и искренний инструктор. Но ни одна школа не сможет
                                                расти и развиваться при отсутствии столь же преданных и искренних
                                                учеников.<b>&nbsp;&nbsp; </b></p>
                                            <p>Инструктору и ученику необходимо выполнять следующие предписания:<br/>
                                                ИНСТРУКТОР<br/>
                                                1. Никогда не уставать учить. Хороший инструктор может учить везде, в
                                                любое время. Он всегда должен быть готов отвечать на вопросы.<br/>
                                                2. Инструктор должен стремиться, чтобы ученик превзошел его. Такой
                                                результат служит величайшей похвалой инструктору. Ученика никогда нельзя
                                                удерживать позади. Если инструктор понял, что ученик превзошел его
                                                возможности, ученика надо передать инструктору более высокой
                                                квалификации.<br/>
                                                3. Инструктор всегда должен служить примером для учеников и никогда не
                                                пытаться обманывать их.<br/>
                                                4. Воспитание ученика должно быть выше материальной заинтересованности.
                                                Как только инструктор станет озабочен материальными аспектами обучения,
                                                он сразу же потеряет уважение учеников.<br/>
                                                5. Инструктор должен учить, основываясь на научной базе, чтобы экономить
                                                силы и время.<br/>
                                                6. Инструктор должен помогать ученикам устанавливать хорошие отношения
                                                за пределами спортивного зала. Обязанность инструктора — заниматься
                                                воспитанием учеников как в зале, так и за его пределами.<br/>
                                                7. Необходимо поощрять посещение других спортзалов, изучение опыта
                                                других школ. Ученики, которым запрещено посещать другие школы, могут
                                                стать непослушными. Целесообразность посещения других школ обусловлена
                                                тем, что ученик не только сможет подобрать школу, которая ему подходит
                                                наилучшим образом, но и многому научиться, сравнивая разные школы.<br/>
                                                8. Ко всем ученикам следует относиться одинаково. Любимчиков быть не
                                                должно. Ругать ученика можно только наедине с ним и никогда не делать
                                                этого на глазах других.<br/>
                                                9. Если инструктор не знает ответа на вопрос, ему не следует придумывать
                                                ответ. Надо признать свою неосведомленность и попытаться найти ответ как
                                                можно быстрее.<br/>
                                                10. Инструктор не должен искать у учеников такого рода помощи, как
                                                уборка кабинетов, ремонт и т.п.<br/>
                                                11. Инструктор не должен использовать своих учеников для личных целей.
                                                Единственная цель инструктора — воспитать физически и духовно
                                                совершенных учеников.<br/>
                                                12. Инструктор должен всегда быть честным с учениками, не терять их
                                                доверия.<br/>
                                                УЧЕНИКИ<br/>
                                                1. Никогда не уставать учиться. Хороший ученик может учиться везде и в
                                                любое время. В этом секрет успеха в учебе.<br/>
                                                2. Хороший ученик готов на жертвы ради своего боевого искусства и
                                                инструктора. Некоторые ученики рассматривают обучение как товар,
                                                купленный за ежемесячную плату, и не желают участвовать в выступлениях,
                                                обучении или работе в спортзале. Инструктор может позволить себе
                                                избавиться от таких учеников.<br/>
                                                3. Всегда быть хорошим примером для младших учеников. Вполне
                                                естественно, что они захотят соревноваться со старшими.<br/>
                                                4. Всегда быть преданными, никогда не критиковать инструктора,
                                                применяемые способы обучения и само таэквон-до.<br/>
                                                5. Изучать и стараться использовать показанный инструктором прием.<br/>
                                                6. Помнить, что поведение ученика за пределами спортзала отражается на
                                                боевом искусстве и на инструкторе.<br/>
                                                7. Если ученик освоил новые приемы из другой школы, а инструктор
                                                возражает против их применения, ученик должен отказаться от них, либо
                                                заниматься там, где он выучил эти приемы.<br/>
                                                8. Никогда не проявлять неуважение к инструктору. Хотя ученику
                                                позволительно не соглашаться с инструктором, он должен сначала выполнять
                                                задание, а обсуждать все вопросы позже.<br/>
                                                9. Всегда быть активным, не стесняясь задавать вопросы.<br/>
                                                10. Никогда не предавать инструктора.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 8</i></b>Имя нынешнего президента Международной Федерации
                                                Таэквон-до. <b><i>&nbsp;</i></b>Виды спарринга (на корейском
                                                языке)<b><i> </i></b></p>
                                            <p><b><i>&nbsp;Ответ: *</i></b>нынешний президент Международной Федерации
                                                Таэквон-до - мастер Ри Ёнг Сон, Почетный президент Международной
                                                Федерации Таэквон-до - корейский профессор Чан Унг.
                                            </p>
                                            <p>*Главным образом различают следующие виды спарринга:</p>
                                            <p>«Яксок масоги» (обусловленный) делится на «самбо» (на 3 шага), «ибо» (на
                                                2 шага), «ильбо» (на 1 шаг).</p>
                                            <p>«Пан чжаю масоги» — полусвободный спарринг.</p>
                                            <p>«Чжаю масоги» — свободный спарринг.</p>
                                            <p>«Мобом масоги» — модельный спарринг.</p>
                                            <p>«Баль масоги» — спарринг с использованием только действий ногами.</p>
                                            <p>«Яксок чжаю масоги» — свободный спарринг с заранее оговоренным
                                                сценарием.</p>
                                            <p><b><i>&nbsp;</i></b></p>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp;</i></b></p>
                                            <p><b><i>&nbsp;</i></b></p>
                                            <p>&nbsp;</p>
                                            <p align="center"><a className="belt-with-margin" name="5 KUP"></a><b>5</b><b> ГЫП</b><b> (<a
                                                style={{color: 'green'}}>зелено</a>-</b><b><a
                                                style={{color: 'blue'}}>синий</a></b><b> пояс)</b></p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Туль<i>:</i></span><i> «ВОН-ХЁ»</i></b>
                                            </p>
                                            <p><b><i>&nbsp; «ЮЛЬ-ГОК»&nbsp; ;</i></b></p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Удары ногами:</span></b>
                                                <i>Ап чаги, Долльо чаги, Ануро нэрьо чаги, Бакуро нэрьо чаги, Горо чаги,
                                                    Йоп чаги, Тора йоп чаги, Твимьо ап чаги</i> (наносится с задней
                                                ноги), <i>Твимьо йоп чаги, Твимьо долльо чаги, Твимьо горо чаги </i>(эти
                                                3 удара наносится с передней ноги), <i>Твимьо тора йоп чаги, Ибо долльо
                                                    чаги</i>(удары наносятся с задней ноги на средний и верхний уровень
                                                туловища).</p>
                                            <p>Удары ногами выполняются попеременно левой-правой ногой из положения <i>Нюнча
                                                со пальмок дэби макки.</i> Количество — на усмотрение инструктора.</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Спарринг:</span></b>&nbsp; Самбо
                                                матсоги (спарринг на 3 шага с партнером)</p>
                                            <p>Ибо матсоги (спарринг на 2 шага с партнером)</p>
                                            <p>Свободный спарринг (1 мин.)</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>ОФП:</span></b> 25-отжиманий,45-приседаний,
                                                25-«складочка» (для спортсменов в возрасте до 12 лет)</p>
                                            <p>30-отжиманий,50-приседаний, 30-«складочка» (для спортсменов в возрасте от
                                                13 лет и старше)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Теория:</span></b> &nbsp;
                                                <b><i>Билет № 1</i></b> Духовные принципы Таэквон-до (краткое
                                                определение каждого принципа).</p>
                                            <p><b><i>Ответ: </i></b>Принципы таэквон-до:</p>
                                            <p>1) Учтивость (Ye Ui). Формируя свой характер, занимающиеся Таэквон-до
                                                должны практиковать следующие проявления учтивости, включая их как
                                                обязательные элементы в тренировочный процесс: развивать дух
                                                взаимоуважения; стыдиться своих вредных привычек; быть вежливым;
                                                поддерживать атмосферу справедливости и гуманизма; соблюдать иерархию
                                                между занимающимися и инструктором, начинающими
                                                и&nbsp; квалифицированными спортсменами, старшими и младшими; вести себя
                                                в соответствии с принятым этикетом; уважать положение других; вести себя
                                                с другими честно и открыто.<b></b></p>
                                            <p>2) Честность(Yom Chi). Каждый должен уметь отличать правильное от
                                                ложного, в случае собственной неправоты, испытывать угрызения
                                                совести.</p>
                                            <p>3) Настойчивость (In Nae).&nbsp; Древние говорили: «Настойчивость
                                                приводит к очищению и совершенству». «Тот, кто будет стократно
                                                настойчив, обеспечит мир своему дому». Счастье и удача чаще всего
                                                достаются&nbsp;&nbsp; настойчивым. Для того, чтобы чего-либо достичь,
                                                необходимо поставить перед собой цель и настойчиво ее добиваться. Один
                                                из секретов становления мастерства в</p>
                                            <p>Таэквон-до – настойчивое преодоление всех трудностей.</p>
                                            <p>4) Самообладание (Guk Gi). Самообладание чрезвычайно важно как в
                                                тренировочном зале, так и в повседневной жизни. Потеря самоконтроля во
                                                время спарринга может привести к очень серьёзным последствиям для обоих
                                                соперников. Неуживчивость внутри коллектива является проявлением плохого
                                                самоконтроля. Великий китайский мыслитель Лао Цзы говорил: <b><i>«Сильный
                                                    – тот, кто умеет побеждать себя, а не другого».</i></b></p>
                                            <p>5) Непоколебимость духа (Baekjul Boolgool). Занимающиеся Таэквон-до
                                                должны быть честными и искренними. Столкнувшись с несправедливостью, они
                                                должны действовать активно, ничего не боясь, проявлять непоколебимый
                                                дух, независимо от того, кто им противостоит и как много
                                                противников.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 2 </i></b>&nbsp;Дата основания Таэквон-до&nbsp; «ДО-САН»
                                                (расшифровать название и указать количество движений). Для чего
                                                необходим спарринг?</p>
                                            <p><b><i>Ответ: </i></b>свое название Таэквон-до получило 11 апреля 1955
                                                года.</p>
                                            <p>Название туля «До Сан» является псевдонимом известного патриота Ан Чхан
                                                Хо (1876-1938). Включение в туль 24 движений символизирует годы жизни,
                                                посвященные этим человеком просветительской деятельности и участию в
                                                движении за освобождение Кореи.</p>
                                            <p>Спарринг в Таєквон-до в основном делится на два вида: свободный, который
                                                похож на бокс, и спарринг с заранее оговоренным сценарием. Свободный
                                                спарринг позволяет развить бойцовский дух и храбрость, научиться
                                                «читать» маневры и тактику соперника, получить объективную оценку своего
                                                мастерства. А спарринг с заранее оговоренным сценарием дает возможность
                                                применять умения и навыки, приобретенные при изучении тулей и базовой
                                                техники по отношению к движущемуся сопернику. Спарринг имеет неразрывную
                                                связь с тулями и базовой техникой. С другой стороны, он полезен и для
                                                закалки атакующих и защитных сегментов тела.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 3&nbsp; </i></b>Имя и дата рождения основателя
                                                Таэквон-до.&nbsp; Назвать 4 основных блока и их краткое описание.</p>
                                            <p><b><i>Ответ: </i></b>основатель Таэквон-до, генерал Чой Хонг Хи родился 9
                                                ноября 1918 года в&nbsp; местечке Хвадэ (территория нынешней КНДР).</p>
                                            <p>«Наджундэ макги» – нижний блок;</p>
                                            <p>«Чукё макги» — верхний блок;</p>
                                            <p>«Анпальмок каунде макги»&nbsp; — средний блок (изнутри наружу);</p>
                                            <p>«Ануро бакатпальмок макги» — средний блок (снаружи внутрь).</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>&nbsp;</span></b></p>
                                            <p><b><i>Билет № 4</i></b> Чарёт соги,<b><i> </i></b>Нарани соги, Гоннун
                                                соги, Нюнча соги, Аннун соги- краткое описание стоек.</p>
                                            <p><b><i>Ответ: </i></b>«Чарёт соги» (моа соги) – стопы располагаются рядом.
                                                При этой стойке таэквондист обращен к сопернику или лицом или боком.</p>
                                            <p>«Нарани соги» — стопы располагаются параллельно на ширине плеч.
                                                Таэквондист обращен к сопернику или лицом или боком.</p>
                                            <p>«Гоннун соги» является наиболее эффективной стойкой для выполнения
                                                атакующих и защитных действий, направленных вперед или назад.</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад так, чтобы расстояние между
                                                    пальцами в
                                                </li>
                                            </ol>
                                            <p>продольном направлении составляло 1,5 ширины плеч, а расстояние между
                                                центрами стоп (в поперечном направлении) равнялось ширине плеч.</p>
                                            <ol>
                                                <li>Колено передней ноги сгибается таким образом, что воображаемая
                                                    вертикальная линия одновременно проходит через коленный сустав и
                                                    пятку. Задняя нога должна быть прямой.
                                                </li>
                                                <li>Вес тела распределен равномерно на обе ноги.</li>
                                                <li>Стопа передней ноги обращена вперед, стопа задней ноги повернута
                                                    наружу на 25°. Поворот задней ноги боле чем на 25° затрудняет
                                                    защитные действия при атаке сзади.
                                                </li>
                                                <li>Мышцы стоп должны быть напряжены (должно возникать чувство, что
                                                    стопы притягиваются друг к другу).
                                                </li>
                                                <li>При выполнении атакующих и защитных действий из этой стойки
                                                    таэквондист может быть обращен к сопернику лицом или в пол-оборота.
                                                </li>
                                            </ol>
                                            <p>«Нюнча соги» широко применяется при выполнении как защитных, так и
                                                атакующих действий. Для нанесения удара передней ногой требуется лишь
                                                незначительное перераспределить вес тела. Преимуществами данной стойки
                                                считаются возможность выполнения проскальзывания и расположения
                                                таэквондиста в пол-оборота к сопернику .</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад так, чтобы расстояние между
                                                    пальцами передней ноги и наружным краем задней ноги в продольном
                                                    направлении составляло 1,5 ширины плеч. Стопы при этом располагаются
                                                    почти под прямым углом. Рекомендуется повернуть обе стопы внутрь
                                                    примерно на 15°. Для повышения устойчивости расстояние между пятками
                                                    во фронтальной плоскости должно составлять примерно 2,5 см.
                                                </li>
                                                <li>Колено задней ноги сгибается таким образом, чтобы воображаемая
                                                    вертикальная линия одновременно проходила через коленный сустав и
                                                    пальцы стопы. Колено задней ноги сгибается пропорционально передней.
                                                </li>
                                                <li>Таз должен располагаться над внутренним краем коленного сустава
                                                    задней ноги.
                                                </li>
                                                <li>Примерно 70% веса тела должно приходится на заднюю и 30% на переднюю
                                                    ногу.
                                                </li>
                                            </ol>
                                            <p>«Аннун соги» очень удобная стойка для выполнения движений в сторону.
                                                Широко используется при совершенствовании ударов руками, тренировке мышц
                                                ног.</p>
                                            <ol>
                                                <li>Стопы располагаются параллельно на расстоянии 1,5 ширины плеч.</li>
                                                <li>Вес тела распределен равномерно на обе ноги.</li>
                                                <li>Коленные суставы находятся над подушечками стоп.</li>
                                                <li>Наружные края стоп упираются в опору (как бы отталкиваясь каждой
                                                    ногой внутрь).
                                                </li>
                                                <li>Грудь и живот поданы вперед. Мышцы брюшного пресса напряжены.</li>
                                                <li>При выполнении атакующих и защитных действий из этой стойки
                                                    таэквондист может быть обращен к сопернику лицом или боком.
                                                </li>
                                            </ol>
                                            <p>&nbsp;</p>
                                            <p><b><i> Билет № 5&nbsp; </i></b>Что означает слово Таэквон-до? Сколько
                                                тулей в Таэквон-до и почему именно такое количество? Для чего необходима
                                                специальная техника?</p>
                                            <p><b><i>Ответ: &nbsp;*</i></b>Корейское «таэ» озночает «выполнение удара
                                                ногой в прыжке или полете», «квон» — «кулак» (главным образом в
                                                контексте нанесения удара или разбивания чего-либо рукой), «до» —
                                                «искусство», «путь» — путь истины, проторенный в прошлом, покрытый
                                                святостью и мудростью. Соединив все вместе, получаем, что слово
                                                «таэквон-до» обозначает систему духовной тренировки и технику
                                                самообороны без оружия наряду со здоровьем, а также квалифицированным
                                                исполнением ударов, блоков и прыжков, выполняющихся голыми руками и
                                                ногами для поражения одного или нескольких соперников.</p>
                                            <p>*В Таэквон-до существует 24 туля, что символизирует 24 часа в сутках,
                                                т.е. жизнь человека. Человеческая жизнь (грубо говоря 100 лет) в
                                                масштабе вечности может рассматриваться как один день. Таким образом,
                                                все люди – простые странники, проходящие перед лицом вечности за один
                                                день. Никто не может прожить дольше отрезка времени, отпущенного ему для
                                                жизни. Но, несмотря на это, многие люди так сильно стремятся к
                                                материальным благам, как будто они собираются прожить тысячелетия.
                                                Немногим удаётся оставить грядущим поколениям образцы высокого духа и
                                                таким образом обрести бессмертие. Дух вечен, материальные блага – нет.
                                                Самым главным в жизни есть то что передаётся грядущим поколениям.</p>
                                            <p>*Специальная&nbsp; техника призвана оценить координацию движений,
                                                акробатическую подготовку и умение решать сложные двигательные
                                                задачи.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i> Билет № 6&nbsp; </i></b>Что обозначает зеленый цвет пояса?
                                                «ВОН-ХЁ» (расшифровать название и указать количество движений). Виды
                                                спарринга (на корейском языке)</p>
                                            <p><b><i>Ответ: &nbsp;*</i></b>зеленый символизирует начальные этапы
                                                процесса роста растений, которому уподобляется процесс развития
                                                Таэквон-до.</p>
                                            <p>*Туль «Вон Хё» назван в честь монаха, познакомившего в 686 г до н.э.
                                                корейскую династию Силла с буддизмом. Количество движений – 28.</p>
                                            <p><b><i>&nbsp;</i></b></p>
                                            <p><b><i> Билет № 7 </i></b>Взаимоотношения «ученик-инструктор»
                                                (предписания, которые им необходимо выполнять); <b></b></p>
                                            <p><b><i>Ответ: &nbsp;*</i></b>Ответственность&nbsp; за
                                                обучение<b> &nbsp;</b>искусству Таэквон-до лежит на инструкторах,
                                                основная задача которых состоит в том, чтобы сделать учеников физически
                                                и духовно здоровыми и тем самым внести свой вклад в создание мирного
                                                общества. Конечно же, каждой школе необходим преданный и искренний
                                                инструктор. Но ни одна школа не сможет расти и развиваться при
                                                отсутствии столь же преданных и искренних учеников.<b>&nbsp;&nbsp; </b>
                                            </p>
                                            <p>Инструктору и ученику необходимо выполнять следующие предписания:<br/>
                                                ИНСТРУКТОР<br/>
                                                1. Никогда не уставать учить. Хороший инструктор может учить везде, в
                                                любое время. Он всегда должен быть готов отвечать на вопросы.<br/>
                                                2. Инструктор должен стремиться, чтобы ученик превзошел его. Такой
                                                результат служит величайшей похвалой инструктору. Ученика никогда нельзя
                                                удерживать позади. Если инструктор понял, что ученик превзошел его
                                                возможности, ученика надо передать инструктору более высокой
                                                квалификации.<br/>
                                                3. Инструктор всегда должен служить примером для учеников и никогда не
                                                пытаться обманывать их.<br/>
                                                4. Воспитание ученика должно быть выше материальной заинтересованности.
                                                Как только инструктор станет озабочен материальными аспектами обучения,
                                                он сразу же потеряет уважение учеников.<br/>
                                                5. Инструктор должен учить, основываясь на научной базе, чтобы экономить
                                                силы и время.<br/>
                                                6. Инструктор должен помогать ученикам устанавливать хорошие отношения
                                                за пределами спортивного зала. Обязанность инструктора — заниматься
                                                воспитанием учеников как в зале, так и за его пределами.<br/>
                                                7. Необходимо поощрять посещение других спортзалов, изучение опыта
                                                других школ. Ученики, которым запрещено посещать другие школы, могут
                                                стать непослушными. Целесообразность посещения других школ обусловлена
                                                тем, что ученик не только сможет подобрать школу, которая ему подходит
                                                наилучшим образом, но и многому научиться, сравнивая разные школы.<br/>
                                                8. Ко всем ученикам следует относиться одинаково. Любимчиков быть не
                                                должно. Ругать ученика можно только наедине с ним и никогда не делать
                                                этого на глазах других.<br/>
                                                9. Если инструктор не знает ответа на вопрос, ему не следует придумывать
                                                ответ. Надо признать свою неосведомленность и попытаться найти ответ как
                                                можно быстрее.<br/>
                                                10. Инструктор не должен искать у учеников такого рода помощи, как
                                                уборка кабинетов, ремонт и т.п.<br/>
                                                11. Инструктор не должен использовать своих учеников для личных целей.
                                                Единственная цель инструктора — воспитать физически и духовно
                                                совершенных учеников.<br/>
                                                12. Инструктор должен всегда быть честным с учениками, не терять их
                                                доверия.<br/>
                                                УЧЕНИКИ<br/>
                                                1. Никогда не уставать учиться. Хороший ученик может учиться везде и в
                                                любое время. В этом секрет успеха в учебе.<br/>
                                                2. Хороший ученик готов на жертвы ради своего боевого искусства и
                                                инструктора. Некоторые ученики рассматривают обучение как товар,
                                                купленный за ежемесячную плату, и не желают участвовать в выступлениях,
                                                обучении или работе в спортзале. Инструктор может позволить себе
                                                избавиться от таких учеников.<br/>
                                                3. Всегда быть хорошим примером для младших учеников. Вполне
                                                естественно, что они захотят соревноваться со старшими.<br/>
                                                4. Всегда быть преданными, никогда не критиковать инструктора,
                                                применяемые способы обучения и само таэквон-до.<br/>
                                                5. Изучать и стараться использовать показанный инструктором прием.<br/>
                                                6. Помнить, что поведение ученика за пределами спортзала отражается на
                                                боевом искусстве и на инструкторе.<br/>
                                                7. Если ученик освоил новые приемы из другой школы, а инструктор
                                                возражает против их применения, ученик должен отказаться от них, либо
                                                заниматься там, где он выучил эти приемы.<br/>
                                                8. Никогда не проявлять неуважение к инструктору. Хотя ученику
                                                позволительно не соглашаться с инструктором, он должен сначала выполнять
                                                задание, а обсуждать все вопросы позже.<br/>
                                                9. Всегда быть активным, не стесняясь задавать вопросы.<br/>
                                                10. Никогда не предавать инструктора.</p>
                                            <p><b></b></p>
                                            <p><b><i> Билет № 8 </i></b>Имя нынешнего Президента Международной Федерации
                                                Таэквон-до. «ЮЛЬ-ГОК» (расшифровать название, диаграмму и указать
                                                количество движений).</p>
                                            <p><b><i>Ответ: *</i></b>нынешний президент Международной Федерации
                                                Таэквон-до - мастер Ри Ёнг Сон, Почетный президент Международной
                                                Федерации Таэквон-до - корейский профессор Чан Унг.
                                            </p>
                                            <p>*Название туля «Юль Гок» является псевдонимом великого философа и учителя
                                                Ли И (1536-1584), прозванного «Корейским Конфуцием». Включение в туль 38
                                                движений символизирует место рождения этого человека – 38 параллель, а
                                                диаграмма движений – иероглиф, соответствующий слову «учитель».</p>
                                            <p>&nbsp;</p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p><b>&nbsp;</b></p>
                                            <p><b>&nbsp;</b></p>
                                            <p align="center"><a className="belt-with-margin" name="4 KUP"></a><b>4 ГЫП (<a style={{color: 'blue'}}>синий</a> пояс)</b>
                                            </p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Туль<i>:</i></span><i> «ЮЛЬ-ГОК»&nbsp; </i></b>
                                            </p>
                                            <p><b><i>&nbsp;&nbsp; &nbsp;&nbsp;«ДЖУН-ГУН» &nbsp;</i></b></p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Удары ногами:</span></b>
                                                <i>Долльо чаги, Ануро нэрьо чаги, Бакуро нэрьо чаги, Горо чаги, Йоп
                                                    чаги, Тора йоп чаги, Тора горо чаги, Тора бакуро нэрьо
                                                    чаги.</i>&nbsp; &nbsp;&nbsp;<i>Твимьо ап чаги</i> (наносится с
                                                задней ноги), <i>Твимьо йоп чаги, Твимьо долльо чаги, Твимьо горо
                                                    чаги</i> (эти 3 удара наносится с передней ноги), <i>Твимьо тора йоп
                                                    чаги, Твимьо тора горо чаги</i>.;&nbsp; &nbsp;&nbsp;<i>Ибо долльо
                                                    чаги</i> (удары наносятся с задней ноги на средний и верхний уровень
                                                туловища)</p>
                                            <p><i>Ибо йоп чаги</i> (удары наносятся с задней ноги на средний и верхний
                                                уровень туловища)</p>
                                            <p>Удары ногами выполняются попеременно левой-правой ногой из положения <i>Нюнча
                                                со пальмок дэби макки.</i> Количество — на усмотрение инструктора.</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Специальная техника: </span></b>;&nbsp;
                                                <b><i>Твимьо нопи чаги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см.(для спортсменов в
                                                возрасте &nbsp;до 12 лет) и толщиной 1.5-2см (для спортсменов в возрасте
                                                от 13 лет и старше). Доска располагается на голову выше роста
                                                аттестуемого</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Силовое разбивание:</span></b>&nbsp; ;&nbsp;
                                                <b><i>Йоп чаги; &nbsp;</i></b>Удар наносится по доске толщиной 1.5 см.
                                                (для спортсменов в возрасте&nbsp; до 12 лет) и толщиной от 2см и больше
                                                (для спортсменов в возрасте от 13 лет и старше).</p>
                                            <p align="center"><b><i>Сонкаль йоп тэриги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной от 1.5см и больше (для спортсменов
                                                в возрасте от 13 лет и старше).</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Спарринг:</span></b>&nbsp; Ибо
                                                матсоги (спарринг на 2 шага с партнером)</p>
                                            <p>Свободный спарринг (2 мин.)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>ОФП:</span></b> 30-отжиманий,50-приседаний,
                                                30-«складочка» (для спортсменов в возрасте до 12 лет)</p>
                                            <p>35-отжиманий,55-приседаний, 35-«складочка» (для спортсменов в возрасте от
                                                13 лет и старше)</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Теория:&nbsp;&nbsp; </span><i>Билет
                                                № 1</i></b>&nbsp;&nbsp; &nbsp;Духовные принципы Таэквон-до (краткое
                                                определение каждого принципа).</p>
                                            <p><b><i>Ответ: </i></b>Принципы таэквон-до:</p>
                                            <p>1) Учтивость (Ye Ui). Формируя свой характер, занимающиеся Таэквон-до
                                                должны практиковать следующие проявления учтивости, включая их как
                                                обязательные элементы в тренировочный процесс: развивать дух
                                                взаимоуважения; стыдиться своих вредных привычек; быть вежливым;
                                                поддерживать атмосферу справедливости и гуманизма; соблюдать иерархию
                                                между занимающимися и инструктором, начинающими
                                                и&nbsp; квалифицированными спортсменами, старшими и младшими; вести себя
                                                в соответствии с принятым этикетом; уважать положение других; вести себя
                                                с другими честно и открыто.<b></b></p>
                                            <p>2) Честность(Yom Chi). Каждый должен уметь отличать правильное от
                                                ложного, в случае собственной неправоты, испытывать угрызения
                                                совести.</p>
                                            <p>3) Настойчивость (In Nae).&nbsp; Древние говорили: «Настойчивость
                                                приводит к очищению и совершенству». «Тот, кто будет стократно
                                                настойчив, обеспечит мир своему дому». Счастье и удача чаще всего
                                                достаются&nbsp;&nbsp; настойчивым. Для того, чтобы чего-либо достичь,
                                                необходимо поставить перед собой цель и настойчиво ее добиваться. Один
                                                из секретов становления мастерства в</p>
                                            <p>Таэквон-до – настойчивое преодоление всех трудностей.</p>
                                            <p>4) Самообладание (Guk Gi). Самообладание чрезвычайно важно как в
                                                тренировочном зале, так и в повседневной жизни. Потеря самоконтроля во
                                                время спарринга может привести к очень серьёзным последствиям для обоих
                                                соперников. Неуживчивость внутри коллектива является проявлением плохого
                                                самоконтроля. Великий китайский мыслитель Лао Цзы говорил: <b><i>«Сильный
                                                    – тот, кто умеет побеждать себя, а не другого».</i></b></p>
                                            <p>5) Непоколебимость духа (Baekjul Boolgool). Занимающиеся Таэквон-до
                                                должны быть честными и искренними. Столкнувшись с несправедливостью, они
                                                должны действовать активно, ничего не боясь, проявлять непоколебимый
                                                дух, независимо от того, кто им противостоит и как много
                                                противников.</p>
                                            <p><b><i>Билет № 2 </i></b>&nbsp;Дата основания Таэквон-до.&nbsp; «ВОН-ХЁ»
                                                (расшифровать название и указать количество движений). Для чего
                                                необходим спарринг?</p>
                                            <p><b><i>Ответ: *</i></b>свое название Таэквон-до получило 11 апреля 1955
                                                года.</p>
                                            <p>*Туль «Вон Хё» назван в честь монаха, познакомившего в 686 г до н.э.
                                                корейскую династию Силла с буддизмом. Количество движений – 28.</p>
                                            <p>*Спарринг в Таєквон-до в основном делится на два вида: свободный, который
                                                похож на бокс, и спарринг с заранее оговоренным сценарием. Свободный
                                                спарринг позволяет развить бойцовский дух и храбрость, научиться
                                                «читать» маневры и тактику соперника, получить объективную оценку своего
                                                мастерства. А спарринг с заранее оговоренным сценарием дает возможность
                                                применять умения и навыки, приобретенные при изучении тулей и базовой
                                                техники по отношению к движущемуся сопернику. Спарринг имеет неразрывную
                                                связь с тулями и базовой техникой. С другой стороны, он полезен и для
                                                закалки атакующих и защитных сегментов тела.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp; Билет № 3&nbsp; </i></b>Имя и дата рождения основателя
                                                Таэквон-до.&nbsp; Назвать 4 основных блока и их краткое описание.</p>
                                            <p><b><i>Ответ: *</i></b>основатель Таэквон-до, генерал Чой Хонг Хи родился
                                                9 ноября 1918 года в&nbsp; местечке Хвадэ (территория нынешней КНДР).
                                            </p>
                                            <p>*«Наджундэ макги» – нижний блок;</p>
                                            <p>«Чукё макги» — верхний блок;</p>
                                            <p>«Анпальмок каунде макги»&nbsp; — средний блок (изнутри наружу);</p>
                                            <p>«Ануро бакатпальмок макги» — средний блок (снаружи внутрь).</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>&nbsp;</span></b></p>
                                            <p><b><i>Билет № 4</i></b> Чарёт соги,<b><i> </i></b>Нарани соги, Гоннун
                                                соги, Нюнча соги, Аннун соги- краткое описание стоек.</p>
                                            <p><b><i>Ответ: </i></b>«Чарёт соги» (моа соги) – стопы располагаются рядом.
                                                При этой стойке таэквондист обращен к сопернику или лицом или боком.</p>
                                            <p>«Нарани соги» — стопы располагаются параллельно на ширине плеч.
                                                Таэквондист обращен к сопернику или лицом или боком.</p>
                                            <p>«Гоннун соги» является наиболее эффективной стойкой для выполнения
                                                атакующих и защитных действий, направленных вперед или назад.</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад так, чтобы расстояние между
                                                    пальцами в
                                                </li>
                                            </ol>
                                            <p>продольном направлении составляло 1,5 ширины плеч, а расстояние между
                                                центрами стоп (в поперечном направлении) равнялось ширине плеч.</p>
                                            <ol>
                                                <li>Колено передней ноги сгибается таким образом, что воображаемая
                                                    вертикальная линия одновременно проходит через коленный сустав и
                                                    пятку. Задняя нога должна быть прямой.
                                                </li>
                                                <li>Вес тела распределен равномерно на обе ноги.</li>
                                                <li>Стопа передней ноги обращена вперед, стопа задней ноги повернута
                                                    наружу на 25°. Поворот задней ноги боле чем на 25° затрудняет
                                                    защитные действия при атаке сзади.
                                                </li>
                                                <li>Мышцы стоп должны быть напряжены (должно возникать чувство, что
                                                    стопы притягиваются друг к другу).
                                                </li>
                                                <li>При выполнении атакующих и защитных действий из этой стойки
                                                    таэквондист может быть обращен к сопернику лицом или в пол-оборота.
                                                </li>
                                            </ol>
                                            <p>«Нюнча соги» широко применяется при выполнении как защитных, так и
                                                атакующих действий. Для нанесения удара передней ногой требуется лишь
                                                незначительное перераспределить вес тела. Преимуществами данной стойки
                                                считаются возможность выполнения проскальзывания и расположения
                                                таэквондиста в пол-оборота к сопернику .</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад так, чтобы расстояние между
                                                    пальцами передней ноги и наружным краем задней ноги в продольном
                                                    направлении составляло 1,5 ширины плеч. Стопы при этом располагаются
                                                    почти под прямым углом. Рекомендуется повернуть обе стопы внутрь
                                                    примерно на 15°. Для повышения устойчивости расстояние между пятками
                                                    во фронтальной плоскости должно составлять примерно 2,5 см.
                                                </li>
                                                <li>Колено задней ноги сгибается таким образом, чтобы воображаемая
                                                    вертикальная линия одновременно проходила через коленный сустав и
                                                    пальцы стопы. Колено задней ноги сгибается пропорционально передней.
                                                </li>
                                                <li>Таз должен располагаться над внутренним краем коленного сустава
                                                    задней ноги.
                                                </li>
                                                <li>Примерно 70% веса тела должно приходится на заднюю и 30% на переднюю
                                                    ногу.
                                                </li>
                                            </ol>
                                            <p>«Аннун соги» очень удобная стойка для выполнения движений в сторону.
                                                Широко используется при совершенствовании ударов руками, тренировке мышц
                                                ног.</p>
                                            <ol>
                                                <li>Стопы располагаются параллельно на расстоянии 1,5 ширины плеч.</li>
                                                <li>Вес тела распределен равномерно на обе ноги.</li>
                                                <li>Коленные суставы находятся над подушечками стоп.</li>
                                                <li>Наружные края стоп упираются в опору (как бы отталкиваясь каждой
                                                    ногой внутрь).
                                                </li>
                                                <li>Грудь и живот поданы вперед. Мышцы брюшного пресса напряжены.</li>
                                                <li>При выполнении атакующих и защитных действий из этой стойки
                                                    таэквондист может быть обращен к сопернику лицом или боком.
                                                </li>
                                            </ol>
                                            <p>&nbsp;</p>
                                            <p><b><i> &nbsp;Билет № 5&nbsp; </i></b>Что означает слово Таэквон-до?
                                                Сколько тулей в Таэквон-до и почему именно такое количество? Годжун
                                                соги, Двитбаль соги- краткое описание стоек.</p>
                                            <p><b><i>Ответ: *</i></b>Корейское «таэ» озночает «выполнение удара ногой в
                                                прыжке или полете», «квон» — «кулак» (главным образом в контексте
                                                нанесения удара или разбивания чего-либо рукой), «до» — «искусство»,
                                                «путь» — путь истины, проторенный в прошлом, покрытый святостью и
                                                мудростью. Соединив все вместе, получаем, что слово «таэквон-до»
                                                обозначает систему духовной тренировки и технику самообороны без оружия
                                                наряду со здоровьем, а также квалифицированным исполнением ударов,
                                                блоков и прыжков, выполняющихся голыми руками и ногами для поражения
                                                одного или нескольких соперников.</p>
                                            <p>*В Таэквон-до существует 24 туля, что символизирует 24 часа в сутках,
                                                т.е. жизнь человека. Человеческая жизнь (грубо говоря 100 лет) в
                                                масштабе вечности может рассматриваться как один день. Таким образом,
                                                все люди – простые странники, проходящие перед лицом вечности за один
                                                день. Никто не может прожить дольше отрезка времени, отпущенного ему для
                                                жизни. Но, несмотря на это, многие люди так сильно стремятся к
                                                материальным благам, как будто они собираются прожить тысячелетия.
                                                Немногим удаётся оставить грядущим поколениям образцы высокого духа и
                                                таким образом обрести бессмертие. Дух вечен, материальные блага – нет.
                                                Самым главным в жизни есть то что передаётся грядущим поколениям.</p>
                                            <p>*«Годжун соги» эффективна для проведения направленных в сторону атакующих
                                                и защитных действий. Эта стойка во многом схожа с «нюнча соги». Различия
                                                заключаются в следующем:</p>
                                            <ol>
                                                <li>Вес равномерно распределен на обе ноги.</li>
                                                <li>Расстояние между большими пальцами ног в продольном направлении
                                                    равно 1,5 ширины плеч.
                                                </li>
                                            </ol>
                                            <p>«Двитбаль соги» обычно используется для защитных действий, редко – для
                                                атакующих. Преимуществом данной стойки является возможность нанесения
                                                удара или отталкивания соперника передней ногой без предварительного
                                                переноса веса теле на заднюю ногу.</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад таким образом, чтобы расстояние
                                                    между мизинцами равнялось ширине плеч.
                                                </li>
                                                <li>Задняя нога сгибается до тех пор, пока ее колено не оказывается над
                                                    пальцем стопы, при этом пятка задней ноги находится немного позади
                                                    передней ноги.
                                                </li>
                                                <li>Согнутая в колене передняя нога касается опоры подушечкой стопы.
                                                </li>
                                                <li>Колено передней ноги повернуто внутрь примерно на 25°, а стопа
                                                    задней ноги – на 15°.
                                                </li>
                                                <li>Почти весь вес тела переносится на заднюю ногу.</li>
                                            </ol>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp;&nbsp; &nbsp;&nbsp;Билет № 6&nbsp; </i></b>Что обозначает
                                                синий цвет пояса? «ЮЛЬ-ГОК»&nbsp; (расшифровать название и указать
                                                количество движений). Виды спарринга (на корейском языке)<b><i> </i></b>Для
                                                чего необходима специальная техника?</p>
                                            <p><b><i>Ответ:</i></b> *Синий символизирует небеса, к которым, подобно
                                                растущему дереву, в процессе тренировок устремляется мастерство
                                                таэквондиста.</p>
                                            <p><b><i>*</i></b>Название туля «Юль Гок» является псевдонимом великого
                                                философа и учителя Ли И (1536-1584), прозванного «Корейским Конфуцием».
                                                Включение в туль 38 движений символизирует место рождения этого человека
                                                – 38 параллель, а диаграмма движений – иероглиф, соответствующий слову
                                                «учитель».</p>
                                            <p><b><i>&nbsp;</i></b>*Главным образом различают следующие виды спарринга:
                                            </p>
                                            <p>«Яксок масоги» (обусловленный) делится на «самбо» (на 3 шага), «ибо» (на
                                                2 шага), «ильбо» (на 1 шаг).</p>
                                            <p>«Пан чжаю масоги» — полусвободный спарринг.</p>
                                            <p>«Чжаю масоги» — свободный спарринг.</p>
                                            <p>«Мобом масоги» — модельный спарринг.</p>
                                            <p>«Баль масоги» — спарринг с использованием только действий ногами.</p>
                                            <p>«Яксок чжаю масоги» — свободный спарринг с заранее оговоренным
                                                сценарием.</p>
                                            <p>*Специальная&nbsp; техника призвана оценить координацию движений,
                                                акробатическую подготовку и умение решать сложные двигательные
                                                задачи.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i> &nbsp;Билет № 7 </i></b>Взаимоотношения «ученик-инструктор»
                                                (предписания, которые им необходимо выполнять)</p>
                                            <p><b><i>Ответ: *</i></b>Ответственность&nbsp; за обучение<b> &nbsp;</b>искусству
                                                Таэквон-до лежит на инструкторах, основная задача которых состоит в том,
                                                чтобы сделать учеников физически и духовно здоровыми и тем самым внести
                                                свой вклад в создание мирного общества. Конечно же, каждой школе
                                                необходим преданный и искренний инструктор. Но ни одна школа не сможет
                                                расти и развиваться при отсутствии столь же преданных и искренних
                                                учеников.<b>&nbsp;&nbsp; </b></p>
                                            <p>Инструктору и ученику необходимо выполнять следующие предписания:<br/>
                                                ИНСТРУКТОР<br/>
                                                1. Никогда не уставать учить. Хороший инструктор может учить везде, в
                                                любое время. Он всегда должен быть готов отвечать на вопросы.<br/>
                                                2. Инструктор должен стремиться, чтобы ученик превзошел его. Такой
                                                результат служит величайшей похвалой инструктору. Ученика никогда нельзя
                                                удерживать позади. Если инструктор понял, что ученик превзошел его
                                                возможности, ученика надо передать инструктору более высокой
                                                квалификации.<br/>
                                                3. Инструктор всегда должен служить примером для учеников и никогда не
                                                пытаться обманывать их.<br/>
                                                4. Воспитание ученика должно быть выше материальной заинтересованности.
                                                Как только инструктор станет озабочен материальными аспектами обучения,
                                                он сразу же потеряет уважение учеников.<br/>
                                                5. Инструктор должен учить, основываясь на научной базе, чтобы экономить
                                                силы и время.<br/>
                                                6. Инструктор должен помогать ученикам устанавливать хорошие отношения
                                                за пределами спортивного зала. Обязанность инструктора — заниматься
                                                воспитанием учеников как в зале, так и за его пределами.<br/>
                                                7. Необходимо поощрять посещение других спортзалов, изучение опыта
                                                других школ. Ученики, которым запрещено посещать другие школы, могут
                                                стать непослушными. Целесообразность посещения других школ обусловлена
                                                тем, что ученик не только сможет подобрать школу, которая ему подходит
                                                наилучшим образом, но и многому научиться, сравнивая разные школы.<br/>
                                                8. Ко всем ученикам следует относиться одинаково. Любимчиков быть не
                                                должно. Ругать ученика можно только наедине с ним и никогда не делать
                                                этого на глазах других.<br/>
                                                9. Если инструктор не знает ответа на вопрос, ему не следует придумывать
                                                ответ. Надо признать свою неосведомленность и попытаться найти ответ как
                                                можно быстрее.<br/>
                                                10. Инструктор не должен искать у учеников такого рода помощи, как
                                                уборка кабинетов, ремонт и т.п.<br/>
                                                11. Инструктор не должен использовать своих учеников для личных целей.
                                                Единственная цель инструктора — воспитать физически и духовно
                                                совершенных учеников.<br/>
                                                12. Инструктор должен всегда быть честным с учениками, не терять их
                                                доверия.<br/>
                                                УЧЕНИКИ<br/>
                                                1. Никогда не уставать учиться. Хороший ученик может учиться везде и в
                                                любое время. В этом секрет успеха в учебе.<br/>
                                                2. Хороший ученик готов на жертвы ради своего боевого искусства и
                                                инструктора. Некоторые ученики рассматривают обучение как товар,
                                                купленный за ежемесячную плату, и не желают участвовать в выступлениях,
                                                обучении или работе в спортзале. Инструктор может позволить себе
                                                избавиться от таких учеников.<br/>
                                                3. Всегда быть хорошим примером для младших учеников. Вполне
                                                естественно, что они захотят соревноваться со старшими.<br/>
                                                4. Всегда быть преданными, никогда не критиковать инструктора,
                                                применяемые способы обучения и само таэквон-до.<br/>
                                                5. Изучать и стараться использовать показанный инструктором прием.<br/>
                                                6. Помнить, что поведение ученика за пределами спортзала отражается на
                                                боевом искусстве и на инструкторе.<br/>
                                                7. Если ученик освоил новые приемы из другой школы, а инструктор
                                                возражает против их применения, ученик должен отказаться от них, либо
                                                заниматься там, где он выучил эти приемы.<br/>
                                                8. Никогда не проявлять неуважение к инструктору. Хотя ученику
                                                позволительно не соглашаться с инструктором, он должен сначала выполнять
                                                задание, а обсуждать все вопросы позже.<br/>
                                                9. Всегда быть активным, не стесняясь задавать вопросы.<br/>
                                                10. Никогда не предавать инструктора.</p>
                                            <p><b>&nbsp;</b></p>
                                            <p><b><i>&nbsp;&nbsp; &nbsp;&nbsp;Билет № 8 </i></b>Имя нынешнего президента
                                                Международной Федерации Таэквон-до.&nbsp; «ДЖУН-ГУН» (расшифровать
                                                название, диаграмму и указать количество движений).</p>
                                            <p><b><i>Ответ: *</i></b>нынешний президент Международной Федерации
                                                Таэквон-до - мастер Ри Ёнг Сон, Почетный президент Международной
                                                Федерации Таэквон-до - корейский профессор Чан Унг.
                                            </p>
                                            <p>*Туль «Джун Гун» назван в честь известного патриота Ан Джун Гуна,
                                                убившего первого японского генерала, правителя Кореи Хиро Буми Ито,
                                                способствовавшего захвату Кореи Японией. Включение в туль 32 движений
                                                символизирует возраст Ан Джун Гуна во время его казни в тюрьме (1910
                                                г).</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p align="center"><a className="belt-with-margin" name="3 KUP"></a><b>3 </b><b>ГЫП</b><b> (<a
                                                style={{color: 'blue'}}>сине</a>-</b><b><a
                                                style={{color: 'red'}}>красный</a></b><b> пояс)</b></p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Туль<i>:</i></span><i> «ДЖУН-ГУН»&nbsp; </i></b>
                                            </p>
                                            <p><b><i>&nbsp; «ТЭ-ГЕ»&nbsp; </i></b></p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Удары ногами:</span></b> Ануро
                                                нэрьо чаги, Бакуро нэрьо чаги, Горо чаги, Йоп чаги, Тора йоп чаги, Тора
                                                горо чаги, Тора бакуро нэрьо чаги, Бандэ долльо чаги. &nbsp;Твимьо ап
                                                чаги (наносится с задней ноги), Твимьо йоп чаги, Твимьо долльо чаги,
                                                Твимьо горо чаги (эти 3 удара наносится с передней ноги), Твимио тура
                                                йоп чаги, Твимио тура горо чаги.;&nbsp; &nbsp;&nbsp;Ибо долльо чаги
                                                (удары наносятся с задней ноги на средний и верхний уровень туловища)
                                            </p>
                                            <p>Ибо йоп чаги (удары наносятся с задней ноги на средний и верхний уровень
                                                туловища)</p>
                                            <p>Горо чаги- Долльо чаги</p>
                                            <p>Долльо чаги- Йоп чаги</p>
                                            <p>Бакуро нэрьо чаги- Долльо чаги</p>
                                            <p>Долльо чаги- Бакуро нэрьо чаги</p>
                                            <p>Данные серии наносятся с задней ноги без касания пола между ударами.</p>
                                            <p>Удары ногами выполняются попеременно левой-правой ногой из положения
                                                Нюнча со пальмок дэби макки. Количество — на усмотрение инструктора.</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Специальная техника: </span></b>&nbsp;
                                                <b><i>Твимьо нопи чаги&nbsp; </i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см.(для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной 1.5-2см (для спортсменов в возрасте
                                                от 13 лет и старше). Доска располагается на голову выше роста
                                                аттестуемого.</p>
                                            <p><b><i>Твимьо долльо чаги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см.(для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной 1.5-2см (для спортсменов в возрасте
                                                от 13 лет и старше). Середина доски располагается на голову выше роста
                                                аттестуемого.</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Силовое разбивание:</span></b>&nbsp; &nbsp;
                                                <b><i>Йоп чаги </i></b>Удар наносится по доске толщиной 1.5 см. (для
                                                спортсменов в возрасте&nbsp; до 12 лет) и толщиной от 2см и больше (для
                                                спортсменов в возрасте от 13 лет и старше).</p>
                                            <p align="center"><b><i>Сонкаль йоп тэриги</i></b></p>
                                            <p align="center"><b><i>Ап джумок чируги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной от 1.5см и больше (для спортсменов
                                                в возрасте от 13 лет и старше).</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Спарринг:</span></b>&nbsp; Ибо
                                                матсоги (спарринг на 2 шага с партнером)</p>
                                            <p>Ильбо матсоги (спарринг на 1 шаг с партнером)</p>
                                            <p>Свободный спарринг (2 мин.)</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>ОФП:</span></b> 35-отжиманий,55-приседаний,
                                                35-«складочка» (для спортсменов в возрасте до 12 лет)</p>
                                            <p>40-отжиманий,60-приседаний, 40-«складочка» (для спортсменов в возрасте от
                                                13 лет и старше)</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Теория:&nbsp;&nbsp; </span><i>Билет
                                                № 1</i></b> Духовные принципы Таэквон-до (краткое определение каждого
                                                принципа).</p>
                                            <p><b><i>Ответ:</i></b> Принципы таэквон-до:</p>
                                            <p>1) Учтивость (Ye Ui). Формируя свой характер, занимающиеся Таэквон-до
                                                должны практиковать следующие проявления учтивости, включая их как
                                                обязательные элементы в тренировочный процесс: развивать дух
                                                взаимоуважения; стыдиться своих вредных привычек; быть вежливым;
                                                поддерживать атмосферу справедливости и гуманизма; соблюдать иерархию
                                                между занимающимися и инструктором, начинающими
                                                и&nbsp; квалифицированными спортсменами, старшими и младшими; вести себя
                                                в соответствии с принятым этикетом; уважать положение других; вести себя
                                                с другими честно и открыто.<b></b></p>
                                            <p>2) Честность(Yom Chi). Каждый должен уметь отличать правильное от
                                                ложного, в случае собственной неправоты, испытывать угрызения
                                                совести.</p>
                                            <p>3) Настойчивость (In Nae).&nbsp; Древние говорили: «Настойчивость
                                                приводит к очищению и совершенству». «Тот, кто будет стократно
                                                настойчив, обеспечит мир своему дому». Счастье и удача чаще всего
                                                достаются&nbsp;&nbsp; настойчивым. Для того, чтобы чего-либо достичь,
                                                необходимо поставить перед собой цель и настойчиво ее добиваться. Один
                                                из секретов становления мастерства в</p>
                                            <p>Таэквон-до – настойчивое преодоление всех трудностей.</p>
                                            <p>4) Самообладание (Guk Gi). Самообладание чрезвычайно важно как в
                                                тренировочном зале, так и в повседневной жизни. Потеря самоконтроля во
                                                время спарринга может привести к очень серьёзным последствиям для обоих
                                                соперников. Неуживчивость внутри коллектива является проявлением плохого
                                                самоконтроля. Великий китайский мыслитель Лао Цзы говорил: <b><i>«Сильный
                                                    – тот, кто умеет побеждать себя, а не другого».</i></b></p>
                                            <p>5) Непоколебимость духа (Baekjul Boolgool). Занимающиеся Таэквон-до
                                                должны быть честными и искренними. Столкнувшись с несправедливостью, они
                                                должны действовать активно, ничего не боясь, проявлять непоколебимый
                                                дух, независимо от того, кто им противостоит и как много
                                                противников.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 2 </i></b>&nbsp;Дата основания
                                                Таэквон-до.&nbsp; «ЮЛЬ-ГОК»&nbsp; (расшифровать название, диаграмму и
                                                указать количество движений). Для чего необходим спарринг?</p>
                                            <p><b><i>Ответ: *</i></b>свое название Таэквон-до получило 11 апреля 1955
                                                года.</p>
                                            <p><b><i>*</i></b>Название туля «Юль Гок» является псевдонимом великого
                                                философа и учителя Ли И (1536-1584), прозванного «Корейским Конфуцием».
                                                Включение в туль 38 движений символизирует место рождения этого человека
                                                – 38 параллель, а диаграмма движений – иероглиф, соответствующий слову
                                                «учитель».</p>
                                            <p>*Спарринг в Таєквон-до в основном делится на два вида: свободный, который
                                                похож на бокс, и спарринг с заранее оговоренным сценарием. Свободный
                                                спарринг позволяет развить бойцовский дух и храбрость, научиться
                                                «читать» маневры и тактику соперника, получить объективную оценку своего
                                                мастерства. А спарринг с заранее оговоренным сценарием дает возможность
                                                применять умения и навыки, приобретенные при изучении тулей и базовой
                                                техники по отношению к движущемуся сопернику. Спарринг имеет неразрывную
                                                связь с тулями и базовой техникой. С другой стороны, он полезен и для
                                                закалки атакующих и защитных сегментов тела.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp; Билет № 3&nbsp; </i></b>Имя и дата рождения основателя
                                                Таэквон-до. Ду пальмок нопундэ макки, Ду пальмок наджундэ миро макки- их
                                                описание и название тулей, в которых они применяются.</p>
                                            <p><b><i>Ответ: *</i></b>основатель Таэквон-до, генерал Чой Хонг Хи родился
                                                9 ноября 1918 года в&nbsp; местечке Хвадэ (территория нынешней КНДР).
                                            </p>
                                            <p>* «Ду пальмок макг и» — один из наиболее сильных блоков. Преимуществом
                                                этого блока является возможность быстрого перемещения не блокирующей
                                                руки для отбивания новой атаки без необходимости прекращения ставящегося
                                                блока. Обычно применяется из «гоннун соги» и «кеча соги», иногда – из
                                                «нюнча соги» и «двитбаль соги». Блок может ставится только «ан пальмок».
                                                При постановке блока необходимо обращать внимание на то, чтобы тело
                                                располагалось в пол-оборота к сопернику, а также чтобы&nbsp; — мизинец
                                                не блокирующей руки контактировал с внутренней стороной локтя
                                                блокирующей руки. «Ду пальмок макги» применяется в тулях «Юль Гок»,
                                                «Джун Гун», «Той Ге», «Чун Му», «Кван Ге».</p>
                                            <p><b><i>Билет № 4</i></b> Что развивает силовое разбивание? Джумок нулло
                                                макки, Сонбадак нулло макки — их описание и название тулей, в которых
                                                они применяются.</p>
                                            <p><b><i>Ответ: * </i></b>Силовое разбивание развивает силу, быстроту,
                                                точность ударов, контроль дыхания, концентрацию внимания.</p>
                                            <p><b><i>*</i></b>«Нулло макги» применяется для блокирования только низких
                                                ударов, когда защищающийся хочет остановить, а не отбить удар.
                                                Блокирование осуществляется с помощью «сонбадак» (ладонью) , «санг
                                                сонбадак» (двумя сложенными ладонями), «ап чумок» (кулаком) и «кеча
                                                чумок» (скрещенными предплечьями).</p>
                                            <p>«Сонбадак» выполняется в сочетании с «гоннун» и «натчо»&nbsp; соги.
                                                Всегда сопровождается с «оллио сонбадак макги». Блок имеет двойное
                                                назначение: во-первых, это прекрасное упражнение для закалки мышц и
                                                совершенствования дыхания, а во-вторых, с его помощью можно блокировать
                                                одновременно два удара.</p>
                                            <p>&nbsp;</p>
                                            <p>В момент блокирования локти слегка разводятся в стороны, а блокирующий
                                                должен быть обращен к сопернику грудью. Цель – только подъем стопы
                                                бьющей ноги.</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p><p>«Ап чумок» чаще всего применяется из «аннун» и «сасун» соги,
                                            иногда из «гоннун» и «натчо» соги. Обычно блокируется&nbsp; подъем стопы,
                                            реже – медиальная лодыжка. Блок в стойках «аннун» и «сасун» соги всегда
                                            сопровождается постановкой «ан пальмок макги».</p>
                                            <p>&nbsp;</p>
                                            <p>«Кёча чумок макги» может выполняться практически из любой стойки, чаще
                                                всего – из «гоннун», «аннун» и «кёча» соги. Назначение этого блока –
                                                отбивание ударов ногой, направленных в область нижней части живота. Цель
                                                блокирования – голень соперника.</p>
                                            <p><b><i></i></b></p>
                                            <p><b><i></i></b></p>
                                            <p><b><i>Билет № 5&nbsp; </i></b>Что означает слово Таэквон-до? Сколько
                                                тулей в Таэквон-до и почему именно такое количество? Годжун соги,
                                                Двитбаль соги, Кёча соги- краткое описание стоек.</p>
                                            <p><b><i>*</i></b>Корейское «таэ» озночает «выполнение удара ногой в прыжке
                                                или полете», «квон» — «кулак» (главным образом в контексте нанесения
                                                удара или разбивания чего-либо рукой), «до» — «искусство», «путь» — путь
                                                истины, проторенный в прошлом, покрытый святостью и мудростью. Соединив
                                                все вместе, получаем, что слово «таэквон-до» обозначает систему духовной
                                                тренировки и технику самообороны без оружия наряду со здоровьем, а также
                                                квалифицированным исполнением ударов, блоков и прыжков, выполняющихся
                                                голыми руками и ногами для поражения одного или нескольких соперников.
                                            </p>
                                            <p>*В Таэквон-до существует 24 туля, что символизирует 24 часа в сутках,
                                                т.е. жизнь человека. Человеческая жизнь (грубо говоря 100 лет) в
                                                масштабе вечности может рассматриваться как один день. Таким образом,
                                                все люди – простые странники, проходящие перед лицом вечности за один
                                                день. Никто не может прожить дольше отрезка времени, отпущенного ему для
                                                жизни. Но, несмотря на это, многие люди так сильно стремятся к
                                                материальным благам, как будто они собираются прожить тысячелетия.
                                                Немногим удаётся оставить грядущим поколениям образцы высокого духа и
                                                таким образом обрести бессмертие. Дух вечен, материальные блага – нет.
                                                Самым главным в жизни есть то что передаётся грядущим поколениям.</p>
                                            <p>*«Годжун соги» эффективна для проведения направленных в сторону атакующих
                                                и защитных действий. Эта стойка во многом схожа с «нюнча соги». Различия
                                                заключаются в следующем:</p>
                                            <ol>
                                                <li>Вес равномерно распределен на обе ноги.</li>
                                                <li>Расстояние между большими пальцами ног в продольном направлении
                                                    равно 1,5 ширины плеч.
                                                </li>
                                            </ol>
                                            <p>«Двитбаль соги» обычно используется для защитных действий, редко – для
                                                атакующих. Преимуществом данной стойки является возможность нанесения
                                                удара или отталкивания соперника передней ногой без предварительного
                                                переноса веса теле на заднюю ногу.</p>
                                            <ol>
                                                <li>Нога перемещается вперед или назад таким образом, чтобы расстояние
                                                    между мизинцами равнялось ширине плеч.
                                                </li>
                                                <li>Задняя нога сгибается до тех пор, пока ее колено не оказывается над
                                                    пальцем стопы, при этом пятка задней ноги находится немного позади
                                                    передней ноги.
                                                </li>
                                                <li>Согнутая в колене передняя нога касается опоры подушечкой стопы.
                                                </li>
                                                <li>Колено передней ноги повернуто внутрь примерно на 25°, а стопа
                                                    задней ноги – на 15°.
                                                </li>
                                                <li>Почти весь вес тела переносится на заднюю ногу.</li>
                                            </ol>
                                            <p>«Кёча соги» очень удобная стойка для атаки в прыжке соперника,
                                                располагающегося спереди или сбоку. Часто используется при блокировании
                                                и как подготовительная стойка для выполнения каких-либо маневров.</p>
                                            <ol start="1">
                                                <li>Одна нога заносится за другую. Передняя нога касается опоры только
                                                    подушечкой стопы.
                                                </li>
                                                <li>Вес тела переносится на опорную ногу.</li>
                                                <li>За исключением прыжков занос всегда осуществляется спереди от
                                                    опорной ноги. При выполнении атакующих и защитных действий из этой
                                                    стойки таэквондист может быть обращен к сопернику лицом, в
                                                    пол-оборота или боком.
                                                </li>
                                            </ol>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp; Билет № 6&nbsp; </i></b>Что обозначает синий цвет пояса?
                                                «ТЭ-ГЕ» (расшифровать название, диаграмму и указать количество
                                                движений). Виды спарринга (на корейском языке)<b><i> </i></b>Для чего
                                                необходима специальная техника?</p>
                                            <p><b><i>Ответ:</i></b> *Синий символизирует небеса, к которым, подобно
                                                растущему дереву, в процессе тренировок устремляется мастерство
                                                таэквондиста.</p>
                                            <p><b><i>*</i></b>Название туля «Тэ Гэ» является псевдонимом жившего в XVI
                                                веке учителя И Вонга, известного авторитета неоконфуцианизма. Включение
                                                в туль 37 движений символизирует место рождения этого человека – 37
                                                параллель, а диаграмма&nbsp; движений – иероглиф, соответствующий слову
                                                «учитель».</p>
                                            <p><b><i>&nbsp;</i></b>*Главным образом различают следующие виды спарринга:
                                            </p>
                                            <p>«Яксок масоги» (обусловленный) делится на «самбо» (на 3 шага), «ибо» (на
                                                2 шага), «ильбо» (на 1 шаг).</p>
                                            <p>«Пан чжаю масоги» — полусвободный спарринг.</p>
                                            <p>«Чжаю масоги» — свободный спарринг.</p>
                                            <p>«Мобом масоги» — модельный спарринг.</p>
                                            <p>«Баль масоги» — спарринг с использованием только действий ногами.</p>
                                            <p>«Яксок чжаю масоги» — свободный спарринг с заранее оговоренным
                                                сценарием.</p>
                                            <p>*Специальная&nbsp; техника призвана оценить координацию движений,
                                                акробатическую подготовку и умение решать сложные двигательные
                                                задачи.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i> &nbsp;Билет № 7 </i></b>Взаимоотношения «ученик-инструктор»
                                                (предписания, которые им необходимо выполнять).<b> </b>Что символизирует
                                                надпись на спине добока?</p>
                                            <p><b><i>Ответ: *</i></b>Ответственность&nbsp; за обучение<b> &nbsp;</b>искусству
                                                Таэквон-до лежит на инструкторах, основная задача которых состоит в том,
                                                чтобы сделать учеников физически и духовно здоровыми и тем самым внести
                                                свой вклад в создание мирного общества. Конечно же, каждой школе
                                                необходим преданный и искренний инструктор. Но ни одна школа не сможет
                                                расти и развиваться при отсутствии столь же преданных и искренних
                                                учеников.<b>&nbsp;&nbsp; </b></p>
                                            <p>Инструктору и ученику необходимо выполнять следующие предписания:<br/>
                                                ИНСТРУКТОР<br/>
                                                1. Никогда не уставать учить. Хороший инструктор может учить везде, в
                                                любое время. Он всегда должен быть готов отвечать на вопросы.<br/>
                                                2. Инструктор должен стремиться, чтобы ученик превзошел его. Такой
                                                результат служит величайшей похвалой инструктору. Ученика никогда нельзя
                                                удерживать позади. Если инструктор понял, что ученик превзошел его
                                                возможности, ученика надо передать инструктору более высокой
                                                квалификации.<br/>
                                                3. Инструктор всегда должен служить примером для учеников и никогда не
                                                пытаться обманывать их.<br/>
                                                4. Воспитание ученика должно быть выше материальной заинтересованности.
                                                Как только инструктор станет озабочен материальными аспектами обучения,
                                                он сразу же потеряет уважение учеников.<br/>
                                                5. Инструктор должен учить, основываясь на научной базе, чтобы экономить
                                                силы и время.<br/>
                                                6. Инструктор должен помогать ученикам устанавливать хорошие отношения
                                                за пределами спортивного зала. Обязанность инструктора — заниматься
                                                воспитанием учеников как в зале, так и за его пределами.<br/>
                                                7. Необходимо поощрять посещение других спортзалов, изучение опыта
                                                других школ. Ученики, которым запрещено посещать другие школы, могут
                                                стать непослушными. Целесообразность посещения других школ обусловлена
                                                тем, что ученик не только сможет подобрать школу, которая ему подходит
                                                наилучшим образом, но и многому научиться, сравнивая разные школы.<br/>
                                                8. Ко всем ученикам следует относиться одинаково. Любимчиков быть не
                                                должно. Ругать ученика можно только наедине с ним и никогда не делать
                                                этого на глазах других.<br/>
                                                9. Если инструктор не знает ответа на вопрос, ему не следует придумывать
                                                ответ. Надо признать свою неосведомленность и попытаться найти ответ как
                                                можно быстрее.<br/>
                                                10. Инструктор не должен искать у учеников такого рода помощи, как
                                                уборка кабинетов, ремонт и т.п.<br/>
                                                11. Инструктор не должен использовать своих учеников для личных целей.
                                                Единственная цель инструктора — воспитать физически и духовно
                                                совершенных учеников.<br/>
                                                12. Инструктор должен всегда быть честным с учениками, не терять их
                                                доверия.<br/>
                                                УЧЕНИКИ<br/>
                                                1. Никогда не уставать учиться. Хороший ученик может учиться везде и в
                                                любое время. В этом секрет успеха в учебе.<br/>
                                                2. Хороший ученик готов на жертвы ради своего боевого искусства и
                                                инструктора. Некоторые ученики рассматривают обучение как товар,
                                                купленный за ежемесячную плату, и не желают участвовать в выступлениях,
                                                обучении или работе в спортзале. Инструктор может позволить себе
                                                избавиться от таких учеников.<br/>
                                                3. Всегда быть хорошим примером для младших учеников. Вполне
                                                естественно, что они захотят соревноваться со старшими.<br/>
                                                4. Всегда быть преданными, никогда не критиковать инструктора,
                                                применяемые способы обучения и само таэквон-до.<br/>
                                                5. Изучать и стараться использовать показанный инструктором прием.<br/>
                                                6. Помнить, что поведение ученика за пределами спортзала отражается на
                                                боевом искусстве и на инструкторе.<br/>
                                                7. Если ученик освоил новые приемы из другой школы, а инструктор
                                                возражает против их применения, ученик должен отказаться от них, либо
                                                заниматься там, где он выучил эти приемы.<br/>
                                                8. Никогда не проявлять неуважение к инструктору. Хотя ученику
                                                позволительно не соглашаться с инструктором, он должен сначала выполнять
                                                задание, а обсуждать все вопросы позже.<br/>
                                                9. Всегда быть активным, не стесняясь задавать вопросы.<br/>
                                                10. Никогда не предавать инструктора.</p>
                                            <p>*Надпись делается в виде вечнозеленого дерева – сосны.</p>
                                            <p><b>&nbsp;</b></p>
                                            <p><b><i>&nbsp; Билет № 8 </i></b>Имя нынешнего президента Интернациональной
                                                Федерации Таэквон-до.; «ДЖУН-ГУН» (расшифровать название, диаграмму и
                                                указать количество движений).</p>
                                            <p><b><i>Ответ: *</i></b>нынешний президент Международной Федерации
                                                Таэквон-до - мастер Ри Ёнг Сон, Почетный президент Международной
                                                Федерации Таэквон-до - корейский профессор Чан Унг.
                                            </p>
                                            <p>*Туль «Джун Гун» назван в честь известного патриота Ан Джун Гуна,
                                                убившего первого японского генерала, правителя Кореи Хиро Буми Ито,
                                                способствовавшего захвату Кореи Японией. Включение в туль 32 движений
                                                символизирует возраст Ан Джун Гуна во время его казни в тюрьме (1910
                                                г).</p>
                                            <p>&nbsp;</p>
                                            <p align="center"><a className="belt-with-margin" name="2 KUP"></a><b>2 </b><b>ГЫП (<a
                                                style={{color: 'red'}}>красный</a> пояс)</b></p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Туль:</span> <i>«ТЭ —
                                                ГЕ»&nbsp; </i></b></p>
                                            <p><b><i>&nbsp; «ХВА-РАН»&nbsp; </i></b></p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Удары ногами:</span></b>
                                                <i>Йоп чаги, Тора йоп чаги, Тора горо чаги, Тора бакуро нэрьо чаги,
                                                    Бандэ долльо</i> чаги.&nbsp;&nbsp; &nbsp;&nbsp;<i>Твимьо ап
                                                    чаги</i> (наносится с задней ноги), <i>Твимьо йоп чаги, Твимьо
                                                    долльо чаги, Твимьо горо чаги</i> (эти 3 удара наносится с передней
                                                ноги), <i>Твимьо тора йоп чаги, Твимьо тора горо чаги, Твимьо</i> <i>бандэ
                                                    долльо чаги.&nbsp; Ибо долльо чаги</i> (удары наносятся с задней
                                                ноги на средний и верхний уровень туловища)</p>
                                            <p><i>Ибо йоп чаги </i>(удары наносятся с задней ноги на средний и верхний
                                                уровень туловища)</p>
                                            <p><i>Горо чаги- Долльо чаги</i></p>
                                            <p><i>Долльо чаги- Йоп чаги</i></p>
                                            <p><i>Бакуро нэрьо чаги- Долльо чаги</i></p>
                                            <p><i>Долльо чаги- Бакуро нэрьо чаги</i></p>
                                            <p><i>Горо чаги- Долльо чаги- Йоп чаги</i></p>
                                            <p><i>Тора горо чаги- Долльо чаги</i></p>
                                            <p>Данные серии наносятся с задней ноги без касания пола между ударами.</p>
                                            <p><i>Долльо чаги, Бакуро нэрьо чаги, Горо чаги, Йоп чаги-</i>удары
                                                наносятся с передней ноги со скольжением</p>
                                            <p>Удары ногами выполняются попеременно левой-правой ногой из положения
                                                Нюнча со пальмок дэби макки. Количество — на усмотрение инструктора.</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Самооборона</span></b></p>
                                            <p>Освобождение от захвата, одной рукой, спереди</p>
                                            <p>Освобождение от захвата, двумя руками, спереди</p>
                                            <p>Освобождение от захвата одноименной рукой сзади за плечо</p>
                                            <p>Освобождение от захвата разноименной рукой сзади за плечо</p>
                                            <p>Освобождение от захвата «в ключ» за шею сбоку</p>
                                            <p>Освобождение от захвата «в ключ» за шею, под руками, сзади</p>
                                            <p>Освобождение от захвата спереди за волосы</p>
                                            <p>&nbsp;</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Специальная техника: </span></b>&nbsp;
                                                <b><i>Твимьо нопи чаги&nbsp; </i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см.(для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной 1.5-2см (для спортсменов в возрасте
                                                от 13 лет и старше). Доска располагается на голову выше роста
                                                аттестуемого.</p>
                                            <p align="center"><b><i>Твимио долльо чаги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см.(для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной 1.5-2см (для спортсменов в возрасте
                                                от 13 лет и старше). Середина доски располагается на голову выше роста
                                                аттестуемого.</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Силовое разбивание:</span></b>&nbsp; &nbsp;&nbsp;
                                                <b><i>Йоп чаги; &nbsp;</i></b>Удар наносится по доске толщиной 1.5 см.
                                                (для спортсменов в возрасте&nbsp; до 12 лет) и толщиной от 2см и больше
                                                (для спортсменов в возрасте от 13 лет и старше).</p>
                                            <p align="center"><b><i>Долльо чаги</i></b></p>
                                            <p>Удар наносится по доске толщиной от 1 до 1.5 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной от 2см и больше (для спортсменов в
                                                возрасте от 13 лет и старше).</p>
                                            <p><b><i> &nbsp;Ап джумок чируги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной от 1.5см и больше (для спортсменов
                                                в возрасте от 13 лет и старше).</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Спарринг:</span></b>&nbsp; Ильбо
                                                матсоги (спарринг на 1 шаг с партнером)</p>
                                            <p>Свободный спарринг -2 мин, по минуте с разными партнерами.</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>ОФП:</span></b> 40-отжиманий,60-приседаний,
                                                40-«складочка» (для спортсменов в возрасте до 12 лет)</p>
                                            <p>45-отжиманий,65-приседаний, 45-«складочка» (для спортсменов в возрасте от
                                                13 лет и старше)</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Теория:&nbsp;&nbsp; </span><i>Билет
                                                № 1</i></b> Духовные принципы Таэквон-до (краткое определение каждого
                                                принципа).</p>
                                            <p><b><i>Ответ:</i></b> Принципы таэквон-до:</p>
                                            <p>1) Учтивость (Ye Ui). Формируя свой характер, занимающиеся Таэквон-до
                                                должны практиковать следующие проявления учтивости, включая их как
                                                обязательные элементы в тренировочный процесс: развивать дух
                                                взаимоуважения; стыдиться своих вредных привычек; быть вежливым;
                                                поддерживать атмосферу справедливости и гуманизма; соблюдать иерархию
                                                между занимающимися и инструктором, начинающими
                                                и&nbsp; квалифицированными спортсменами, старшими и младшими; вести себя
                                                в соответствии с принятым этикетом; уважать положение других; вести себя
                                                с другими честно и открыто.<b></b></p>
                                            <p>2) Честность(Yom Chi). Каждый должен уметь отличать правильное от
                                                ложного, в случае собственной неправоты, испытывать угрызения
                                                совести.</p>
                                            <p>3) Настойчивость (In Nae).&nbsp; Древние говорили: «Настойчивость
                                                приводит к очищению и совершенству». «Тот, кто будет стократно
                                                настойчив, обеспечит мир своему дому». Счастье и удача чаще всего
                                                достаются&nbsp;&nbsp; настойчивым. Для того, чтобы чего-либо достичь,
                                                необходимо поставить перед собой цель и настойчиво ее добиваться. Один
                                                из секретов становления мастерства в</p>
                                            <p>Таэквон-до – настойчивое преодоление всех трудностей.</p>
                                            <p>4) Самообладание (Guk Gi). Самообладание чрезвычайно важно как в
                                                тренировочном зале, так и в повседневной жизни. Потеря самоконтроля во
                                                время спарринга может привести к очень серьёзным последствиям для обоих
                                                соперников. Неуживчивость внутри коллектива является проявлением плохого
                                                самоконтроля. Великий китайский мыслитель Лао Цзы говорил: <b><i>«Сильный
                                                    – тот, кто умеет побеждать себя, а не другого».</i></b></p>
                                            <p>5) Непоколебимость духа (Baekjul Boolgool). Занимающиеся Таэквон-до
                                                должны быть честными и искренними. Столкнувшись с несправедливостью, они
                                                должны действовать активно, ничего не боясь, проявлять непоколебимый
                                                дух, независимо от того, кто им противостоит и как много
                                                противников.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>Билет № 2 </i></b>&nbsp;Философия
                                                Таэквон-до&nbsp; «ДЖУН-ГУН»&nbsp; (расшифровать название, диаграмму и
                                                указать количество движений). Что развивает спарринг?</p>
                                            <p><b><i>Ответ: </i></b>*Философия таэквон-до основана на этических,
                                                моральных и духовных принципах, на основе которых люди могут жить вместе
                                                в гармонии. Генерал Чой Хонг Хи установил следующие философские
                                                принципы, которые будут краеугольными камнями таэквон-до, по которым все
                                                прилежные ученики этого боевого искусства должны жить:</p>
                                            <ol>
                                                <li>Быть полным решимости идти, хотя путь будет трудным, и делать то,
                                                    что нужно делать, хотя это будет нелегко.
                                                </li>
                                                <li>Быть внимательным к слабым и суровым к сильным.</li>
                                                <li>Быть удовлетворенным тем положением и деньгами, которые имеешь, но
                                                    никогда не быть удовлетворенным своим мастерством.
                                                </li>
                                                <li>Всегда завершать начатое дело, будь оно большим или малым.</li>
                                                <li>Быть всегда готовым учить любого, независимо от религии, расы и
                                                    идеологии.
                                                </li>
                                                <li>Никогда не поддаваться давлению или угрозе при осуществлении
                                                    благородной цели.
                                                </li>
                                                <li>Учить мировоззрению и навыкам делами, а не словами.</li>
                                                <li>Всегда оставаться собой, даже когда обстоятельства жизни меняются.
                                                </li>
                                                <li>Всегда быть учителем, который учит телом, когда молод, словами –
                                                    когда стар, духовными заповедями – после смерти.
                                                </li>
                                            </ol>
                                            <p>*Туль «Джун Гун» назван в честь известного патриота Ан Джун Гуна,
                                                убившего первого японского генерала, правителя Кореи Хиро Буми Ито,
                                                способствовавшего захвату Кореи Японией. Включение в туль 32 движений
                                                символизирует возраст Ан Джун Гуна во время его казни в тюрьме (1910
                                                г).</p>
                                            <p>*Спарринг в Таєквон-до в основном делится на два вида: свободный, который
                                                похож на бокс, и спарринг с заранее оговоренным сценарием. Свободный
                                                спарринг позволяет развить бойцовский дух и храбрость, научиться
                                                «читать» маневры и тактику соперника, получить объективную оценку своего
                                                мастерства. А спарринг с заранее оговоренным сценарием дает возможность
                                                применять умения и навыки, приобретенные при изучении тулей и базовой
                                                техники по отношению к движущемуся сопернику. Спарринг имеет неразрывную
                                                связь с тулями и базовой техникой. С другой стороны, он полезен и для
                                                закалки атакующих и защитных сегментов тела.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp; Билет № 3&nbsp; </i></b>Что развивает самооборона? Ду
                                                пальмок нопундэ макки, Ду пальмок наджундэ миро макки- их описание и
                                                название тулей, в которых они применяются.</p>
                                            <p><b><i>Ответ:</i></b>*<b><i> </i></b>самооборона развивает возможность
                                                реального применения действий, (разученных при выполнении базовых
                                                упражнений, тулей, спарринга)&nbsp; при отражении атаки вооруженного или
                                                невооруженного противника</p>
                                            <p>* «Ду пальмок макги» — один из наиболее сильных блоков. Преимуществом
                                                этого блока является возможность быстрого перемещения не блокирующей
                                                руки для отбивания новой атаки без необходимости прекращения ставящегося
                                                блока.</p>
                                            <p>Обычно применяется из «гоннун соги» и «кеча соги», иногда – из «нюнча
                                                соги» и «двитбаль соги». Блок может ставится только «ан пальмок». При
                                                постановке блока необходимо обращать внимание на то, чтобы тело
                                                располагалось в пол-оборота к сопернику, а также чтобы&nbsp; — мизинец
                                                не блокирующей руки контактировал с внутренней стороной локтя
                                                блокирующей руки. «Ду пальмок макги» применяется в тулях «Юль Гок»,
                                                «Джун Гун», «Тэ Гэ», «Чун Му», «Кван Ге».</p>
                                            <p><b><i>&nbsp;</i></b></p>
                                            <p><b><i>Билет № 4</i></b> Что развивает силовое разбивание? Система
                                                начисления очков в спарринге</p>
                                            <p><b><i>Ответ: </i></b>* Силовое разбивание развивает силу, быстроту,
                                                точность ударов, контроль дыхания, концентрацию внимания.</p>
                                            <p>*Засчитаны будут все атакующие действия, остановленные за 2см до
                                                цели.</p>
                                            <p><b>Одно (1) очко</b> присуждается за:<br/>
                                                -атака рукой направленная в средний или верхний уровень<br/>
                                                -атака ногой направленная в средний уровень<br/>
                                                -атака рукой в прыжке направленная в средний уровень<br/>
                                                -отлично выполненная защита<br/>
                                                <b>Два (2) очка</b> присуждаются за:<br/>
                                                -атака ногой направленная в верхний уровень<br/>
                                                -атака рукой в прыжке (обе ноги не должны касаться площадки)
                                                направленная в верхний уровень<br/>
                                                -атака ногой в прыжке направленная в средний уровень<br/>
                                                <b>Три (3) очка</b> присуждаются за:<br/>
                                                -атака ногой в прыжке или в полёте направленная в верхний уровень<br/>
                                                -атака ногой в прыжке или в полёте с разворотом на
                                                180<sup> o</sup> направленная в средний уровень<br/>
                                                <b>Четыре (4) очка</b> присуждаются за:<br/>
                                                -атака ногой в прыжке с разворотом на 180<sup> o</sup> направленная в
                                                верхний уровень<br/>
                                                -атака ногой в прыжке с разворотом на 360<sup> o</sup> и более,
                                                направленная в средний уровень<br/>
                                                <b>Пять (5) очков</b> присуждаются за:<br/>
                                                -атака ногой в прыжке с разворотом на 360<sup> o</sup> и более,
                                                направленная в верхний уровень</p>
                                            <p>Если при ударе в полете засчитывается 2 или 3 удара рукой и ногой, очки
                                                начисляются за каждый удар.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp; Билет № 5&nbsp; </i></b>Дифференциация званий в Таэквон-до
                                                (как, по-корейски, называются мастера с 1 по 9 дан). Идеальный
                                                инструктор.</p>
                                            <p><b><i>Ответ: </i></b>*1-3 дан – Бусабом (помощник инструктора);</p>
                                            <p>4-6 дан – Сабом (инструктор);</p>
                                            <p>7-8 дан – Сахён (мастер);</p>
                                            <p>9 дан – Сасунг (гранд мастер)</p>
                                            <p>*Идеальный инструктор должен обладать следующими чертами:</p>
                                            <p>1. Высокий моральный и этический уровень.</p>
                                            <p>2. Ясная философия и взгляд на жизнь.</p>
                                            <p>3. Ответственное отношение к своим обязанностям.</p>
                                            <p>4. Научный подход к вопросам техники.</p>
                                            <p>5. Знание локализации уязвимых точек на теле человека.</p>
                                            <p>6. Непоколебимая чистота в политических и финансовых делах.</p>
                                            <p>7. Желание посвятить себя распространению Таэквон-до по всему миру.</p>
                                            <p>8. С ним должны быть искренни старшие, ему должны доверять коллеги, его
                                                должны уважать младшие.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp; Билет № 6&nbsp; </i></b>Что обозначает красный цвет пояса?
                                                «ТЭ-ГЕ» (расшифровать название, диаграмму и указать количество
                                                движений). Виды спарринга (на корейском языке).<b><i> </i></b>Для чего
                                                необходима специальная техника?</p>
                                            <p><b><i>Ответ: *</i></b>Красный символизирует опасность, предупреждая
                                                занимающихся о необходимости четкого самоконтроля, соперника – о
                                                необходимости держаться подальше от спортсмена.</p>
                                            <p><b><i>*</i></b>Название туля «Тэ Гэ» является псевдонимом жившего в XVI
                                                веке учителя И Вонга, известного авторитета неоконфуцианизма. Включение
                                                в туль 37 движений символизирует место рождения этого человека – 37
                                                параллель, а диаграмма&nbsp; движений – иероглиф, соответствующий слову
                                                «учитель».</p>
                                            <p>*Главным образом различают следующие виды спарринга:</p>
                                            <p>«Яксок масоги» (обусловленный) делится на «самбо» (на 3 шага), «ибо» (на
                                                2 шага), «ильбо» (на 1 шаг).</p>
                                            <p>«Пан чжаю масоги» — полусвободный спарринг.</p>
                                            <p>«Чжаю масоги» — свободный спарринг.</p>
                                            <p>«Мобом масоги» — модельный спарринг.</p>
                                            <p>«Баль масоги» — спарринг с использованием только действий ногами.</p>
                                            <p>«Яксок чжаю масоги» — свободный спарринг с заранее оговоренным
                                                сценарием.</p>
                                            <p>*Специальная&nbsp; техника призвана оценить координацию движений,
                                                акробатическую подготовку и умение решать сложные двигательные
                                                задачи.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp; Билет № 7 </i></b>Взаимоотношения «ученик-инструктор»
                                                (предписания, которые им необходимо выполнять).<b> </b>Какие виды
                                                программ существуют в Таэквон-до</p>
                                            <p><b><i>Ответ: *</i></b>Ответственность&nbsp; за обучение<b> &nbsp;</b>искусству
                                                Таэквон-до лежит на инструкторах, основная задача которых состоит в том,
                                                чтобы сделать учеников физически и духовно здоровыми и тем самым внести
                                                свой вклад в создание мирного общества. Конечно же, каждой школе
                                                необходим преданный и искренний инструктор. Но ни одна школа не сможет
                                                расти и развиваться при отсутствии столь же преданных и искренних
                                                учеников.<b>&nbsp;&nbsp; </b></p>
                                            <p>Инструктору и ученику необходимо выполнять следующие предписания:<br/>
                                                ИНСТРУКТОР<br/>
                                                1. Никогда не уставать учить. Хороший инструктор может учить везде, в
                                                любое время. Он всегда должен быть готов отвечать на вопросы.<br/>
                                                2. Инструктор должен стремиться, чтобы ученик превзошел его. Такой
                                                результат служит величайшей похвалой инструктору. Ученика никогда нельзя
                                                удерживать позади. Если инструктор понял, что ученик превзошел его
                                                возможности, ученика надо передать инструктору более высокой
                                                квалификации.<br/>
                                                3. Инструктор всегда должен служить примером для учеников и никогда не
                                                пытаться обманывать их.<br/>
                                                4. Воспитание ученика должно быть выше материальной заинтересованности.
                                                Как только инструктор станет озабочен материальными аспектами обучения,
                                                он сразу же потеряет уважение учеников.<br/>
                                                5. Инструктор должен учить, основываясь на научной базе, чтобы экономить
                                                силы и время.<br/>
                                                6. Инструктор должен помогать ученикам устанавливать хорошие отношения
                                                за пределами спортивного зала. Обязанность инструктора — заниматься
                                                воспитанием учеников как в зале, так и за его пределами.<br/>
                                                7. Необходимо поощрять посещение других спортзалов, изучение опыта
                                                других школ. Ученики, которым запрещено посещать другие школы, могут
                                                стать непослушными. Целесообразность посещения других школ обусловлена
                                                тем, что ученик не только сможет подобрать школу, которая ему подходит
                                                наилучшим образом, но и многому научиться, сравнивая разные школы.<br/>
                                                8. Ко всем ученикам следует относиться одинаково. Любимчиков быть не
                                                должно. Ругать ученика можно только наедине с ним и никогда не делать
                                                этого на глазах других.<br/>
                                                9. Если инструктор не знает ответа на вопрос, ему не следует придумывать
                                                ответ. Надо признать свою неосведомленность и попытаться найти ответ как
                                                можно быстрее.<br/>
                                                10. Инструктор не должен искать у учеников такого рода помощи, как
                                                уборка кабинетов, ремонт и т.п.<br/>
                                                11. Инструктор не должен использовать своих учеников для личных целей.
                                                Единственная цель инструктора — воспитать физически и духовно
                                                совершенных учеников.<br/>
                                                12. Инструктор должен всегда быть честным с учениками, не терять их
                                                доверия.<br/>
                                                УЧЕНИКИ<br/>
                                                1. Никогда не уставать учиться. Хороший ученик может учиться везде и в
                                                любое время. В этом секрет успеха в учебе.<br/>
                                                2. Хороший ученик готов на жертвы ради своего боевого искусства и
                                                инструктора. Некоторые ученики рассматривают обучение как товар,
                                                купленный за ежемесячную плату, и не желают участвовать в выступлениях,
                                                обучении или работе в спортзале. Инструктор может позволить себе
                                                избавиться от таких учеников.<br/>
                                                3. Всегда быть хорошим примером для младших учеников. Вполне
                                                естественно, что они захотят соревноваться со старшими.<br/>
                                                4. Всегда быть преданными, никогда не критиковать инструктора,
                                                применяемые способы обучения и само таэквон-до.<br/>
                                                5. Изучать и стараться использовать показанный инструктором прием.<br/>
                                                6. Помнить, что поведение ученика за пределами спортзала отражается на
                                                боевом искусстве и на инструкторе.<br/>
                                                7. Если ученик освоил новые приемы из другой школы, а инструктор
                                                возражает против их применения, ученик должен отказаться от них, либо
                                                заниматься там, где он выучил эти приемы.<br/>
                                                8. Никогда не проявлять неуважение к инструктору. Хотя ученику
                                                позволительно не соглашаться с инструктором, он должен сначала выполнять
                                                задание, а обсуждать все вопросы позже.<br/>
                                                9. Всегда быть активным, не стесняясь задавать вопросы.<br/>
                                                10. Никогда не предавать инструктора.</p>
                                            <p>*Рассматривают три тренировочные программы, которые могут использоваться
                                                начинающими:</p>
                                            <p>1. 18 – месячный курс (702 часа): 6 тренировок в неделю по 1,5 часа
                                                каждая.</p>
                                            <p>2. 30 – месячный курс (585 часов): 3 тренировки в неделю по 1,5 часа
                                                каждая.</p>
                                            <p>3. 12 – месячный курс (1248 часов): 6 тренировок в неделю по 4 часа
                                                каждая.</p>
                                            <p>&nbsp;</p>
                                            <p><b><i>&nbsp; Билет № 8 </i></b>5 критериев, по которым оценивается туль
                                                на соревнованиях по Таэквон-до.; <b></b>&nbsp;&nbsp;«ХВА-РАН»
                                                (расшифровать название, диаграмму и указать количество движений).</p>
                                            <p><b><i>Ответ:</i></b>&nbsp; *Критерии начисления очков</p>
                                            <p>Техника;&nbsp; 10 очков</p>
                                            <p>Сила;&nbsp; 6 очков</p>
                                            <p>Равновесие&nbsp; 6 очков</p>
                                            <p>Контроль дыхания 6 очков</p>
                                            <p>Ритм;&nbsp; 6 очков</p>
                                            <p>*Туль «Хва Ран» назван в честь молодежного воинского движения,
                                                организованного в VII веке во время правления династии Силла. Включение
                                                в туль 29 движений символизирует номер воинского подразделения, в
                                                котором происходил процесс трансформирования Таэквон-до в современное
                                                боевое искусство.</p>
                                            <p>&nbsp;</p>
                                            <p align="center"><a className="belt-with-margin" name="1 KUP"></a><b>1 </b><b>ГЫП</b><b> (<a
                                                style={{color: 'red'}}>красно</a>-</b><b>черный&nbsp; пояс)</b></p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Туль<i>:</i></span><i> «ХВА-РАН»&nbsp; </i></b>
                                            </p>
                                            <p><b><i>&nbsp; «ЧУН-МУ»&nbsp; </i></b></p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Удары ногами:</span></b>
                                                <i>Йоп чаги, Тора йоп чаги, Тора горо чаги, Тора бакуро нэрьо чаги,
                                                    Бандэ долльо</i> чаги.&nbsp; <i>Твимьо ап чаги</i> (наносится с
                                                задней ноги), <i>Твимьо йоп чаги, Твимьо долльо чаги, Твимьо горо
                                                    чаги</i> (эти 3 удара наносится с передней ноги), <i>Твимьо тора йоп
                                                    чаги, Твимьо тора горо чаги, Твимьо</i> <i>бандэ долльо
                                                    чаги. &nbsp;Ибо доллио чаги</i> (удары наносятся с задней ноги на
                                                средний и верхний уровень туловища)</p>
                                            <p><i>Ибо йоп чаги </i>(удары наносятся с задней ноги на средний и верхний
                                                уровень туловища)</p>
                                            <p><i>Горо чаги- Долльо чаги</i></p>
                                            <p><i>Долльо чаги- Йоп чаги</i></p>
                                            <p><i>Бакуро нэрьо чаги- Долльо чаги</i></p>
                                            <p><i>Долльо чаги- Бакуро нэрьо чаги</i></p>
                                            <p><i>Горо чаги- Долльо чаги- Йоп чаги</i></p>
                                            <p><i>Тора горо чаги- Долльо чаги</i></p>
                                            <p>Данные серии наносятся с задней ноги без касания пола между ударами.</p>
                                            <p><i>Долльо чаги, Бакуро нэрьо чаги, Горо чаги, Йоп чаги &nbsp;-</i>удары
                                                наносятся с передней ноги со скольжением</p>
                                            <p><i>&nbsp;Тора йоп чаги, Тора горо чаги-</i>удары наносятся с задней ноги
                                                со скольжением.</p>
                                            <p>Серии ударов руки-ноги на усмотрение инструктора</p>
                                            <p>Удары ногами выполняются попеременно левой-правой ногой из положения
                                                Нюнча со пальмок дэби макки. Количество — на усмотрение инструктора.</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Самооборона</span></b></p>
                                            <p>Освобождение от захвата, одной рукой, спереди</p>
                                            <p>Освобождение от захвата, двумя руками, спереди</p>
                                            <p>Освобождение от захвата одноименной рукой сзади за плечо</p>
                                            <p>Освобождение от захвата разноименной рукой сзади за плечо</p>
                                            <p>Освобождение от захвата «в ключ» за шею сбоку</p>
                                            <p>Освобождение от захвата «в ключ» за шею, под руками, сзади</p>
                                            <p>Освобождение от захвата спереди за волосы</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Специальная техника: </span></b>&nbsp;
                                                <b><i>Твимьо нопи чаги&nbsp; </i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной 1.5-2см (для спортсменов в возрасте
                                                от 13 лет и старше). Доска располагается на голову выше роста
                                                аттестуемого.</p>
                                            <p><b><i>Твимьо долльо чаги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см.(для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной 1.5-2см (для спортсменов в возрасте
                                                от 13 лет и старше). Середина доски располагается на голову выше роста
                                                аттестуемого.</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Силовое разбивание:</span></b>&nbsp;&nbsp; &nbsp;
                                                <b><i>Йоп чаги </i></b>Удар наносится по доске толщиной 1.5 см. (для
                                                спортсменов в возрасте&nbsp; до 12 лет) и толщиной от 2см и больше (для
                                                спортсменов в возрасте от 13 лет и старше).</p>
                                            <p align="center"><b><i>Долльо чаги</i></b></p>
                                            <p>Удар наносится по доске толщиной от 1 до 1.5 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной от 2см и больше (для спортсменов в
                                                возрасте от 13 лет и старше).</p>
                                            <p><b><i>&nbsp;&nbsp; &nbsp;&nbsp;Сонкаль дунг тэриги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной от 1.5см и больше (для спортсменов
                                                в возрасте от 13 лет и старше).</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Спарринг:</span></b>&nbsp; Ильбо
                                                матсоги (спарринг на 1 шаг с партнером)</p>
                                            <p>Свободный спарринг -2 мин, по минуте с разными партнерами.</p>
                                            <p>Спарринг против 2-х противников одновременно (1 мин.)</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>ОФП:</span></b> 40-отжиманий,60-приседаний,
                                                40-«складочка» (для спортсменов в возрасте до 12 лет)</p>
                                            <p>45-отжиманий,65-приседаний, 45-«складочка» (для спортсменов в возрасте от
                                                13 лет и старше)</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Теория:&nbsp;&nbsp; </span></b>
                                            </p>
                                            <p><b><i>Билет № 1</i></b> Духовные принципы Таэквон-до (краткое определение
                                                каждого принципа).</p>
                                            <p><b><i>Билет № 2 </i></b>&nbsp;Философия Таэквон-до.&nbsp; «ТЭ-ГЕ»
                                                (расшифровать название, диаграмму и указать количество движений).</p>
                                            <p><b><i>Билет № 3&nbsp; </i></b>Что развивает самооборона? Дигуджа макки —
                                                описание движения и название тулей, в которых оно применяется. Критерии,
                                                по которым оценивается, на соревнованиях, командный туль</p>
                                            <p><b><i>Билет № 4</i></b> Что развивает силовое разбивание? Система
                                                начисления очков в спарринге.</p>
                                            <p><b><i>Билет № 5&nbsp; </i></b>Дифференциация званий в Таэквон-до (как,
                                                по-корейски, называются инструктора, с 1 по 9 дан). Идеальный
                                                инструктор.</p>
                                            <p><b><i>Билет № 6&nbsp; </i></b>Что обозначает красный цвет
                                                пояса?<b> </b>«ЧУН-МУ»&nbsp; (расшифровать название, диаграмму и указать
                                                количество движений). Виды спарринга (на корейском
                                                языке)<b><i> </i></b>&nbsp;Что развивает специальная техника?</p>
                                            <p><b><i>Билет № 7 </i></b>Взаимоотношения «ученик-инструктор» (предписания,
                                                которые им необходимо выполнять).<b> </b>Какие виды программ существуют
                                                в Таэквон-до</p>
                                            <p><b><i>Билет № 8 </i></b>5 критериев, по которым оценивается личный туль
                                                на соревнованиях по Таэквон-до. «ХВА-РАН» (расшифровать название,
                                                диаграмму и указать количество движений).</p>
                                            <p>&nbsp;</p>
                                            <p align="center"><a className="belt-with-margin" name="1 DAN"></a><b>I</b><b> </b><b>ДАН (<em>черный</em> пояс)</b><b></b>
                                            </p>
                                            <p align="center"><b>&nbsp;</b></p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Туль<i>:</i></span><i> «КВАН-ГЭ»</i></b>
                                            </p>
                                            <p><b><i>&nbsp; «ПО-УН»</i></b></p>
                                            <p><b><i>&nbsp; «ГЕ-БЭК»</i></b></p>
                                            <p>После выполнения 3-х обязательных тулей — любой ,от «ЧОН-ЖИ»
                                                до&nbsp; «ЧУН-МУ»,на усмотрение экзаменатора.</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Удары ногами:</span></b> &nbsp;Ап
                                                чаги, Долльо чаги, Ануро нэрьо чаги, Бакуро нэрьо чаги, Горо
                                                чаги, <b>&nbsp;</b><i>Йоп чаги, Тора йоп чаги, Тора горо чаги, Тора
                                                    бакуро нэрьо чаги, Бандэ долльо</i> чаги.&nbsp; <i>Твимьо ап
                                                    чаги</i> (наносится с задней ноги), <i>Твимьо йоп чаги, Твимьо
                                                    долльо чаги, Твимьо горо чаги</i> (эти 3 удара наносится с передней
                                                ноги), <i>Твимьо тора йоп чаги, Твимьо тора горо чаги, Твимьо</i> <i>бандэ
                                                    долльо чаги&nbsp; Ибо долльо чаги</i> (удары наносятся с задней ноги
                                                на средний и верхний уровень туловища)</p>
                                            <p><i>Ибо йоп чаги </i>(удары наносятся с задней ноги на средний и верхний
                                                уровень туловища)</p>
                                            <p><i>Горо чаги- Долльо чаги</i></p>
                                            <p><i>Доллио чаги- Йоп чаги</i></p>
                                            <p><i>Бакуро нэрьо чаги- Долльо чаги</i></p>
                                            <p><i>Долльо чаги- Бакуро нэрьо чаги</i></p>
                                            <p><i>Горо чаги- Долльо чаги- Йоп чаги</i></p>
                                            <p><i>Тора горо чаги- Долльо чаги</i></p>
                                            <p>Данные серии наносятся с задней ноги без касания пола между ударами.</p>
                                            <p><i>Долльо чаги, Бакуро нэрьо чаги, Горо чаги, Йоп чаги&nbsp; -</i>удары
                                                наносятся с передней ноги со скольжением</p>
                                            <p><i>Тора йоп чаги, Тора горо чаги-</i>удары наносятся с задней ноги со
                                                скольжением.</p>
                                            <p><i>Серии ударов руки-ноги на усмотрение инструктора</i></p>
                                            <p>Удары ногами выполняются попеременно левой-правой ногой из положения
                                                Нюнча со пальмок дэби макки. Количество — на усмотрение инструктора.</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Самооборона</span></b></p>
                                            <p>Освобождение от захвата, одной рукой, спереди</p>
                                            <p>Освобождение от захвата, двумя руками, спереди</p>
                                            <p>Освобождение от захвата одноименной рукой сзади за плечо</p>
                                            <p>Освобождение от захвата разноименной рукой сзади за плечо</p>
                                            <p>Освобождение от захвата «в ключ» за шею сбоку</p>
                                            <p>Освобождение от захвата «в ключ» за шею, под руками, сзади</p>
                                            <p>Освобождение от захвата спереди за волосы</p>
                                            <p>Защита от удара <i>Долльо чаги</i></p>
                                            <p>Защита от удара <i>ножом сверху</i></p>
                                            <p>Защита от удара <i>ножом снизу</i></p>
                                            <p>Защита от удара <i>ножом на уровне солнечного сплетения</i></p>
                                            <p><b>&nbsp;<span
                                                style={{textDecoration: 'underline'}}>Специальная техника: </span></b>&nbsp;
                                                <b><i>Твимьо нопи чаги&nbsp; </i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной 1.5-2см (для спортсменов в возрасте
                                                от 13 лет и старше). Доска располагается высоте вытянутой руки
                                                аттестуемого.</p>
                                            <p><b><i>Твимьо долльо чаги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1 см.(для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной 1.5-2см (для спортсменов в возрасте
                                                от 13 лет и старше). Середина доски располагается высоте вытянутой руки
                                                аттестуемого.</p>
                                            <p align="center"><b><i>Твимьо бандэ долльо</i></b><i> <b>чаги</b></i></p>
                                            <p>Удар наносится по доске толщиной 1 см.(для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной 1.5-2см (для спортсменов в возрасте
                                                от 13 лет и старше). Середина доски располагается высоте вытянутой руки
                                                аттестуемого.</p>
                                            <p align="center"><b><i>Твимьо номо</i></b><i> <b>чаги</b></i></p>
                                            <p>Удар наносится по доске толщиной 1.5 см. Удар наносится в прыжке, через,
                                                стоящих на коленях, 8 человек(для спортсменок-6 чел.)</p>
                                            <p><b><span style={{textDecoration: 'underline'}}>Силовое разбивание</span></b> &nbsp;
                                                <b><i>Ап джумок чируги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1,5 см. (для спортсменов в
                                                возрасте &nbsp;до 12 лет) и толщиной от 1.5см и больше (для спортсменов
                                                в возрасте от 13 лет и старше).</p>
                                            <p align="center"><b><i>Сонкаль тэриги</i></b></p>
                                            <p>Удар наносится по доске толщиной 2 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной от 1.5см и больше (для спортсменов
                                                в возрасте от 13 лет и старше).</p>
                                            <p><b></b><b><i>Йоп чаги </i></b>Удар наносится по доске толщиной 2 см. (для
                                                спортсменов в возрасте&nbsp; до 12 лет) и толщиной от 2см и больше (для
                                                спортсменов в возрасте от 13 лет и старше).</p>
                                            <p align="center"><b><i>Долльо чаги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1.5 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной от 2см и больше (для спортсменов в
                                                возрасте от 13 лет и старше).</p>
                                            <p align="center"><b><i>Бандэ долльо</i></b><i> <b>чаги</b></i></p>
                                            <p>Удар наносится по доске толщиной 1.5 см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной от 2см и больше (для спортсменов в
                                                возрасте от 13 лет и старше).</p>
                                            <p align="center"><b><i>Тора горо чаги</i></b></p>
                                            <p>Удар наносится по доске толщиной 1см. (для спортсменов в
                                                возрасте&nbsp; до 12 лет) и толщиной от 1.5см и больше — для спортсменов
                                                в возрасте от 13 лет и старше. Доска держится одной рукой за верхнюю
                                                часть. После нанесения удара эта часть должна остаться в руке
                                                ассистента.</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>Спарринг:</span></b>&nbsp; Самбо
                                                матсоги (спарринг на 3 шаг с партнером)</p>
                                            <p>Ибо матсоги (спарринг на 2 шаг с партнером)</p>
                                            <p>Ильбо матсоги (спарринг на 1 шаг с партнером)</p>
                                            <p>Свободный спарринг -5 мин, по минуте с разными партнерами.</p>
                                            <p><b><span
                                                style={{textDecoration: 'underline'}}>ОФП:</span></b> 50-отжиманий,100-приседаний,
                                                50-«складочка»</p>
                                            <p>&nbsp;</p>
                                            <p><b>Теория:</b></p><br/>
                                            <p><b><i>Билет № 1.</i></b></p> Духовные принципы Таэквон-До (краткое
                                            определение каждого принципа).<br/>

                                            1) Учтивость (Ye Ui). Формируя свой характер, занимающиеся Таэквон-до должны
                                            практиковать следующие проявления учтивости,
                                            включая их как обязательные элементы в тренировочный процесс: развивать дух
                                            взаимоуважения; стыдиться своих вредных привычек;
                                            быть вежливым; поддерживать атмосферу справедливости и гуманизма; соблюдать
                                            иерархию между занимающимися и инструктором, начинающими и
                                            квалифицированными спортсменами, старшими и младшими; вести себя в
                                            соответствии с принятым этикетом; уважать положение других; вести себя с
                                            другими честно и открыто.<br/>
                                            2) Честность(Yom Chi). Каждый должен уметь отличать правильное от ложного, в
                                            случае собственной неправоты, испытывать угрызения совести.<br/>
                                            3) Настойчивость (In Nae). Древние говорили: «Настойчивость приводит к
                                            очищению и совершенству». «Тот, кто будет стократно настойчив,
                                            обеспечит мир своему дому». Счастье и удача чаще всего достаются
                                            настойчивым. Для того, чтобы чего-либо достичь, необходимо поставить
                                            перед собой цель и настойчиво ее добиваться. Один из секретов становления
                                            мастерства в
                                            Таэквон-до – настойчивое преодоление всех трудностей.<br/>
                                            4) Самообладание (Guk Gi). Самообладание чрезвычайно важно как в
                                            тренировочном зале, так и в повседневной жизни. Потеря самоконтроля во время
                                            спарринга может привести к очень серьёзным последствиям для обоих
                                            соперников. Неуживчивость внутри коллектива является проявлением плохого
                                            самоконтроля.
                                            Великий китайский мыслитель Лао Цзы говорил: «Сильный – тот, кто умеет
                                            побеждать себя, а не другого».<br/>
                                            5) Непоколебимость духа (Baekjul Boolgool). Занимающиеся Таэквон-до должны
                                            быть честными и искренними. Столкнувшись с несправедливостью,
                                            они должны действовать активно, ничего не боясь, проявлять непоколебимый
                                            дух, независимо от того, кто им противостоит и как много
                                            противников.<br/><br/>

                                            <p><b><i>Билет № 2.</i></b></p> Философия Таэквон-До. Любой туль, по желанию
                                            экзаменатора (расшифровать название, диаграмму и указать количество
                                            движений).<br/>

                                            Философия таэквон-до основана на этических, моральных и духовных принципах,
                                            на основе которых люди могут жить вместе в гармонии.
                                            Генерал Чой Хонг Хи установил следующие философские принципы, которые будут
                                            краеугольными камнями таэквон-до, по которым все прилежные ученики этого
                                            боевого искусства должны жить:<br/>
                                            Быть полным решимости идти, хотя путь будет трудным, и делать то, что нужно
                                            делать, хотя это будет нелегко.<br/>
                                            Быть внимательным к слабым и суровым к сильным.<br/>
                                            Быть удовлетворенным тем положением и деньгами, которые имеешь, но никогда
                                            не быть удовлетворенным своим мастерством.<br/>
                                            Всегда завершать начатое дело, будь оно большим или малым.<br/>
                                            Быть всегда готовым учить любого, независимо от религии, расы и
                                            идеологии.<br/>
                                            Никогда не поддаваться давлению или угрозе при осуществлении благородной
                                            цели.<br/>
                                            Учить мировоззрению и навыкам делами, а не словами.<br/>
                                            Всегда оставаться собой, даже когда обстоятельства жизни меняются.<br/>
                                            Всегда быть учителем, который учит телом, когда молод, словами – когда стар,
                                            духовными заповедями – после смерти.<br/>
                                            «ЧОН-ДЖИ» переводится как «рай земной». На востоке оно интерпретируется как
                                            создание мира и зарождение истории человечества. Это первый туль, с которым
                                            знакомится новичок. Он состоит из двух частей, первая из которых
                                            символизирует Землю, а вторая – Небеса. Количество движений: 19.<br/>
                                            «ДАН-ГУН» Туль «Дан Гун» назван в честь Дан Гуна – родоначальника корейской
                                            нации и основателя Дангунской Кореи, существовавшей в 2333 г. до н.э.. Туль
                                            насчитывает 21 движение.<br/>
                                            «ДО-САН» является псевдонимом известного патриота Ан Чхан Хо (1876-1938).
                                            Включение в туль 24 движений символизирует годы жизни, посвященные этим
                                            человеком просветительской деятельности и участию в движении за освобождение
                                            Кореи<br/>
                                            «ВОН-ХЕ» Туль «Вон Хё» назван в честь монаха, познакомившего в 686 г до н.э.
                                            корейскую династию Силла с буддизмом. Количество движений – 28.<br/>
                                            «ЮЛЬ-ГОК» Название туля «Юль Гок» является псевдонимом великого философа и
                                            учителя Ли И (1536-1584), прозванного «Корейским Конфуцием». Включение в
                                            туль 38 движений символизирует место рождения этого человека – 38 параллель,
                                            а диаграмма движений – иероглиф, соответствующий слову «учитель».<br/>
                                            «ДЖУН-ГУН» Назван в честь патриота Ан Джун Гуна, который убил первого
                                            японского генерал – губернатора Кореи. Тридцать два движения этого туля
                                            соответствуют возрасту мистера Ана, когда он был казнен в тюрьме Луи Шунг в
                                            1910 году.<br/>
                                            «ТЭ-ГЭ» Название туля «Тэ Гэ» является псевдонимом жившего в XVI веке
                                            учителя И Вонга, известного авторитета неоконфуцианизма. Включение в туль 37
                                            движений символизирует место рождения этого человека – 37 параллель, а
                                            диаграмма движений – иероглиф, соответствующий слову «учитель».<br/>
                                            «ХВА-РАН» Туль «Хва Ран» назван в честь молодежного воинского движения,
                                            организованного в VII веке во время правления династии Силла. Включение в
                                            туль 29 движений символизирует номер воинского подразделения, в котором
                                            происходил процесс трансформирования Таэквон-до в современное боевое
                                            искусство.<br/>
                                            «ЧУН-МУ» Название является псевдонимом великого адмирала Ли Сун Сина -
                                            изобретателя боевого корабля (в 1592 г.), ставшего прообразом современных
                                            подводных лодок. Туль заканчивается ударом левой рукой, что символизирует
                                            скоропостижную кончину этого человека, так и не успевшего полностью раскрыть
                                            свою непоколебимую преданность королю. Количество движений: 30<br/>
                                            «КВАН-ГЭ» Количество движений: 39. Исходное положение: ханаль сон нарани
                                            соги. Значение: Кван-Ге имя 19го короля династии Когурё, который вернул все
                                            потерянные территории, включая большую часть Манчжурии. Диаграмма
                                            символизирует экспансию и восстановление потерянных территорий. Число 39
                                            символизирует первые две цифры числа 391 года нашей эры, когда он взошел на
                                            трон.<br/>
                                            «ПО-УН» Количество движений: 36. Исходное положение ханаль сон нарани соги.
                                            Значение: По-Ун псевдоним поэта Чанг Му Су (1400 год нашей эры), чьи стихи
                                            «я не буду служить второму мастеру, даже если меня казнят тысячу раз»
                                            известны каждому Корейцу. Он также был пионером в области физики. Диаграмма
                                            символизирует бескорыстную преданность Королю и родине.<br/>
                                            «ГЕ-БЭК» Количество движений: 44. Значение:Ге-Бек имя генерала династии
                                            Пэкче (660 год нашей эры). Диаграмма символизирует его строгую военную
                                            дисциплину.<br/><br/>

                                            <p><b><i>Билет № 3.</i></b></p> История Таэквон-До (имя и основные даты
                                            основателя, расшифровать название, дата основания И.Т.Ф. и первые страны,
                                            вошедшие в состав федерации). «ГЕ-БЭК» (рас¬шифровать название, диаграмму и
                                            указать количество движений).<br/>

                                            Основатель Таэквон-до, генерал Чой Хонг Хи родился 9 ноября 1918 года в
                                            местечке Хвадэ (территория нынешней КНДР).
                                            Свое название Таэквон-до получило 11 апреля 1955 года. Международная
                                            Федерация Таэквон-до (ITF) была создана 22 марта
                                            1966 года ассоциациями таэквон-до Вьетнама, ФРГ, США, Малайзии, Египта,
                                            Турции, Италии, Сингапура и Кореи. Федерация Таэквон-до И.Т.Ф. Украины
                                            создана в 1992 году.<br/><br/>

                                            <p><b><i>Билет № 4.</i></b></p> Теория мощности (основные составляющие).
                                            «ПО-УН» (расшифровать название, диаграмму и указать коли¬чество
                                            движений).<br/>

                                            Многие люди, видя, какой силой обладают занимающиеся Таэквондо, изумляются
                                            и спрашивают, откуда берется эта сила и как еѐ достигают.Если говорить
                                            кратко, это возможно, если использовать всю силу, которой обладает
                                            человеческое тело, на основе математических и научных методов. Обычный
                                            человек может использовать лишь от 10 до 20 процентов той силы, которой он
                                            обладает. Занимающийся Тхэквондо учится использовать эту силу на все 100
                                            процентов.<br/>
                                            В этом смысле занимающиеся Тхэквондо, независимо от телосложения, возраста и
                                            пола, не только используют на 100 процентов возможности внутренней силы, но
                                            и укрепляют свое здоровье; и в этом нет ничего сверхчеловеческого.<br/>
                                            Короче говоря, в проявлении максимальной силы в Таэквондо важное место
                                            занимают принципы: реактивной силы, концентрации, равновесия, управления
                                            дыханием, массы и скорости, поставленные на прочную основу научной
                                            теории.<br/>
                                            Реактивная сила<br/>
                                            Для того чтобы читатель понял, о чем идет речь, дадим научное разъяснение.В
                                            соответствии с III законом Ньютона, на каждую силу, приложенную в
                                            какой-либо точке тела, со сто- роны тела действует сила реакции, равная
                                            приложенной силе и противоположно ей направленная.Так, например, если
                                            автомобиль ударяется в стену с силой 2000 фунтов, то и со стороны стены на
                                            автомобиль действует сила реакции в 2000 фунтов.<br/>
                                            Далее, в соответствии с законом сохранения импульса, если на одну сторону
                                            качелей давить с силой в 1 тонну, то со стороны протиивоположного плеча
                                            будет действовать такая же сила.<br/>
                                            Поэтому, если на противоположное плечо воздействовать с некоторой
                                            дополнительной силой, то в нужном нам направлении сила еще более
                                            возрастает.Эта противодействующая сила носит название реактивной
                                            силы.<br/>
                                            Если в соответствии с вышеизложенным, вы ударяете правым кулаком,
                                            одновременно притягивая в противоположном направлении левый кулак, то сила
                                            удара в этом случае будет увеличиваться настолько, насколько сильно
                                            притягивается левый кулак.<br/>
                                            Концентрация<br/>
                                            Эффект воздействия силы удара в результате сосредоточения в небольшой цели
                                            еще более возрастает.Если, к примеру, сжать один конец резинового шланга,
                                            то сила и скорость истечения воды будет больше, чем в случае, если этот
                                            конец будет открытым.<br/>
                                            В Тхэквондо концентрация в момент удара передается в большинстве случаев в
                                            руки или ноги, иногда в другие части тела.
                                            При этом очень важно не концентрировать всю силу удара с самого начала
                                            движения, а делать это постепенно, с тем, чтобы эта концентрация достигла
                                            максимума в момент касания цели, которую нужно разбить одним ударом.<br/>
                                            В противном случае удар будет несильным.<br/>
                                            Концентрация находится в обратной зависимости со временем в прямой с
                                            ускорением; чем меньше площадь цели и чем с большим ускорением выполняется
                                            удар, тем она будет сильнее.<br/>
                                            Концентрация определяется напряжением, с одной стороны, мышц всего тела, а
                                            с другой выполняющих специальное действие, при этом важное значение имеет
                                            напряжение
                                            больших мышц поясницы и живота, которые медленнее используемых бьющих
                                            частей непосредственно перед жизненно важной точкой тела противника.<br/>
                                            Поэтому для достижения максимальной силы удара необходимо
                                            сконцентрироваться именно в момент достижения цели.<br/>
                                            При этом, поскольку мышцы поясницы и живота больше, чем мышцы рук и ног, то
                                            они медленнее последних и ввиду этого движение
                                            мышц живота и поясницы должно начинаться раньше, чем рук и ног как в
                                            нападении, так и в защите.Однако не должно производиться разделения движения
                                            мышц каждой в отдельности.<br/>
                                            Равновесие<br/>
                                            Хотя в спорте вообще уделяется внимание равновесию, в Таэквондо равновесию
                                            придается особое значение, потому что, сохраняя устойчивое
                                            равновесие в защите и, конечно, в атаке, можно выполнить смертельный удар,
                                            а с другой стороны, не имея равновесия, можно упасть или значительно
                                            ослабить силу удара.
                                            Поэтому и в атаке, и в защите, независимо от стойки, следует занимать
                                            устойчивое положение, в котором будет удобно передвигаться.<br/>
                                            В Таэквондо есть два вида равновесия — динамическая устойчивость и
                                            статическая устойчивость, которые тесно связаны
                                            с проявлением максимальной силы. Следует к этому добавить, что для
                                            достижения максимальной силы удара устойчивость должна сохраняться с
                                            момента начала движения и до его конца.<br/>
                                            В равновесии большое значение имеют: сила тяжести, положение пятки и
                                            упругость коленей; если вес тела поровну
                                            распределен на обе ноги, то линия силы тяжести должна проходить через
                                            среднюю линию тела; если вес тела приходится на одну ногу,
                                            то линия силы тяжести должна проходить через середину опорной ноги. В
                                            момент атаки и, конечно, сразу же после неѐ необходимо
                                            восстанавливать равновесие, при этом нельзя поднимать пятку задней ноги, а
                                            колени следует сохранять упругими.В противном случае вы не добьетесь
                                            максима- льной силы ни в атаке, ни в защите.<br/>
                                            Дыхание<br/>
                                            Хорошее управление дыханием, с одной стороны, способствует повышению
                                            выносливости и скорости, а с другой стороны,
                                            помогает выдержать сильный удар.В Тхэквондо для повышения скорости во время
                                            движения следует задерживать дыхание;
                                            так, во время состязания в беге на 100м более быстрым окажется тот, кто
                                            пробегает дистанцию на одном дыхании, а не тот, кто часто дышит.<br/>
                                            Для достижения максимальной силы необходимо связывать движение и дыхание
                                            таким образом, чтобы в момент, когда
                                            атакующая или блокирующая часть тела соприкасается с целью, делать короткий
                                            выдох, а после этого делать глубокий вдох.
                                            Но, когда вы обороняетесь, не допускайте, чтобы вам нанесли удар в момент,
                                            когда вы делаете глубокий вдох, поскольку
                                            в этом случае даже слабый удар окажется для вас сильным; и наоборот, если
                                            вы будете делать короткий выдох.<br/>
                                            Масса<br/>
                                            Если занимающиеся Тхэквондо внимательно рассмотрят математическую связь
                                            силы, веса и скорости, то они заметят,
                                            что в процессе выполнения движения с момента его начала идо момента
                                            окончания более важное значение имеет скорость, чем масса;
                                            но и масса имеет значение в увеличении силы. Существует два способа
                                            увеличения массы:<br/>
                                            первый — за счет того, что мышцы живота и спины дают дополнительный
                                            кинетический импульс рукам, которые скрещиваются между собой;<br/>
                                            второй — за счет того, что максимально используется упругость коленей — в
                                            начале движения бедра поднимаются, а к моменту окончания
                                            движения — опускаются.В результате движения тела сверху вниз его масса
                                            увеличивается.<br/>
                                            За 2 тысячелетия наука шагнула далеко вперед. Поэтому занимающиеся должны
                                            изучать всѐ, что достигнуто человечеством и использовать эти достижения для
                                            увеличения силы.<br/>
                                            Скорость<br/>
                                            Самое важное место в увеличении мощности играет скорость. Научно говоря F=MA
                                            и P=MV2, где сила прямо пропорциональна
                                            массе и квадрату скорости (ускорению). Энергия же пропорциональна массе и
                                            квадрату скорости.<br/>
                                            В Таэквондо очень важен этот принцип увеличения энергии.Таким образом, в
                                            деле увеличения скорости важное
                                            место отводится реактивной силе, управлению дыханием, равновесию, массе и
                                            работе мышц спины.При этом движения должны выполняться мягко для достижения
                                            максимальной мощности.<br/><br/>

                                            <p><b><i>Билет № 5.</i></b></p> Дифференциация званий (как, по-корейски,
                                            называются инструктора, с 1 по 9 дан). Идеальный инструктор.<br/>

                                            *1-3 дан – Бусабом (помощник инструктора);<br/>
                                            4-6 дан – Сабом (инструктор);<br/>
                                            7-8 дан – Сахён (мастер);<br/>
                                            9 дан – Сасунг (гранд мастер)<br/>
                                            *Идеальный инструктор должен обладать следующими чертами:<br/>
                                            1. Высокий моральный и этический уровень.<br/>
                                            2. Ясная философия и взгляд на жизнь.<br/>
                                            3. Ответственное отношение к своим обязанностям.<br/>
                                            4. Научный подход к вопросам техники.<br/>
                                            5. Знание локализации уязвимых точек на теле человека.<br/>
                                            6. Непоколебимая чистота в политических и финансовых делах.<br/>
                                            7. Желание посвятить себя распространению Таэквон-до по всему миру.<br/>
                                            8. С ним должны быть искренни старшие, ему должны доверять коллеги, его
                                            должны уважать младшие.<br/><br/>

                                            <p><b><i>Билет № 6.</i></b></p> «ЧУН-МУ» (расшифровать название, диаг¬рамму
                                            и указать количество движений). Виды спарринга (на корейском).<br/>
                                            Что развивает специальная техника?<br/>

                                            *Главным образом различают следующие виды спарринга:<br/>
                                            «Яксок масоги» (обусловленный) делится на «самбо» (на 3 шага), «ибо» (на 2
                                            шага), «ильбо» (на 1 шаг).<br/>
                                            «Пан чжаю масоги» — полусвободный спарринг.<br/>
                                            «Чжаю масоги» — свободный спарринг.<br/>
                                            «Мобом масоги» — модельный спарринг.<br/>
                                            «Баль масоги» — спарринг с использованием только действий ногами.<br/>
                                            «Яксок чжаю масоги» — свободный спарринг с заранее оговоренным
                                            сценарием.<br/>
                                            *Специальная техника призвана оценить координацию движений, акробатическую
                                            подготовку и умение решать сложные двигательные задачи.<br/><br/>

                                            <p><b><i>Билет № 7.</i></b></p> Взаимоотношения «ученик-инструктор»
                                            (предписания, которые им необходимо выполнять). Система начисления очков в
                                            спарринге.<br/>

                                            *Ответственность за обучение искусству Таэквон-до лежит на инструкторах,
                                            основная задача которых состоит в том,
                                            чтобы сделать учеников физически и духовно здоровыми и тем самым внести свой
                                            вклад в создание мирного общества.
                                            Конечно же, каждой школе необходим преданный и искренний инструктор. Но ни
                                            одна школа не сможет расти и развиваться при отсутствии столь же преданных и
                                            искренних учеников.<br/>
                                            Инструктору и ученику необходимо выполнять следующие предписания:<br/>
                                            ИНСТРУКТОР<br/>
                                            1. Никогда не уставать учить. Хороший инструктор может учить везде, в любое
                                            время. Он всегда должен быть готов отвечать на вопросы.<br/>
                                            2. Инструктор должен стремиться, чтобы ученик превзошел его. Такой результат
                                            служит величайшей похвалой инструктору. <br/>
                                            Ученика никогда нельзя удерживать позади. Если инструктор понял, что ученик
                                            превзошел его возможности, ученика надо передать инструктору более высокой
                                            квалификации.<br/>
                                            3. Инструктор всегда должен служить примером для учеников и никогда не
                                            пытаться обманывать их.<br/>
                                            4. Воспитание ученика должно быть выше материальной заинтересованности. Как
                                            только инструктор станет озабочен материальными аспектами обучения, он сразу
                                            же потеряет уважение учеников.<br/>
                                            5. Инструктор должен учить, основываясь на научной базе, чтобы экономить
                                            силы и время.<br/>
                                            6. Инструктор должен помогать ученикам устанавливать хорошие отношения за
                                            пределами спортивного зала. Обязанность инструктора — заниматься воспитанием
                                            учеников как в зале, так и за его пределами.<br/>
                                            7. Необходимо поощрять посещение других спортзалов, изучение опыта других
                                            школ. Ученики, которым запрещено посещать другие школы, могут стать
                                            непослушными. Целесообразность посещения
                                            других школ обусловлена тем, что ученик не только сможет подобрать школу,
                                            которая ему подходит наилучшим образом, но и многому научиться, сравнивая
                                            разные школы.<br/>
                                            8. Ко всем ученикам следует относиться одинаково. Любимчиков быть не должно.
                                            Ругать ученика можно только наедине с ним и никогда не делать этого на
                                            глазах других.<br/>
                                            9. Если инструктор не знает ответа на вопрос, ему не следует придумывать
                                            ответ. Надо признать свою неосведомленность и попытаться найти ответ как
                                            можно быстрее.<br/>
                                            10. Инструктор не должен искать у учеников такого рода помощи, как уборка
                                            кабинетов, ремонт и т.п.<br/>
                                            11. Инструктор не должен использовать своих учеников для личных целей.
                                            Единственная цель инструктора — воспитать физически и духовно совершенных
                                            учеников.<br/>
                                            12. Инструктор должен всегда быть честным с учениками, не терять их доверия.<br/>
                                            УЧЕНИКИ<br/>
                                            1. Никогда не уставать учиться. Хороший ученик может учиться везде и в любое
                                            время. В этом секрет успеха в учебе.<br/>
                                            2. Хороший ученик готов на жертвы ради своего боевого искусства и
                                            инструктора. Некоторые ученики рассматривают обучение как товар,
                                            купленный за ежемесячную плату, и не желают участвовать в выступлениях,
                                            обучении или работе в спортзале. Инструктор может позволить себе избавиться
                                            от таких учеников.<br/>
                                            3. Всегда быть хорошим примером для младших учеников. Вполне естественно,
                                            что они захотят соревноваться со старшими.<br/>
                                            4. Всегда быть преданными, никогда не критиковать инструктора, применяемые
                                            способы обучения и само таэквон-до.<br/>
                                            5. Изучать и стараться использовать показанный инструктором прием.<br/>
                                            6. Помнить, что поведение ученика за пределами спортзала отражается на
                                            боевом искусстве и на инструкторе.<br/>
                                            7. Если ученик освоил новые приемы из другой школы, а инструктор возражает
                                            против их применения, ученик должен отказаться от них, либо заниматься там,
                                            где он выучил эти приемы.<br/>
                                            8. Никогда не проявлять неуважение к инструктору. Хотя ученику позволительно
                                            не соглашаться с инструктором, он должен сначала выполнять задание, а
                                            обсуждать все вопросы позже.<br/>
                                            9. Всегда быть активным, не стесняясь задавать вопросы.<br/>
                                            10. Никогда не предавать инструктора.<br/>
                                            *Засчитаны будут все атакующие действия, остановленные за 2см до цели.<br/>
                                            Одно (1) очко присуждается за:<br/>
                                            -атака рукой направленная в средний или верхний уровень<br/>
                                            -атака ногой направленная в средний уровень<br/>
                                            -атака рукой в прыжке направленная в средний уровень<br/>
                                            -отлично выполненная защита<br/>
                                            Два (2) очка присуждаются за:<br/>
                                            -атака ногой направленная в верхний уровень<br/>
                                            -атака рукой в прыжке (обе ноги не должны касаться площадки) направленная в
                                            верхний уровень<br/>
                                            -атака ногой в прыжке направленная в средний уровень<br/>
                                            Три (3) очка присуждаются за:<br/>
                                            -атака ногой в прыжке или в полёте направленная в верхний уровень<br/>
                                            -атака ногой в прыжке или в полёте с разворотом на 180 o направленная в
                                            средний уровень<br/>
                                            Четыре (4) очка присуждаются за:<br/>
                                            -атака ногой в прыжке с разворотом на 180 o направленная в верхний
                                            уровень<br/>
                                            -атака ногой в прыжке с разворотом на 360 o и более, направленная в средний
                                            уровень<br/>
                                            Пять (5) очков присуждаются за:<br/>
                                            -атака ногой в прыжке с разворотом на 360 o и более, направленная в верхний
                                            уровень<br/>
                                            Если при ударе в полете засчитывается 2 или 3 удара рукой и ногой, очки
                                            начисляются за каждый удар.<br/><br/>

                                            <p><b><i>Билет № 8.</i></b></p> Какие виды программ существуют в Таэквон-
                                            До? Критерии, по которым оценивается личный и
                                            командный туль. «КВАН-ГЭ» (расшифровать название, диаграмму и указать
                                            количество движений). Виды спарринга (на корейском).<br/>

                                            *Рассматривают три тренировочные программы, которые могут использоваться
                                            начинающими:<br/>
                                            1. 18 – месячный курс (702 часа): 6 тренировок в неделю по 1,5 часа
                                            каждая.<br/>
                                            2. 30 – месячный курс (585 часов): 3 тренировки в неделю по 1,5 часа каждая.<br/>
                                            3. 12 – месячный курс (1248 часов): 6 тренировок в неделю по 4 часа
                                            каждая.<br/>
                                            Командный туль. Критерии начисления очков:<br/>
                                            Работа в команде (хореография) 10 очков<br/>
                                            Техника 10 очков<br/>
                                            Сила 6 очков<br/>
                                            Равновесие 6 очков<br/>
                                            Контроль дыхания 6 очков<br/>
                                            Ритм 6 очков<br/>
                                            Личный туль. Критерии начисления очков:<br/>
                                            Техника 10 очков<br/>
                                            Сила 6 очков<br/>
                                            Равновесие 6 очков<br/>
                                            Контроль дыхания 6 очков<br/>
                                            Ритм 6 очков<br/>
                                            *Главным образом различают следующие виды спарринга:<br/>
                                            «Яксок масоги» (обусловленный) делится на «самбо» (на 3 шага), «ибо» (на 2
                                            шага), «ильбо» (на 1 шаг).<br/>
                                            «Пан чжаю масоги» — полусвободный спарринг.<br/>
                                            «Чжаю масоги» — свободный спарринг.<br/>
                                            «Мобом масоги» — модельный спарринг.<br/>
                                            «Баль масоги» — спарринг с использованием только действий ногами.<br/>
                                            «Яксок чжаю масоги» — свободный спарринг с заранее оговоренным
                                            сценарием.<br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Attestation;