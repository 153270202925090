
const initialState = {
    tuls: []
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case 'TULS_LOADED':
            return {
                tuls: action.payload
            };

        default:
            return state;
    }
};

export default reducer;