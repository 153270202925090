import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
import {
    HomePage,
    AboutClub,
    Attestation,
    Blog,
    Friends,
    History,
    KyongKiClub,
    News,
    OurDojangs,
    Reabilitation,
    Terminologic,
    Trainings,
    Tsigun,
    TulsTaekwonDo,
    ErrorPage
} from '../pages';
import Navigation from "../pages/navigation";
import Footer from '../pages/footer';
import '../pages/css/bootstrap.css';
import "../pages/font-awesome/css/font-awesome.css";
import "../pages/css/animate.css";
import "../pages/css/style.css";
import "../pages/color/default.css";
import './app.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { scroller } from 'react-scroll';
import { TaekwondoServiceProvider } from '../taekwondo-service-context';

AOS.init();

const handlerClick = (event) => {
    let newPlace = event.target.closest('[data-state]').dataset.state;
    scrollTo(newPlace);
    openMobileMenu();
    dropdownOpen(event);
};
const handleScroll = () => {
    const navTop = document.querySelector(".navbar-fixed-top");
    if (window.pageYOffset > 50) {
        navTop.classList.add("top-nav-collapse");
    } else {
        navTop.classList.remove("top-nav-collapse");
    }
};
const closeMobileMenu = () => {
    let mobileMenu = document.querySelector('.navbar-collapse');
    mobileMenu.classList.remove('collapse');
};
const mobileMenuToggle = () => {
    let mobileMenu = document.querySelector('.navbar-collapse');
    if (mobileMenu.classList.contains('collapse')) {
        closeMobileMenu();
    } else {
        openMobileMenu();
    }
};
const openMobileMenu = () => {
    let mobileMenu = document.querySelector('.navbar-collapse');
    mobileMenu.classList.add('collapse');
};
const bodyClick = (event) => {
    if (event.target.classList.contains('dropdown-toggle') ||
        event.target.parentElement.classList.contains('dropdown-toggle')) {
        dropdownOpen(event);
        return;
    }
    //Mobile
    const navLinksArray = Array.from(document.querySelectorAll('li.dropdown'));
    navLinksArray.forEach((element) => {
        element.classList.remove('open');
    });
};
const dropdownOpen = (event) => {
    const navLinksArray = Array.from(document.querySelectorAll('li.dropdown'));
    navLinksArray.forEach((element) => {
        element.classList.remove('open');
    });
    const targetNavLink = event.target.closest('li');
    try {
        targetNavLink.classList.add('open');
    } catch (e) {
    }
};
const scrollTo = (selector) => {
    let goToContainer = new Promise((resolve) => {
        resolve();
    });
    goToContainer.then(() =>
        scroller.scrollTo(selector, {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    );
};

const App = () => {
    return (
        <TaekwondoServiceProvider value={ bodyClick }>
            <Fragment>
                <div id="page-top" data-spy="scroll" data-target=".navbar-custom" className="home"/>
                <Switch>
                    <Route
                        path='/'
                        component={HomePage}
                        exact />
                    <Route
                        path='/about-club/'
                        component={AboutClub}
                        />
                    <Route
                        path="/attestation/"
                        component={Attestation}
                        />
                    <Route
                        path="/blog/"
                        component={Blog}
                        />
                    <Route
                        path="/friends/"
                        component={Friends}
                        />
                    <Route
                        path="/history/"
                        component={History}
                        />
                    <Route
                        path="/kyong-ki-club/"
                        component={KyongKiClub}
                        />
                    <Route
                        path="/news/"
                        component={News}
                        />
                    <Route
                        path="/our-dojangs/"
                        component={OurDojangs}
                        />
                    <Route
                        path="/reabilitation/"
                        component={Reabilitation}
                        />
                    <Route
                        path="/terminologic/"
                        component={Terminologic}
                        />
                    <Route
                        path="/trainings/"
                        component={Trainings}
                        />
                    <Route
                        path="/tsigun/"
                        component={Tsigun}
                        />
                    <Route
                        path="/tuls-taekwon-do/"
                        component={TulsTaekwonDo} />
                    <Route
                        component={ErrorPage}
                    />
                </Switch>
                <Navigation/>
                <Footer/>
        </Fragment>
        </TaekwondoServiceProvider>
    );
};

export default App;
export {
    bodyClick,
    dropdownOpen,
    scrollTo,
    handlerClick,
    openMobileMenu,
    handleScroll,
    closeMobileMenu,
    mobileMenuToggle
};