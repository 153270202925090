import React from 'react';
import {bodyClick} from "../app/app";

const OurDojangs = () => {
    return (
        <div onClick={bodyClick}>
            <section id="intro" className="intro menu-page">
            </section>
            <section id="service" className="home-section text-center bg-transparent">
                <div className="heading-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-lg-offset-2">
                                <div className="bounceInDown" data-aos="fade-down" data-aos-delay="400">
                                    <div className="section-heading">
                                        <h2>
                                            <span className="ua">Наші зали</span>
                                            <span className="ru">Наши залы</span>
                                        </h2>
                                        <i className="fa fa-2x fa-angle-down"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-lg-offset-5">
                            <hr className="marginbot-50"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-main">
                            <div className="bounceInBottom" data-aos="fade-bottom" data-aos-delay="200">
                                <div className="service-box">
                                    <div className="service-icon">
                                        <img src={require("./img/icons/icons8-пагода-48.png")} alt=""/>
                                    </div>
                                    <div className="service-desc ua">
                                    </div>
                                    <div className="service-desc ru">
                                        <h5>
                                        </h5>

                                        <br/>
                                        В г. Днепр временно не проводятся занятия.
                                        {/*На данный момент наш клуб проводит занятия по Таэквон-До по следующим адресам:<br/>
                                        г. Днепр - в 5-й и 77-й школе, <br/>фитнес-центр Fit4You на Короленко (здание Гранд
                                        Плазы, пр. Дмитрия Яворницкого 67Д),<br/>
                                        центр индивидуального развития "Цветок жизни" (ул. Шмидта, 3).*/}
                                    </div><br/><br/>
                                    <img className="img-content"
                                         src={require("./img/Snake_In_Eagle_Shadow.jpg")}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default OurDojangs;