import React from 'react';

const {
    Provider: TaekwondoServiceProvider,
    Consumer: TaekwondoServiceConsumer
} = React.createContext();

export {
    TaekwondoServiceProvider,
    TaekwondoServiceConsumer
};