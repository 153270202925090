import React, {Component, Fragment} from 'react';
import Spinner from '../../spinner';
import ErrorBoundry from "../../error-boundry";
// import ErrorButton from '../../error-button';

export default class VideoFrame extends Component {
    state = {
        loading: true
    };
    componentDidMount() {
        this.setState({
            loading: false
        });
    };

    render() {
        const { loading } = this.state;
        const { className, src } = this.props;
        const spinner = loading ? <Spinner/> : null;
        const content = !loading ? <iframe className={className}
                                src={src}
                                width="800"
                                height="auto"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                mozallowfullscreen="true"
                                webkitallowfullscreen="true">
                        </iframe> : null;
        return (
            <Fragment>
                <ErrorBoundry>
                    {spinner}
                    {content}
                    {/*<ErrorButton/>*/}
                </ErrorBoundry>
            </Fragment>
        )
    }
};